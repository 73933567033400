import { Button, Card, Stack } from "react-bootstrap"
import { Link } from "react-router-dom"
import timeService from "../../../services/time/timeService"
import { PropertyBookingEntry } from "../../../Types/bookingTypes"
import { currencyFormatter } from "../../../Helpers/Parsers/currencyFormatter"
import AccessLinkButton from "../../Buttons/AccessLinkButton"

interface BookingListItemProps {
  propertyName: string,
  bookingInfo: PropertyBookingEntry
  options?: {
    header?: string,
    disableLinks?: {
      [k: string]: boolean
    },
    details?: boolean
  },
  children?: any
  extraButtons?: any[]
}

interface BookingEntryOptionTypes {
  header: string,
  disableLinks: any,
  details: boolean
}

const defaultOptions: BookingEntryOptionTypes = {
  header: '',
  disableLinks: {
    delete: true
  },
  details: false
}

export const BookingEntry = (props: BookingListItemProps) => {
  const {
    bookingInfo,
    propertyName,
    options,
    extraButtons,
    children
  } = props

  const {
    _id,
    checkIn,
    checkOut,
    // propertyId,
    source,
    cleaning,
    pricePerNight,
    sourceConfirmation,
    bookingStatus,
    confirmationCode,
    contactInfo,
    bookingGuestInfoId,
    requiresBookingGuestInfo,
    dateBooked
  } = bookingInfo

  const {
    header,
    disableLinks,
    details
  } = {
    ...defaultOptions,
    ...options
  }

  const nights = timeService.calculateNights(checkIn, checkOut)
  const earnings = nights * (pricePerNight || 0)

  return (
    <Card className="booking-list-item mb-3">
      {header && (
        <Card.Header>
          {header}
        </Card.Header>
      )}
      <Card.Body>
        <Card.Title>
          {contactInfo && contactInfo.firstName} {contactInfo && contactInfo.lastName}
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{propertyName}</Card.Subtitle>
        <Card.Text className="small">
          <b>Check In:</b> {timeService.getFormattedTime(checkIn)} <br />
          <b>Check Out:</b> {timeService.getFormattedTime(checkOut)} <br />
          <b>Source:</b> {source} <br />
          <b>Source Confirmation:</b> {sourceConfirmation} <br />
          <b>Confirmation Code:</b> {confirmationCode} <br />
          {bookingGuestInfoId && (
            <>
              <b>Guest Info:</b> <Link to={`/booking-guest/${bookingGuestInfoId}`}>{bookingGuestInfoId}</Link> <br />
            </>
          )}
          {requiresBookingGuestInfo && !bookingGuestInfoId && (
            <>
              <b>Guest Info:</b> NEEDS INFO <br />
            </>
          )}
          <b>Nights</b> {nights} <br />
          <b>Price Per Night:</b> {pricePerNight ? `${currencyFormatter(pricePerNight)}` : ''} <br />
          <b>Earnings:</b> {earnings ? `${currencyFormatter(earnings)}` : ''} <br />
          <b>Booking Status:</b> {bookingStatus} <br />
          {/* <b>PropertyId:</b> {propertyId} <br /> */}
          {/* <b>Booking Id:</b> {_id} <br /> */}
          {details && (
            <>
              <b>Cleaning:</b> {cleaning ? `${currencyFormatter(cleaning)}` : ''} <br />
            </>
          )}
          {dateBooked && (
            <>
              <b>Date Booked:</b> {timeService.getFormattedTime(dateBooked)} <br />
            </>
          )}
        </Card.Text>

        {children && (
          <div className="booking-children mb-3">
            {(children)}
          </div>
        )}
      </Card.Body>
      <Card.Footer>
        <Stack direction={'horizontal'} gap={1} className="mt-2 mb-2">
          {!(disableLinks['view']) && (
            <AccessLinkButton
              action={'booking'}
              color={'primary'}
              size={'sm'}
              text={'View'}
              to={`/booking/${_id}`}
            />
          )}
          {!(disableLinks['edit']) && (
            <AccessLinkButton
              action={'edit-booking'}
              color={'secondary'}
              size={'sm'}
              text={'Edit'}
              to={`/edit-booking/${_id}`}
            />
          )}
          {!(disableLinks['delete']) && (
            <AccessLinkButton
              action={'delete-booking'}
              color={'danger'}
              size={'sm'}
              text={'Delete'}
              to={`/delete-booking/${_id}`}
            />
          )}
        </Stack>

        {extraButtons && extraButtons.map((button, index) => (
          <Button
            key={index}
            className="me-2 mb-2"
            size={'sm'}
            variant={button.variant ? button.variant : 'secondary'}
            disabled={button.disabled}
            onClick={() => button.onClick(bookingInfo)}>
            {button.label}
          </Button>
        ))}
      </Card.Footer>
    </Card>
  )
}