import { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PropertyForm } from "../../../Components/Forms/PropertyForm/PropertyForm";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import { accountSelector } from "../../../redux/account/accountSlice";
import { clearMyProperties } from "../../../redux/accountData/accountDataSlice";
import { uploadImage } from "../../../redux/generalNetwork/generalNetworkSlice";
import { clearProperties } from "../../../redux/properties/propertiesSlice";
import { addNewProperty, editProperty, getPropertyInfo, propertySelector } from "../../../redux/property/propertySlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { PropertyType } from "../../../Types/propertyTypes";
import StickyBar from "../../../Components/StickyBar/StickyBar";

export default function EditPropertyPage() {
  const { email, role } = useSelector(accountSelector)
  const { propertyId } = useParams()
  const { loading, error, message, _id, propertyData } = useSelector(propertySelector)
  const dispatch = useAppDispatch()
  const [imageUploadProgress, setImageUploadProgress] = useState('')
  const { myProperties } = useMyProperties()
  const myPropertySelectOptions = useMyPropertiesSelectValues(myProperties)

  useEffect(() => {
    if (loading !== 'pending' && propertyId && _id !== propertyId) {
      dispatch(getPropertyInfo(propertyId))
    }
    return () => { }
  }, [propertyId, _id, dispatch, loading])

  const onSubmit = async (values: Partial<PropertyType>) => {
    if (!email || !role || !_id) {
      return
    }
    if (!values.authorizedUsers) {
      values.authorizedUsers = [{
        email: email,
        role: role
      }]
    } else if (values.authorizedUsers.filter((x) => {
      return x.email.toLowerCase() === email.toLowerCase()
    }).length === 0) {
      values.authorizedUsers.push({
        email: email,
        role: role
      })
    }
    const editedProperty: any = await dispatch(editProperty({ _id, propertyInfo: values })).unwrap()
    if (editedProperty._id) {
      dispatch(clearMyProperties())
      dispatch(clearProperties())
      dispatch(navigateTo(`/property/${editedProperty._id}`))
    }
    return editedProperty
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Property</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}


          {email && role && _id === propertyId && propertyData && (
            <>
              <StickyBar>
                <h4>
                  {propertyData.title.split(' - ')[0]}
                </h4>
              </StickyBar>
              <PropertyForm
                disabled={loading === 'pending'}
                propertyOptionList={myPropertySelectOptions}
                initialValues={propertyData}
                onSubmit={onSubmit}
                uploadImageFn={async (imageInfo: { imageToUpload: any, filename: string }) => {
                  const uploadedImageUrl = await dispatch(uploadImage(imageInfo)).unwrap()
                  return uploadedImageUrl
                }}
                imageUploadProgress={imageUploadProgress}
                uploadImageMultiFn={async (imageInfo: { imageToUpload: any, filename: string }[]) => {
                  const uploadedImageUrls = []
                  for (let i = 0; i < imageInfo.length; i++) {
                    setImageUploadProgress(`Processing ${i + 1} of ${imageInfo.length}`)
                    const uploadedImageUrl = await dispatch(uploadImage(imageInfo[i])).unwrap()
                    uploadedImageUrls.push(uploadedImageUrl)
                  }
                  setImageUploadProgress(``)
                  return uploadedImageUrls
                }}
              />

              <hr />

              <Button
                variant="secondary"
                onClick={async () => {
                  const newPropertyData = {
                    ...propertyData,
                  }
                  delete newPropertyData._id
                  newPropertyData.title = `${newPropertyData.title} (COPY)`
                  const createdProperty = await dispatch(addNewProperty(newPropertyData)).unwrap()
                  if (createdProperty._id) {
                    dispatch(clearMyProperties())
                    dispatch(clearProperties())
                    dispatch(navigateTo(`/property/${createdProperty._id}`))
                  }
                }}
              >
                Copy Property
              </Button>


            </>
          )}
        </Col>
      </Row>
    </Container>
  )
}
