import { Col, Container, Row } from "react-bootstrap"
import Pagination from "../../Components/Pagination/Pagination"
import useDateBlockQuery from "../../Hooks/UseDateBlockQuery/useDateBlockQueryHook"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import QuickForm from "../../Components/Forms/QuickForm/QuickForm"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers";
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook";
import useMyProperties from "../../Hooks/UseMyProperties/UseMyPropertiesHook"
import useMyPropertiesSelectValues from "../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook"
import DateBlockEntry from "../../Components/DateBlocks/DateBlockEntry/DateBlockEntry"
import { DateBlockEntryType } from "../../Types/dateBlockTypes"
import timeService from "../../services/time/timeService"
import AccessLinkButton from "../../Components/Buttons/AccessLinkButton"

export default function DateBlocksPage() {

    const {
        myProperties,
    } = useMyProperties()
    const propertyOptions = useMyPropertiesSelectValues(myProperties)

    const dispatch = useAppDispatch()

    const { activeQuery } = useActiveQueryHook({
        query: {},
        limit: 10,
    })

    const {
        loading,
        error,
        currentRequestId,
        paginationInfo,
        currentQuery,
        message,
        dateBlocksList,
    } = useDateBlockQuery(activeQuery)

    const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
        const destination = `/date-blocks?${convertActiveQueryToUrl(pageParams)}`
        dispatch(navigateTo(destination))
    }

    let newDateBlockLink = '/add-date-block'
    if (Object.keys(activeQuery.query).length > 0) {
        const dateBlockQuery = { ...activeQuery.query }
        if (dateBlockQuery.checkIn) {
            dateBlockQuery.checkIn = timeService.setDateTime(dateBlockQuery.checkIn, { hour: 16 })?.getTime()
        }
        if (dateBlockQuery.checkOut) {
            dateBlockQuery.checkOut = timeService.setDateTime(dateBlockQuery.checkOut, { hour: 11 })?.getTime()
        }
        if(dateBlockQuery.status) {
            delete dateBlockQuery.status
        }
        newDateBlockLink = `/add-date-block?query=${JSON.stringify(dateBlockQuery)}`
    }


    return (
        <Container>
            <Row>
                <Col>
                    <h1>DateBlocks</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {message && (<>{message}<br /></>)}

                    <AccessLinkButton
                        action={'add-date-block'}
                        color={'dark'}
                        size={'sm'}
                        text={'Add Date Block'}
                        to={newDateBlockLink}
                    />

                    <hr />

                    <QuickForm
                        key={currentRequestId}
                        initialData={{
                            checkIn: activeQuery.query.checkIn,
                            checkOut: activeQuery.query.checkOut,
                            firstName: activeQuery.query.firstName,
                            lastName: activeQuery.query.lastName,
                            source: activeQuery.query.source,
                            confirmationCode: activeQuery.query.confirmationCode,
                            propertyId: activeQuery.query.propertyId,
                            tags: activeQuery.query.tags,
                            status: activeQuery.query.status ? activeQuery.query.status.map((x: string) => ({ tag: x })) : [],
                        }}
                        fields={[
                            {
                                fieldName: 'checkIn',
                                fieldLabel: 'Check In',
                                fieldType: 'date',
                                placeholder: 'checkIn',
                                layout: {
                                    xs: 12,
                                    md: 6,
                                }
                            },
                            {
                                fieldName: 'checkOut',
                                fieldLabel: 'Check Out',
                                fieldType: 'date',
                                placeholder: 'checkOut',
                                layout: {
                                    xs: 12,
                                    md: 6,
                                }
                            },

                            {
                                fieldName: 'firstName',
                                fieldLabel: 'First Name',
                                fieldType: 'text',
                                placeholder: 'firstName',
                                layout: {
                                    xs: 6,
                                    md: 6,
                                }
                            },

                            {
                                fieldName: 'lastName',
                                fieldLabel: 'Last Name',
                                fieldType: 'text',
                                placeholder: 'lastName',
                                layout: {
                                    xs: 6,
                                    md: 6,
                                }
                            },


                            {
                                fieldName: 'propertyId',
                                fieldLabel: 'Property Id',
                                fieldType: 'select',
                                placeholder: 'propertyId',
                                options: propertyOptions,
                                layout: {
                                    xs: 12,
                                    md: 4,
                                }
                            },
                            {
                                fieldName: 'source',
                                fieldLabel: 'Source',
                                fieldType: 'select',
                                placeholder: 'source',
                                options: [
                                    { value: 'owner', text: 'Owner' },
                                    { value: 'hotel', text: 'Hotel' },
                                ],
                                layout: {
                                    xs: 12,
                                    md: 4,
                                }
                            },
                            {
                                fieldName: 'confirmationCode',
                                fieldLabel: 'Confirmation Code',
                                fieldType: 'text',
                                placeholder: 'confirmationCode',
                                layout: {
                                    xs: 12,
                                    md: 4,
                                }
                            },
                            {
                                fieldName: 'status',
                                fieldLabel: 'Status',
                                fieldType: 'quickTag',
                                placeholder: 'status',
                                extra: {
                                    buttons: [
                                        { tag: 'new', text: 'New' },
                                        { tag: 'pending', text: 'Pending'},
                                        { tag: 'confirmed', text: 'Confirmed' },
                                        { tag: 'canceled', text: 'Canceled' },
                                    ]
                                },
                                layout: {
                                    xs: 12,
                                    md: 12,
                                }
                            }
                        ]}
                        disabled={loading === 'pending'}
                        onSubmit={(values: any) => {
                            const newValues: any = {}

                            Object.keys(values).forEach((key) => {
                                if (!values[key]) return

                                if (key === 'checkIn' || key === 'checkOut') {
                                    newValues[key] = (new Date(values[key])).getTime()
                                    return
                                }

                                if(key === 'status') {
                                    if(values[key].length === 0) {
                                        delete values[key]
                                        return
                                    }
                                    newValues[key] = values[key].map((x: any) => x.tag)
                                    return
                                }

                                console.log('key', key, 'values[key]', values[key])

                                newValues[key] = values[key]
                            })

                            loadPage({
                                query: newValues,
                                page: 1,
                                limit: currentQuery.limit
                            })
                        }}
                    />

                    <div className="dateBlock-list-container">
                        {dateBlocksList && dateBlocksList.length > 0 && dateBlocksList.map((dateBlockEntry: DateBlockEntryType, idx: number) => (
                            <DateBlockEntry
                                key={idx}
                                dateBlockInfo={dateBlockEntry}
                                propertyName={myProperties[dateBlockEntry.propertyId]?.title || dateBlockEntry.propertyId}
                            />
                        ))}
                    </div>

                    {paginationInfo && (
                        <Pagination
                            page={paginationInfo.page}
                            limit={paginationInfo.limit}
                            nextPage={paginationInfo.nextPage}
                            prevPage={paginationInfo.prevPage}
                            totalPages={paginationInfo.totalPages}
                            loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                                loadPage({
                                    query: currentQuery.query,
                                    limit: currentQuery.limit,
                                    ...pageLoadInfo
                                })
                            }}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    )
}
