import Papa from "papaparse";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";

export default function ExportPropertiesPage() {

    const { myProperties } = useMyProperties();
    const [fileUrl, setFileUrl] = useState<string | null>(null);

    const prepareCsv = () => {
        const csvData: any[] = [];

        for (const x of Object.values(myProperties)) {
            const convertedEntry = {
                _id: x._id,
                title: x.title,
                description: x.description,
                aliases: complexDataToString(x.aliases),
                ownerFirstName: x.owner.firstName,
                ownerLastName: x.owner.lastName,
                ownerEmail: x.owner.email,
                ownerPhone: x.owner.phone,
                address1: x.address.address1,
                address2: x.address.address2,
                city: x.address.city,
                state: x.address.state,
                zip: x.address.zip,
                country: x.address.country,
                featuredImage: x.featuredImage,
                imageGallery: complexDataToString(x.imageGallery),
                calendars: complexDataToString(x.calendars),
                listed: complexDataToString(x.listed),
                summary: x.about.summary,
                sections: complexDataToString(x.about.sections),
                amenities: x.about.amenities.join('|'),
                guests: x.about.guests,
                adults: x.about.guestInfo.adults,
                children: x.about.guestInfo.children,
                infants: x.about.guestInfo.infants,
                bedrooms: x.about.bedrooms,
                beds: x.about.beds,
                bathrooms: x.about.baths,
                baths: x.about.baths,
                isMultiUnit: x.multiUnit.isMultiUnit ? 'true' : 'false',
                linkedUnits: complexDataToString(x.multiUnit.linkedUnits),
                baseRate: x.pricing.baseRate,
                deposit: x.pricing.deposit,
                cleaning: x.pricing.cleaning,
                tax: x.pricing.tax,
                extraFees: complexDataToString(x.pricing.extraFees),
                virtualTour: x.virtualTour,
                authorizedUsers: complexDataToString(x.authorizedUsers),
                public: x.public,
                services: x.services ? objectTrueKeysToString(x.services) : '',
            }
            csvData.push(convertedEntry)
            const csvString = Papa.unparse(csvData)
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
            const url = URL.createObjectURL(blob)
            setFileUrl(url)
        }
    }

    const complexDataToString = (data: any) => {
        let dataString = ''
        let dataLength = data.length - 1
        data.forEach((dataEntry: any, dataIndex: number) => {
            let dataKeyLength = Object.keys(dataEntry).length - 1
            Object.keys(dataEntry).forEach((fieldName, fieldNumber) => {
                if (fieldName !== '_id') {
                    dataString += `${fieldName}::${dataEntry[fieldName]}${fieldNumber < dataKeyLength ? '%%' : ''}`
                }
            })
            dataString += dataIndex < dataLength ? '||' : ''
        })
        return dataString
    }

    const objectTrueKeysToString = (entry: any): string => {
        let stringObject = ''
        Object.keys(entry).forEach(x => {
            if (entry[x]) {
                stringObject += `${stringObject.length > 0 ? ', ' : ''}${x}`
            }
        })
        return stringObject
    }


    return (
        <Container>
            <Row>
                <Col>
                    <h1>Export Properties</h1>

                    You can use this tool to export your properties to a csv file.
                    <br /><br />
                    You have {Object.keys(myProperties).length} properties.
                    <br /><br />
                    <b>Instructions:</b>
                    <ol>
                        <li>
                            Click prepare file below.
                        </li>
                        <li>
                            When the file is ready click the download button.
                        </li>
                    </ol>
                    <hr />
                    {Object.keys(myProperties).length > 0 && (
                        <Button
                            onClick={() => {
                                prepareCsv();
                            }}
                        >
                            Prepare File
                        </Button>
                    )}
                    <br /><br />
                    {fileUrl && (
                        <a className="btn btn-success me-2 mb-2" href={fileUrl} download="exported-data.csv">Download File</a>
                    )}

                </Col>
            </Row>
        </Container>
    )
}