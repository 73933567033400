import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { generalStandardCrudBuilder, generateStandardNetworkState, StandardNetworkState } from "../reduxHelperFunctions";
import { RootState } from "../store";

interface UserState extends StandardNetworkState {
  _id?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: string;
  profilePic?: string
}

const generateEmptyUserState = (): UserState => {
  return {
    _id: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    profilePic: '',
    ...generateStandardNetworkState()
  }
}

const initialState: UserState = generateEmptyUserState()

export const getUserInfo = createAsyncThunk(
  'user/getUserInfoCall',
  async (userId: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().user
    const { requestId } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const userInfo = await thunkApi.extra.networkRequest.getUserInfo(userId)
    if (userInfo.error) {
      return thunkApi.rejectWithValue({ ...userInfo, _id: userId })
    }
    return {
      ...userInfo
    }
  }
)

export const deleteUser= createAsyncThunk(
  'user/deleteUserCall',
  async (userPayload: {_id: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().user
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const {_id } = userPayload
    const userData = await thunkApi.extra.networkRequest.deleteUser(_id)
    if(!userData) {
      return rejectWithValue({
        error: 'Deletion failed',
        _id: 999
      })
    }
    if (userData.error) {
      return rejectWithValue({
        ...userData,
        _id: 999
      })
    }
    return {
      _id: userData._id,
      username: 'DELETED'
    }
  }
)

export const editUserInfo= createAsyncThunk(
  'user/editUserInfoCall',
  async (userPayload: {userId: string, updatedUserInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().user
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const {userId, updatedUserInfo } = userPayload
    const userInfo = await thunkApi.extra.networkRequest.editUser(userId, updatedUserInfo)
    if (userInfo.error) {
      return rejectWithValue({ ...userInfo, _id: userId })
    }
    return {
      ...userInfo
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    ([
      [getUserInfo, 'getUserInfo'],
      [editUserInfo, 'editUserInfo'],
      [deleteUser, 'deleteUser'],
    ]).forEach(([action, actionName]: any) => {
      generalStandardCrudBuilder(builder, action, actionName)
    })
  }
})

// Action creators are generated for each case reducer function
// export const { } = userSlice.actions

export const userSelector = createSelector((state: RootState) => state, (state) => state.user)

export default userSlice.reducer
