import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import PurchaseForm from "../../../Components/Forms/PurchaseForm/PurchaseForm";
import { purchaseSelector, editPurchase, getPurchaseInfo } from "../../../redux/purchase/purchaseSlice";
import { clearPurchases } from "../../../redux/purchases/purchasesSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";

export default function EditPurchasePage() {
  const { _id, purchaseData, message, loading, error } = useSelector(purchaseSelector)
  const { purchaseId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && purchaseId && _id !== purchaseId) {
      dispatch(getPurchaseInfo(purchaseId))
    }
    return () => { }
  }, [purchaseId, _id, dispatch, loading, purchaseData])


  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (_id) {
      const editedPurchase = await dispatch(editPurchase({ _id, purchaseInfo: values })).unwrap()
      if (editedPurchase._id) {
        dispatch(navigateTo(`/purchase/${editedPurchase._id}`))
      }
      dispatch(clearPurchases())
      return editedPurchase
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Purchase</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          {purchaseId && purchaseId === _id && purchaseData && (
            <PurchaseForm
              disabled={false}
              initialValues={purchaseData}
              onSubmit={handleSubmit}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
