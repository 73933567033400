import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { generalStandardPaginatedBuilder, generateStandardNetworkPaginatedState, StandardPaginatedNetworkState } from "../reduxHelperFunctions";
import { RootState } from "../store";

interface UsersState extends StandardPaginatedNetworkState {
  userList: any[] | null
}

const generateEmptyUsersState = (): UsersState => {
  return {
    ...generateStandardNetworkPaginatedState(),
    userList: null,
  }
}

const initialState: UsersState = generateEmptyUsersState()

export const getUsersAction = createAsyncThunk(
  'users/getUsersActionCall',
  async (queryInfo: {query: any, page: number, limit: number}, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().users
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const usersList = await thunkApi.extra.networkRequest.getUsers(queryInfo.query, {page: queryInfo.page, limit: queryInfo.limit})
    if (usersList.error) {
      return rejectWithValue({
        ...usersList,
        currentQuery: queryInfo
      })
    }
    const {docs, ...paginationInfo} = usersList
    return {
      docs,
      paginationInfo,
      currentQuery: queryInfo
    }
  }
)

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearUsers: (state, action: PayloadAction) => {
      return {
        ...generateEmptyUsersState()
      }
    }
  },
  extraReducers: (builder) => {
    generalStandardPaginatedBuilder(builder, getUsersAction, 'userList')
  }
})

export const { clearUsers } = usersSlice.actions

export const usersSelector = createSelector((state: RootState) => state, (state) => state.users)

export default usersSlice.reducer
