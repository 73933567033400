import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getPropertyInfo, propertySelector } from "../../../redux/property/propertySlice"
import { useAppDispatch } from "../../../redux/store"
import './property.scss'
import { PropertyEntry } from "../../../Components/PropertyEntry/PropertyEntry"

export default function PropertyPage() {
  const { _id, propertyData, message, loading, error } = useSelector(propertySelector)
  const { propertyId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && propertyId && _id !== propertyId) {
      dispatch(getPropertyInfo(propertyId))
    }
    return () => { }
  }, [propertyId, _id, dispatch, loading])
  return (
    <Container>
      <Row>
        <Col>
          <h1>Single Property Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}

          {propertyId && propertyId === _id && propertyData && (
            <div className="property-info">
              {/*JSON.stringify(propertyData)*/}

              <PropertyEntry
                propertyInfo={propertyData}
                details={true}
                disableLinks={{
                  view: 1,
                }}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}