import { createSlice, PayloadAction, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { PropertyType } from "../../Types/propertyTypes";
import { generateStandardNetworkState, standardNetworkErrorCall, standardNetworkPendingCall, StandardNetworkState } from "../reduxHelperFunctions";
import { RootState } from "../store";
import getPropertyRole from "../../Helpers/Property/getPropertyRole";
import { getSelectedPropertiesFromLocal, saveSelectedPropertiesToLocal } from "../../Pages/AdrOccupancyReporter/adrOccupancyHelpers";

interface AccountDataState extends StandardNetworkState {
  myProperties: {
    [propertyId: string]: PropertyType
  }
}

const generateEmptyAccountDataState = (): AccountDataState => {
  return {
    myProperties: {},
    ...generateStandardNetworkState()
  }
}

const initialState: AccountDataState = generateEmptyAccountDataState()


export const getMyProperties = createAsyncThunk(
  'accountData/getMyPropertiesCall',
  async (_, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().accountData
    const { requestId } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const myPropertyData = await thunkApi.extra.networkRequest.getMyProperties()
    if (myPropertyData.error) {
      // do something clever to let the api know either no data or something went wrong
      return thunkApi.rejectWithValue(myPropertyData)
    }
    const username = thunkApi.getState().account.email
    const myProperties: any = {}
    if (myPropertyData && myPropertyData.propertyList && myPropertyData.propertyList.length > 0) {
      myPropertyData.propertyList.forEach((property: PropertyType) => {
        let propertyRole = getPropertyRole(property, username)
        myProperties[property._id] = {
          ...property,
          propertyRole,
          propertyAdmin: propertyRole === 'admin'
        }
      })
    } else {
      myProperties.noProperties = { _id: 666 }
    }

    const selectedProperties = getSelectedPropertiesFromLocal()
    let foundInvalidProperty = false
    Object.keys(selectedProperties).forEach((propertyId: string) => {
      if (!myProperties[propertyId]) {
        foundInvalidProperty = true
      }
    })
    if (foundInvalidProperty) {
      saveSelectedPropertiesToLocal({})
    }

    return {
      myProperties
    }
  }
)


export const accountDataSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearMyProperties: (state) => {
      return generateEmptyAccountDataState()
    },
    setPropertyData: (state, action: PayloadAction<{ [propertyId: string]: any }>) => {
      return {
        ...state,
        myProperties: {
          ...state.myProperties,
          ...action.payload
        },
        loading: 'idle',
        message: ''
      }
    },
    removePropertyFromAccount: (state, action: PayloadAction<string>) => {
      delete state.myProperties[action.payload]
    }
  },
  extraReducers: (builder) => {

    // GENERIC ACTION
    builder.addCase(getMyProperties.pending, standardNetworkPendingCall('Attempting to gather your properties'))
    builder.addCase(getMyProperties.rejected, (state, action: any) => {
      return standardNetworkErrorCall('', { myProperties: { error: { _id: 999 } } })(state, action)
    })
    builder.addCase(getMyProperties.fulfilled, (state, action: any) => {
      return {
        ...state,
        ...action.payload,
        loading: 'idle',
        message: '',
        error: null,
      }
    })

    // END BUILDER
  }
})

export const { setPropertyData, removePropertyFromAccount, clearMyProperties } = accountDataSlice.actions

export const accountDataSelector = createSelector((state: RootState) => state, (state) => state.accountData)

export default accountDataSlice.reducer
