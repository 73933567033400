export const standardNetworkPendingCall = (message: string = '', extraState: any = {}, nested: string = '') => (state: any, action: any) => {

  const pendingState = {
    loading: 'pending',
    currentRequestId: action.meta.requestId,
    message: message,
    lastLoad: Date.now(),
    ...extraState
  }

  if (nested) {
    if (state[nested].loading === 'idle') {
      return {
        ...state,
        [nested]: pendingState
      }
    }
    return
  }

  if (state.loading === 'idle') {
    return {
      ...state,
      ...pendingState
    }
  }
}

export const standardNetworkErrorCall = (message: string = '', extraState: any = {}, nested: string = '') => (state: any, action: any) => {
  const errorState = {
    loading: 'idle',
    message: message,
    error: action.payload,
    ...extraState,
  }
  if (nested) {
    return {
      ...state,
      [nested]: errorState
    }
  }
  return {
    ...state,
    ...errorState
  }
}

// NETWORK STATE GENERATORS
export const generateStandardNetworkState = () => {
  return {
    loading: 'idle',
    error: null,
    currentRequestId: '',
    message: ''
  }
}

export const generateStandardNetworkPaginatedState = () => {
  return {
    ...generateStandardNetworkState(),
    currentQuery: {
      page: -1,
      limit: 5,
      query: {}
    },
    paginationInfo: null,
  }
}
// END NETWORK STATE GENERATORS


// BUILDER FUNCTIONS
export const generalStandardCrudBuilder = (builder: any, actionCall: any, callName: string) => {
  builder.addCase(actionCall.pending, standardNetworkPendingCall(`Making ${callName} request`))
  builder.addCase(actionCall.rejected, (state: any, action: any) => {
    return standardNetworkErrorCall('', { _id: action.payload._id })(state, action)
  })
  builder.addCase(actionCall.fulfilled, (state: any, action: any) => {
    return {
      ...state,
      ...action.payload,
      loading: 'idle',
      message: '',
      error: null,
    }
  })
}

export const generalStandardPaginatedBuilder = (builder: any, actionCall: any, paginatedDataName: string) => {
  builder.addCase(actionCall.pending, standardNetworkPendingCall(`Making ${paginatedDataName} request`))
  builder.addCase(actionCall.rejected, (state: any, action: any) => {
    return standardNetworkErrorCall('', { currentQuery: action.payload.currentQuery })(state, action)
  })
  builder.addCase(actionCall.fulfilled, (state: any, action: any) => {
    return {
      ...state,
      [paginatedDataName]: action.payload.docs,
      paginationInfo: action.payload.paginationInfo,
      currentQuery: action.payload.currentQuery,
      loading: 'idle',
      message: '',
      error: null,
    }
  })
}

export const generalNetworkBuilder = (builder: any, actionCall: any, sliceName: string) => {
  builder.addCase(actionCall.pending, standardNetworkPendingCall(`'Making ${sliceName} request`, {}, sliceName))
  builder.addCase(actionCall.rejected, (state: any, action: any) => {
    return standardNetworkErrorCall('', {}, sliceName)(state, action)
  })
  builder.addCase(actionCall.fulfilled, (state: any, action: any) => {
    return {
      ...state,
      [sliceName]: {
        loading: 'idle',
        message: '',
        error: null,
      }
    }
  })
}
// END BUILDER FUNCTIONS

export interface StandardNetworkState {
  loading?: string
  currentRequestId?: string;
  error?: any
  message?: string;
  lastLoad?: number;
}

export interface StandardPaginationState {
  limit: number
  page: number
  hasNextPage: boolean
  hasPrevPage: boolean
  nextPage: number | null
  prevPage: number | null
  pagingCounter: number
  totalDocs: number
  totalPages: number
}

export interface StandardPaginatedNetworkState extends StandardNetworkState {
  currentQuery: any
  paginationInfo: StandardPaginationState | null
}
