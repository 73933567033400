import { useEffect, useState } from "react"
import { checkBookingDates } from "../../redux/generalNetwork/generalNetworkSlice"
import { useAppDispatch } from "../../redux/store"
import { PropertyBookingEntry } from "../../Types/bookingTypes"
import { PropertyLinkedPropertyType } from "../../Types/propertyTypes"

export default function useRelatedBookings(
  bookingId: string | undefined,
  bookingData: Partial<PropertyBookingEntry>,
  myProperties: { [propertyId: string]: any }
) {
  const dispatch = useAppDispatch()
  
  const [activeBookingId, setActiveBookingId] = useState('')
  const [relatedBookings, setRelatedBookings] = useState<PropertyBookingEntry[]>([])
  
  useEffect(() => {
    if (
      bookingId && bookingData &&
      Object.keys(myProperties).length > 0 &&
      bookingData.propertyId && bookingData._id === bookingId &&
      activeBookingId !== bookingId
    ) {
      setActiveBookingId(bookingId ? bookingId : '')
      findRelatedBookings({
        selectedPropertyId: bookingData.propertyId,
        myProperties,
        bookingData,
        bookingId,
        setRelatedBookings,
        dispatch,
      })
    }
    return () => { }
  }, [myProperties, bookingData, bookingId, activeBookingId, relatedBookings, dispatch])

  return relatedBookings
}


const findRelatedBookings = async (options: {
    selectedPropertyId: string,
    myProperties: { [propertyId: string]: any },
    bookingData: Partial<PropertyBookingEntry>,
    bookingId: string,
    setRelatedBookings: (bookingList: PropertyBookingEntry[]) => void,
    dispatch: any,
  }) => {

  const { 
    selectedPropertyId,
    myProperties,
    bookingData,
    bookingId,
    setRelatedBookings,
    dispatch,
  } = options
  
  const propertyId = [selectedPropertyId]
  if (myProperties[selectedPropertyId]) {
    const {
      multiUnit: {
        isMultiUnit,
        linkedUnits,
      }
    } = myProperties[selectedPropertyId]
    if (isMultiUnit) {
      linkedUnits.forEach((unit: PropertyLinkedPropertyType) => {
        propertyId.push(unit.propertyId)
        // check for parent properties
      })
      if (bookingData && bookingData.checkIn && bookingData.checkOut) {
        const tempRelatedBookingList: PropertyBookingEntry[] = []
        // better network code needed
        const relatedBookingData = await dispatch(checkBookingDates({
          propertyId,
          checkIn: bookingData.checkIn,
          checkOut: bookingData.checkOut
        })).unwrap()
        if (relatedBookingData.docs && relatedBookingData.docs.length > 0) {
          const currentCheckIn = (new Date(bookingData.checkIn)).getTime()
          const currentCheckOut = (new Date(bookingData.checkOut)).getTime()
          relatedBookingData.docs.forEach((booking: PropertyBookingEntry) => {
            const bookingCheckIn = (new Date(booking.checkIn)).getTime()
            const bookingCheckOut = (new Date(booking.checkOut)).getTime()
            if (
              booking._id && booking._id !== bookingId &&
              currentCheckIn === bookingCheckIn &&
              currentCheckOut === bookingCheckOut
            ) {
              tempRelatedBookingList.push(booking)
            }
          })
          setRelatedBookings(tempRelatedBookingList)
          return
        }
      }
    }
    setRelatedBookings([])
  }
}