import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import BuildingForm from "../../../Components/Forms/BuildingForm/BuildingForm";
import { addNewBuilding, buildingSelector } from "../../../redux/building/buildingSlice";
import { clearBuildings } from "../../../redux/buildings/buildingsSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { uploadImage } from "../../../redux/generalNetwork/generalNetworkSlice";
import { useState } from "react";

export default function AddBuildingPage() {
  const [imageUploadProgress, setImageUploadProgress] = useState('')
  const {
    loading,
    error,
    message,
  } = useSelector(buildingSelector)
  const dispatch = useAppDispatch()
  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    const newBuilding = await dispatch(addNewBuilding({ buildingInfo: values })).unwrap()
    if (newBuilding._id) {
      dispatch(navigateTo(`/building/${newBuilding._id}`))
    }
    dispatch(clearBuildings())
    return newBuilding
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add Building</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          <BuildingForm
            initialValues={{}}
            disabled={loading === 'pending'}
            onSubmit={handleSubmit}
            uploadImageFn={async (imageInfo: { imageToUpload: any, filename: string }) => {
              const uploadedImageUrl = await dispatch(uploadImage(imageInfo)).unwrap()
              return uploadedImageUrl
            }}
            imageUploadProgress={imageUploadProgress}
            uploadImageMultiFn={async (imageInfo: { imageToUpload: any, filename: string }[]) => {
              const uploadedImageUrls = []
              for (let i = 0; i < imageInfo.length; i++) {
                setImageUploadProgress(`Processing ${i + 1} of ${imageInfo.length}`)
                const uploadedImageUrl = await dispatch(uploadImage(imageInfo[i])).unwrap()
                uploadedImageUrls.push(uploadedImageUrl)
              }
              setImageUploadProgress(``)
              return uploadedImageUrls
            }}
          />

        </Col>
      </Row>
    </Container>
  )
}
