import { Button, Col, Container, Row } from "react-bootstrap";
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook";
import usePropertyTaskQuery from "../../Hooks/UsePropertyTaskQuery/UsePropertyTaskQuery";
import { useAppDispatch } from "../../redux/store";
import { editPropertyTask } from "../../redux/propertyTask/propertyTaskSlice";

export default function QueryWhore() {

    const { activeQuery } = useActiveQueryHook({
        query: {
            // notStatus: ['completed', 'approved'],
            status: ['confirmed'],
            createdStart: (new Date('2023-02-01')).getTime()
        },
        limit: 500,
        page: 1,
    })

    const dispatch = useAppDispatch()

    const {
        // loading,
        // error,
        // currentRequestId,
        // paginationInfo,
        // currentQuery,
        // message,
        propertyTasksList,
    } = usePropertyTaskQuery(activeQuery)

    // console.log(propertyTasksList)
    
    const tasksWithoutConfirmationDate = (propertyTasksList ? propertyTasksList : []).filter((task: any) => {
        return !task.completedDate
    })

    console.log(tasksWithoutConfirmationDate)

    const updateConfirmationCodes = async () => {
        const tasks = tasksWithoutConfirmationDate
        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i]
            let completedDate = new Date(task.createdAt)
            const lastWorkLogEntry = task.workLog[task.workLog.length - 1]
            if(lastWorkLogEntry && lastWorkLogEntry.end) {
                completedDate = new Date(lastWorkLogEntry.end)
            }

            const updatePayload = {
                _id: task._id,
                propertyTaskInfo: {
                    completedDate
                }
            }
            const updatedTask = await dispatch(editPropertyTask(updatePayload)).unwrap()
            console.log(updatedTask)
        }
        console.log('done')
    }

    return (
        <Container>
            <Row>
                <Col>
                    {propertyTasksList && (
                        <>
                            {propertyTasksList.length} tasks found
                            <br />
                            {tasksWithoutConfirmationDate.length} tasks without confirmation date
                        </>
                    )}

                    <Button
                        onClick={updateConfirmationCodes}
                    >
                        Run
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}