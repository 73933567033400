import { Col, Container, Row } from "react-bootstrap"
import Pagination from "../../Components/Pagination/Pagination"
import useBookingRequestQuery from "../../Hooks/UseBookingRequestQuery/useBookingRequestQueryHook"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import QuickForm from "../../Components/Forms/QuickForm/QuickForm"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers";
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook";
import BookingRequestEntry from "../../Components/BookingRequest/BookingRequestEntry"

export default function BookingRequestsPage() {

    const dispatch = useAppDispatch()

    const { activeQuery } = useActiveQueryHook({
        query: {},
        limit: 10,
    })

    const {
        loading,
        error,
        currentRequestId,
        paginationInfo,
        currentQuery,
        message,
        bookingRequestsList,
    } = useBookingRequestQuery(activeQuery)

    const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
        const destination = `/booking-requests?${convertActiveQueryToUrl(pageParams)}`
        dispatch(navigateTo(destination))
    }


    return (
        <Container>
            <Row>
                <Col>
                    <h1>Booking Requests</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {message && (<>{message}<br /></>)}

                    <QuickForm
                        key={currentRequestId}
                        initialData={{
                            guestName: activeQuery.query.guestName,
                        }}
                        fields={[
                            {
                                fieldName: 'guestName',
                                fieldLabel: 'Name',
                                fieldType: 'text',
                                placeholder: 'Name',
                            }
                        ]}
                        disabled={loading === 'pending'}
                        onSubmit={(values: any) => {
                            loadPage({
                                query: values,
                                page: 1,
                                limit: currentQuery.limit
                            })
                        }}
                    />

                    <div className="bookingRequest-list-container">
                        {bookingRequestsList && bookingRequestsList.length > 0 && bookingRequestsList.map((bookingRequestEntry, idx) => (
                           <BookingRequestEntry 
                                key={idx}
                                bookingRequest={bookingRequestEntry}
                           />
                        ))
                        }
                    </div>

                    {paginationInfo && (
                        <Pagination
                            page={paginationInfo.page}
                            limit={paginationInfo.limit}
                            nextPage={paginationInfo.nextPage}
                            prevPage={paginationInfo.prevPage}
                            totalPages={paginationInfo.totalPages}
                            loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                                loadPage({
                                    query: currentQuery.query,
                                    limit: currentQuery.limit,
                                    ...pageLoadInfo
                                })
                            }}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    )
}
