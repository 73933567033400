import Papa from "papaparse";
import { useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import { bookingsSelector, getBookingsAction } from "../../../redux/bookings/bookingsSlice";
import { useAppDispatch } from "../../../redux/store";

export default function ExportBookingsPage() {

    const { myProperties } = useMyProperties();

    const dispatch = useAppDispatch();

    const activeQuery = {
        query: {},
        page: 1,
        limit: 100,
        all: true,
    }

    const {
        loading,
        // error,
        // currentRequestId,
        // paginationInfo,
        currentQuery,
        // message,
        bookingsList,
    } = useSelector(bookingsSelector)

    const [fileUrl, setFileUrl] = useState<string | null>(null);

    const getBookings = () => {
        dispatch(getBookingsAction(activeQuery))
    }

    const prepareCsv = () => {
        const csvData: any[] = [];
        if (!bookingsList) {
            return;
        }

        for (const x of bookingsList) {
            const bookingProperty = myProperties[x.propertyId]
            const convertedEntry = {
                _id: x._id,
                title: bookingProperty.title,
                propertyId: x.propertyId,
                checkIn: x.checkIn,
                checkOut: x.checkOut,
                pricePerNight: x.pricePerNight,
                source: x.source,
                sourceConfirmation: x.sourceConfirmation,
                firstName: x.contactInfo.firstName,
                lastName: x.contactInfo.lastName,
                guestTotal: x.guestInfo.guestTotal,
                adults: x.guestInfo.adults,
                children: x.guestInfo.children,
                infants: x.guestInfo.infants,
                email: x.contactInfo.email,
                phone: x.contactInfo.phone,
                dateBooked: x.dateBooked,
                dateInquired: x.dateInquired,
                bookingStatus: x.bookingStatus,
                requiresConfirmation: x.requiresConfirmation,
                confirmationCode: x.confirmationCode,
                requiresBookingGuestInfo: x.requiresBookingGuestInfo,
                bookingGuestInfoId: x.bookingGuestInfoId,
                cancellationAmount: x.cancellation && x.cancellation.amount ? x.cancellation.amount : '',
                cancellationAmountType: x.cancellation && x.cancellation.amountType ? x.cancellation.amountType : ''
            }
            csvData.push(convertedEntry)
        }
        const csvString = Papa.unparse(csvData.sort((x,y) => x.title.localeCompare(y.title)))
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
        const url = URL.createObjectURL(blob)
        setFileUrl(url)
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Export Bookings</h1>

                    You can use this tool to export your bookings to a csv file.
                    <br /><br />
                    You have {loading === 'pending' && (<Spinner size="sm" animation="border" />)}  {bookingsList ? bookingsList.length : 0} bookings.
                    <br /><br />
                    <b>Instructions:</b>
                    <ol>
                        <li>
                            Click prepare file below.
                        </li>
                        <li>
                            When the file is ready click the download button.
                        </li>
                    </ol>
                    <hr />
                    {JSON.stringify(currentQuery) !== JSON.stringify(activeQuery) && (
                        <Button
                            onClick={() => {
                                getBookings()
                            }}
                            disabled={loading === 'pending'}
                        >
                            Load Bookings
                        </Button>
                    )}

                    {JSON.stringify(currentQuery) === JSON.stringify(activeQuery) && myProperties && bookingsList && bookingsList.length > 0 && (
                        <Button
                            onClick={() => {
                                prepareCsv();
                            }}
                        >
                            Prepare File
                        </Button>
                    )}
                    <br /><br />
                    {fileUrl && (
                        <a className="btn btn-success me-2 mb-2" href={fileUrl} download="exported-data.csv">Download File</a>
                    )}

                </Col>
            </Row>
        </Container>
    )
}