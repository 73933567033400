import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardCrudBuilder, generateStandardNetworkState, StandardNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface PropertyPriceState extends StandardNetworkState {
  _id: string | null
  propertyPriceData: any
}

const generateEmptyPropertyPriceState = (): PropertyPriceState => {
  return {
    _id: '',
    propertyPriceData: {},
    ...generateStandardNetworkState()
  }
}

const initialState = generateEmptyPropertyPriceState()

export const getPropertyPriceInfo = createAsyncThunk(
  'propertyPrice/getPropertyPriceInfoCall',
  async (propertyPriceId: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().propertyPrice
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const propertyPriceData = await thunkApi.extra.networkRequest.getPropertyPriceById(propertyPriceId)
    if (propertyPriceData.error) {
      return rejectWithValue({
        ...propertyPriceData,
        _id: propertyPriceId
      })
    }
    return {
      _id: propertyPriceId,
      propertyPriceData
    }
  }
)

export const addNewPropertyPrice = createAsyncThunk(
  'propertyPrice/addNewPropertyPriceCall',
  async (propertyPricePayload: { propertyPriceInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().propertyPrice
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { propertyPriceInfo } = propertyPricePayload
    const propertyPriceData = await thunkApi.extra.networkRequest.addNewPropertyPrice(propertyPriceInfo)
    if (propertyPriceData.error) {
      return rejectWithValue({
        ...propertyPriceData,
        _id: 999
      })
    }
    return {
      _id: propertyPriceData._id,
      propertyPriceData: {
        ...propertyPriceData
      }
    }
  }
)

export const editPropertyPrice = createAsyncThunk(
  'propertyPrice/editPropertyPriceCall',
  async (propertyPricePayload: { _id: string, propertyPriceInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().propertyPrice
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id, propertyPriceInfo } = propertyPricePayload
    const propertyPriceData = await thunkApi.extra.networkRequest.editPropertyPrice(_id, propertyPriceInfo)
    if (propertyPriceData.error) {
      return rejectWithValue({
        ...propertyPriceData,
        _id: 999
      })
    }
    return {
      _id: propertyPriceData._id,
      propertyPriceData: {
        ...propertyPriceData
      }
    }
  }
)

export const deletePropertyPrice = createAsyncThunk(
  'propertyPrice/deletePropertyPriceCall',
  async (propertyPricePayload: { _id: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().propertyPrice
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id } = propertyPricePayload
    const propertyPriceData = await thunkApi.extra.networkRequest.deletePropertyPrice(_id)
    if (!propertyPriceData) {
      return rejectWithValue({
        error: 'Deletion failed',
        _id: 999
      })
    }
    if (propertyPriceData.error) {
      return rejectWithValue({
        ...propertyPriceData,
        _id: 999
      })
    }
    return {
      _id: propertyPriceData._id,
      propertyPriceData: {
        title: 'PropertyPrice Deleted'
      }
    }
  }
)

export const propertyPriceSlice = createSlice({
  name: 'propertyPrice',
  initialState,
  reducers: {
    clearPropertyPrice: (state, action: PayloadAction) => {
      return {
        ...generateEmptyPropertyPriceState()
      }
    }
  },
  extraReducers: (builder) => {
    ([
      [getPropertyPriceInfo, 'getPropertyPriceInfo'],
      [addNewPropertyPrice, 'addNewPropertyPrice'],
      [editPropertyPrice, 'editPropertyPrice'],
      [deletePropertyPrice, 'deletePropertyPrice']
    ]).forEach(([action, actionName]: any) => {
      generalStandardCrudBuilder(builder, action, actionName)
    })
  }
})

export const { clearPropertyPrice } = propertyPriceSlice.actions

export const propertyPriceSelector = createSelector((state: RootState) => state, (state) => state.propertyPrice)

export default propertyPriceSlice.reducer
