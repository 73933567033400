import { Col, Container, Row } from "react-bootstrap"
import Pagination from "../../Components/Pagination/Pagination"
import useBuildingQuery from "../../Hooks/UseBuildingQuery/useBuildingQueryHook"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import QuickForm from "../../Components/Forms/QuickForm/QuickForm"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers";
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook";
import { BuildingEntryType } from "../../Types/buildingTypes"
import BuildingEntry from "../../Components/Buildings/BuildingEntry"

export default function BuildingsPage() {

    const dispatch = useAppDispatch()

    const { activeQuery } = useActiveQueryHook({
            query: {},
            limit: 10,
    })

    const {
        loading,
        error,
        currentRequestId,
        paginationInfo,
        currentQuery,
        message,
        buildingsList,
    } = useBuildingQuery(activeQuery)

    const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
        const destination = `/buildings?${convertActiveQueryToUrl(pageParams)}`
        dispatch(navigateTo(destination))
    }


    return (
        <Container>
            <Row>
                <Col>
                    <h1>Buildings</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {message && (<>{message}<br /></>)}

                    <QuickForm
                        key={currentRequestId}
                        initialData={{
                            name: activeQuery.query.name,
                        }}
                        fields={[
                            {
                                fieldName: 'title',
                                fieldLabel: 'Title',
                                fieldType: 'text',
                                placeholder: 'Title',
                            }
                        ]}
                        disabled={loading === 'pending'}
                        onSubmit={(values: any) => {
                            loadPage({
                                query: values,
                                page: 1,
                                limit: currentQuery.limit
                            })
                        }}
                    />

                    <div className="building-list-container">
                        {buildingsList && buildingsList.length > 0 && buildingsList.map((buildingEntry: BuildingEntryType, idx: number) => (
                            <BuildingEntry
                                key={idx}
                                buildingInfo={buildingEntry}
                            />
                        ))
                        }
                    </div>

                    {paginationInfo && (
                        <Pagination
                            page={paginationInfo.page}
                            limit={paginationInfo.limit}
                            nextPage={paginationInfo.nextPage}
                            prevPage={paginationInfo.prevPage}
                            totalPages={paginationInfo.totalPages}
                            loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                                loadPage({
                                    query: currentQuery.query,
                                    limit: currentQuery.limit,
                                    ...pageLoadInfo
                                })
                            }}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    )
}
