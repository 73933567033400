import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { UserSearchForm } from "../../Components/Forms/UserSearchForm/UserSearchForm"
import Pagination from "../../Components/Pagination/Pagination"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers"
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook"
import useUsersQuery from "../../Hooks/UseUsersQuery/UseUsersQuery"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import './usersPage.scss'

export default function UsersPage() {

  const dispatch = useAppDispatch()

  const { activeQuery } = useActiveQueryHook({ limit: 10 })

  const {
    loading,
    error,
    currentRequestId,
    paginationInfo,
    currentQuery,
    message,
    userList,
  } = useUsersQuery(activeQuery)

  const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
    const destination = `/users?${convertActiveQueryToUrl(pageParams)}`
    dispatch(navigateTo(destination))
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Users Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}

          <UserSearchForm
            key={currentRequestId}
            disabled={loading === 'pending'}
            onSubmit={(values: any) => {
              loadPage({
                query: values,
                page: 1,
                limit: currentQuery.limit
              })
            }}
            firstName={activeQuery.query.firstName}
            lastName={activeQuery.query.lastName}
            email={activeQuery.query.email}
          />
          <hr />

          {userList && userList.length > 0 && (
            <div className="user-list-container">
              {userList.map((user, idx) => (
                <UserEntry
                  key={user._id}
                  {...user} />
              ))}
            </div>
          )}

          {paginationInfo && (
            <Pagination
              page={paginationInfo.page}
              limit={paginationInfo.limit}
              nextPage={paginationInfo.nextPage}
              prevPage={paginationInfo.prevPage}
              totalPages={paginationInfo.totalPages}
              loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                loadPage({
                  query: currentQuery.query,
                  limit: currentQuery.limit,
                  ...pageLoadInfo
                })
              }}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}


const UserEntry = (props: { _id: string, firstName: string, lastName: string, email: string, profilePic: string, role: string }) => {
  const {
    _id,
    firstName,
    lastName,
    email,
    role
    // profilePic,
  } = props

  return (
    <div className="user-entry">
      Name: {firstName} {lastName} <br />
      Email: {email} <br />
      Role: {role} <br />
      <Link to={`/user/${_id}`} >View</Link>
    </div>
  )
}
