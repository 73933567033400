import { ErrorMessage, Form, Formik } from "formik";
import { useState } from "react";
import { Button } from "react-bootstrap";
import ImageUploadField from "../../FormFields/ImageUpload/ImageUpload";
import { SelectInputField } from "../../FormFields/SelectInput/SelectInputField";
import { TextInputField } from "../../FormFields/TextInput/TextInputField";
import { editUserSchema } from "./EditUserFormHelpers";
import canAccess from "../../../services/accessService/accessService";

export function EditUserForm(props: {
  firstName?: string,
  lastName?: string,
  profilePic?: string,
  email?: string,
  role?: string,
  disabled: boolean,
  onSubmit: (profileInfo: { firstName?: string, lastName?: string, profilePic?: string, email?: string, role?: string }) => Promise<void>,
  uploadImageFn: (imageInfo: { imageToUpload: any, filename: string }) => Promise<string>,
}) {
  const { firstName, lastName, profilePic, email, role, disabled, onSubmit, uploadImageFn } = props
  const [uploadingImage, setUploadingImage] = useState(false)

  return (
    <div>
      <h2>Edit User</h2>
      <Formik
        initialValues={{ firstName, lastName, email, profilePic, role, password: '' }}
        validationSchema={editUserSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const submissionValues: any = {
              firstName: values.firstName,
              lastName: values.lastName,
              profilePic: values.profilePic,
              role: values.role,
              email: values.email ? values.email.toLowerCase() : values.email
            }
            if (values.password && values.password.length > 5) {
              submissionValues.password = values.password
            }
            await onSubmit(submissionValues)
            setSubmitting(false)

          } catch (err) {
            // custom error handler
          }
          // setSubmitting(false)
        }}
      >

        {({ isSubmitting, setFieldValue, touched, errors, values }) => (
          <Form>
            <TextInputField
              fieldLabel="First Name"
              placeholder="First Name"
              required={true}
              fieldName="firstName" />

            <TextInputField
              fieldLabel="Last Name"
              placeholder="Last Name"
              required={true}
              fieldName="lastName" />

            <TextInputField
              fieldLabel="Email"
              placeholder="Email"
              fieldType="email"
              required={true}
              fieldName="email" />

            {/* <TextInputField
              fieldLabel="Role"
              placeholder="Role"
              required={true}
              fieldName="role" /> */}

            <SelectInputField
              fieldLabel="Role"
              placeholder="- Select Role -"
              required={true}
              fieldName="role"
              options={[
                ...(canAccess('user', 'form.grantAdmin') ? [{ value: 'admin', text: 'Admin' }] : []),
                { value: 'association', text: 'Association' },
                { value: 'association-member', text: 'Association Member' },
                { value: 'casual', text: 'Casual' },
                { value: 'cleaning', text: 'Cleaning' },
                { value: 'front-desk', text: 'Front Desk' },
                { value: 'maintenance', text: 'Maintenance' },
                { value: 'maintenance-lead', text: 'Maintenance Lead' },
                { value: 'manager', text: 'Manager'},
                { value: 'owner', text: 'Owner' },
                { value: 'viewer', text: 'Viewer' },
              ]} />
            {canAccess('user', 'form.changePassword') && (
              <TextInputField
                fieldLabel="Change Password"
                placeholder="*****"
                fieldType="password"
                fieldName="password" />
            )}

            {/*<Field type="text" placeholder="profilPic" name="profilePic" /><br />
            <ErrorMessage name="profilePic" component="div" />*/}

            {values.profilePic && (
              <>
                <img alt={'profile pic preview'} src={values.profilePic} className="profile-pic-preview" />
                <br />
              </>
            )}
            <ImageUploadField
              uploadedImageCallback={async (imageInfo: any) => {
                setUploadingImage(true)
                try {
                  const uploadedImageUrl = await uploadImageFn(imageInfo)
                  if (uploadedImageUrl) {
                    setFieldValue('profilePic', uploadedImageUrl)
                  }
                } catch (err) {
                  // something went wrong
                }
                setUploadingImage(false)
              }}
            />
            <ErrorMessage name="profilePic" component="div" />
            <br />

            <Button className="mb-3" type="submit" disabled={disabled || isSubmitting || uploadingImage}>
              Update
            </Button>
          </Form>
        )}
      </Formik>

    </div>
  )
}
