export const copyToClipboard = (text: string) => {

    if (navigator?.clipboard?.writeText && window.location.protocol === 'https:') {
      navigator.clipboard.writeText(text)
      alert('Copied')
      return
    }

    const textArea = document.createElement("textarea");
    textArea.value = text ? text : 'Nothing to copy';

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
      alert('Copied')
    } catch (err) {
      console.error('Oops, unable to copy', err);
      alert('Failed to copy')
    }

    document.body.removeChild(textArea);
  }