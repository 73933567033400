import { bukiblesSelector, getBukiblesAction } from "../../redux/bukibles/bukiblesSlice"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/store"
import { useEffect } from "react"
import { shouldLoadNetwork } from "../../Helpers/ValueHandlers/shouldLoad"


export default function useBukibleQuery(activeQuery: {
    query: any,
    page: number
    limit: number,
    all?: boolean
}) {
    const {
        loading,
        error,
        currentRequestId,
        paginationInfo,
        currentQuery,
        message,
        bukiblesList,
        lastLoad
    } = useSelector(bukiblesSelector)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (shouldLoadNetwork(loading, currentQuery, activeQuery, lastLoad)) {
            dispatch(getBukiblesAction(activeQuery))
        }
        return () => { }
    })

    return { loading, error, message, bukiblesList, paginationInfo, currentRequestId, currentQuery, lastLoad }

}