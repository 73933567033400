import { useEffect, useMemo } from "react"
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import BukinForm from "../../../Components/Forms/BukinForm/BukinForm";
import { bukinSelector, editBukin, getBukinInfo } from "../../../redux/bukin/bukinSlice";
import { clearBukins } from "../../../redux/bukins/bukinsSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import useBukibleQuery from "../../../Hooks/UseBukibleQuery/useBukibleQueryHook";

export default function EditBukinPage() {
  const { _id, bukinData, message, loading, error } = useSelector(bukinSelector)
  const { bukinId } = useParams()
  const dispatch = useAppDispatch()

  const {
    // loading,
    // error,
    // currentRequestId,
    // paginationInfo,
    // currentQuery,
    // message,
    bukiblesList,
} = useBukibleQuery({
    query: {},
    limit: 500,
    page: 1,
})

const bukibleSelectOptions = useMemo(() => {
    if (!bukiblesList) { return [] }
    return bukiblesList.map(bukible => ({
        value: bukible._id,
        text: bukible.title
    }))
}, [bukiblesList])

  useEffect(() => {
    if (loading !== 'pending' && bukinId && _id !== bukinId) {
      dispatch(getBukinInfo(bukinId))
    }
    return () => { }
  }, [bukinId, _id, dispatch, loading, bukinData])


  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (_id) {
      const editedBukin = await dispatch(editBukin({ _id, bukinInfo: values })).unwrap()
      if (editedBukin._id) {
        dispatch(navigateTo(`/bukin/${editedBukin._id}`))
      }
      dispatch(clearBukins())
      return editedBukin
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Bukin</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          {bukinId && bukinId === _id && bukinData && (
            <BukinForm
              disabled={false}
              initialValues={bukinData}
              onSubmit={handleSubmit}
              bukibleSelectOptions={bukibleSelectOptions}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
