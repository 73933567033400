import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import BookingRequestForm from "../../../Components/Forms/BookingRequestForm/BookingRequestForm";
import { bookingRequestSelector, editBookingRequest, getBookingRequestInfo } from "../../../redux/bookingRequest/bookingRequestSlice";
import { clearBookingRequests } from "../../../redux/bookingRequests/bookingRequestsSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";

export default function EditBookingRequestPage() {
  const { _id, bookingRequestData, message, loading, error } = useSelector(bookingRequestSelector)
  const { bookingRequestId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && bookingRequestId && _id !== bookingRequestId) {
      dispatch(getBookingRequestInfo(bookingRequestId))
    }
    return () => { }
  }, [bookingRequestId, _id, dispatch, loading, bookingRequestData])


  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (_id) {
      const editedBookingRequest = await dispatch(editBookingRequest({ _id, bookingRequestInfo: values })).unwrap()
      if (editedBookingRequest._id) {
        dispatch(navigateTo(`/booking-request/${editedBookingRequest._id}`))
      }
      dispatch(clearBookingRequests())
      return editedBookingRequest
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Booking Request</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          {bookingRequestId && bookingRequestId === _id && bookingRequestData && (
            <BookingRequestForm
              disabled={false}
              initialValues={bookingRequestData}
              onSubmit={handleSubmit}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
