import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import BukibleForm from "../../../Components/Forms/BukibleForm/BukibleForm";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";
import { bukibleSelector, editBukible, getBukibleInfo } from "../../../redux/bukible/bukibleSlice";
import { clearBukibles } from "../../../redux/bukibles/bukiblesSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";

export default function EditBukiblePage() {
  const { _id, bukibleData, message, loading, error } = useSelector(bukibleSelector)
  const { bukibleId } = useParams()
  const dispatch = useAppDispatch()

  const {
    uploadImageFn,
  } = useUploadImage()

  useEffect(() => {
    if (loading !== 'pending' && bukibleId && _id !== bukibleId) {
      dispatch(getBukibleInfo(bukibleId))
    }
    return () => { }
  }, [bukibleId, _id, dispatch, loading, bukibleData])


  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (_id) {
      const editedBukible = await dispatch(editBukible({ _id, bukibleInfo: values })).unwrap()
      if (editedBukible._id) {
        dispatch(navigateTo(`/bukible/${editedBukible._id}`))
      }
      dispatch(clearBukibles())
      return editedBukible
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Bukible</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          {bukibleId && bukibleId === _id && bukibleData && (
            <BukibleForm
              disabled={false}
              initialValues={bukibleData}
              onSubmit={handleSubmit}
              uploadImageFn={uploadImageFn}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
