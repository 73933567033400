import { createAsyncThunk, createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardPaginatedBuilder, generateStandardNetworkPaginatedState, StandardPaginatedNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface BukiblesState extends StandardPaginatedNetworkState {
  bukiblesList: any[] | null
}

const generateEmptyBukiblesState = (): BukiblesState => {
  return {
    ...generateStandardNetworkPaginatedState(),
    bukiblesList: null,
  }
}

export const getBukiblesAction = createAsyncThunk(
  'bukibles/getBukiblesActionCall',
  async (queryInfo: {query: any, page: number, limit: number, all?: boolean}, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bukibles
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const bukiblesList = await thunkApi.extra.networkRequest.getBukibles(queryInfo.query, {page: queryInfo.page, limit: queryInfo.limit, all: queryInfo.all})
    if (bukiblesList.error) {
      return rejectWithValue({
        ...bukiblesList,
        currentQuery: queryInfo
      })
    }
    const {docs, ...paginationInfo} = bukiblesList
    return {
      docs,
      paginationInfo,
      currentQuery: queryInfo
    }
  }
)

const initialState: BukiblesState = generateEmptyBukiblesState()

export const bukiblesSlice = createSlice({
  name: 'bukibles',
  initialState,
  reducers: {
    clearBukibles: (state, action: PayloadAction) => {
      return {
        ...generateEmptyBukiblesState()
      }
    }
  },
  extraReducers: (builder) => {
    generalStandardPaginatedBuilder(builder, getBukiblesAction, 'bukiblesList')
  }
})

export const { clearBukibles } = bukiblesSlice.actions

export const bukiblesSelector = createSelector((state: RootState) => state, (state) => state.bukibles)

export default bukiblesSlice.reducer
