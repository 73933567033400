import { useSelector } from "react-redux"
import { useAppDispatch } from '../../redux/store'
import './account.scss'
import { accountSelector, changePassword, updateProfile } from '../../redux/account/accountSlice'
import { EditProfileForm } from '../../Components/Forms/EditProfileForm/EditProfileForm'
import { ChangePasswordForm } from '../../Components/Forms/ChangePasswordForm/ChangePasswordForm'
import { useState } from "react"
import { generalNetworkSelector, uploadImage } from "../../redux/generalNetwork/generalNetworkSlice"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"

export default function AccountPage() {

  const { username, firstName, lastName, profilePic, role, loading, error, message } = useSelector(accountSelector)
  const generalNetworkState = useSelector(generalNetworkSelector)
  const dispatch = useAppDispatch()

  const [editProfileMode, setEditProfileMode] = useState(false)
  const [editPasswordMode, setEditPasswordMode] = useState(false)

  return (
    <Container>
      <Row>
        <Col>
          <h1>Profile Page</h1>
          <hr />
          {profilePic && (
            <img alt={'profile pic'} className="profile-pic" src={profilePic} />
          )}
          <ul>
            <li>Username: {username}</li>
            <li>Name: {firstName} {lastName}</li>
            <li>Role: {role}</li>
          </ul>
          <hr />

          {error && (<> {JSON.stringify(error)} <br /></>)}
          {message && (<> {message} <br /></>)}

          {editProfileMode && (
            <EditProfileForm
              firstName={firstName}
              lastName={lastName}
              profilePic={profilePic}
              disabled={loading === 'pending' || generalNetworkState.uploadImage.loading === 'pending'}
              onSubmit={async (updatedProfile: { firstName?: string, lastName?: string, profilePic?: string }) => {
                await dispatch(updateProfile({
                  firstName: updatedProfile.firstName,
                  lastName: updatedProfile.lastName,
                  profilePic: updatedProfile.profilePic
                })).unwrap()
                return
              }}
              uploadImageFn={async (imageInfo: { imageToUpload: any, filename: string }) => {
                const uploadedImageUrl = await dispatch(uploadImage(imageInfo)).unwrap()
                return uploadedImageUrl
              }}
            />
          )}

          {editPasswordMode && (
            <ChangePasswordForm
              onSubmit={async (passwordInfo: { oldPassword: string, newPassword: string }) => {
                await dispatch(changePassword(passwordInfo)).unwrap()
              }}
              diabled={loading === 'pending'}
            />
          )}
          <Stack direction={"horizontal"} gap={3}>
            {!editPasswordMode && (
              <Button variant={editProfileMode ? 'danger':'primary'} onClick={() => setEditProfileMode(!editProfileMode)}>
              {editProfileMode ? 'Cancel':'Edit Profile'}
              </Button>
            )}
            {!editProfileMode && (
              <Button variant={editPasswordMode ? 'danger':'primary'} className="change-password-btn" onClick={() => setEditPasswordMode(!editPasswordMode)}>
              {editPasswordMode ? 'Cancel':'Change Password'}
              </Button>
            )}
          </Stack>
        </Col>
      </Row>
    </Container>
  )
}
