import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardCrudBuilder, generateStandardNetworkState, StandardNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface BookingState extends StandardNetworkState {
  _id: string | null
  bookingData: any
}

const generateEmptyBookingState = (): BookingState => {
  return {
    _id: '',
    bookingData: {},
    ...generateStandardNetworkState()
  }
}

const initialState = generateEmptyBookingState()

export const getBookingInfo = createAsyncThunk(
  'booking/getBookingInfoCall',
  async (bookingId: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().booking
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const bookingData = await thunkApi.extra.networkRequest.getBookingById(bookingId)
    if (bookingData.error) {
      return rejectWithValue({
        ...bookingData,
        _id: bookingId
      })
    }
    return {
      _id: bookingId,
      bookingData
    }
  }
)

export const addNewBooking = createAsyncThunk(
  'booking/addNewBookingCall',
  async (bookingPayload: { bookingInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().booking
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { bookingInfo } = bookingPayload
    const bookingData = await thunkApi.extra.networkRequest.addNewBooking(bookingInfo)
    if (bookingData.error) {
      return rejectWithValue({
        ...bookingData,
        _id: 999
      })
    }
    return {
      _id: bookingData._id,
      bookingData: {
        ...bookingData
      }
    }
  }
)

export const editBooking = createAsyncThunk(
  'booking/editBookingCall',
  async (bookingPayload: { _id: string, bookingInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().booking
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id, bookingInfo } = bookingPayload
    const bookingData = await thunkApi.extra.networkRequest.editBooking(_id, bookingInfo)
    if (bookingData.error) {
      return rejectWithValue({
        ...bookingData,
        _id: 999
      })
    }
    return {
      _id: bookingData._id,
      bookingData: {
        ...bookingData
      }
    }
  }
)

export const deleteBooking = createAsyncThunk(
  'booking/deleteBookingCall',
  async (bookingPayload: { _id: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().booking
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id } = bookingPayload
    const bookingData = await thunkApi.extra.networkRequest.deleteBooking(_id)
    if (!bookingData) {
      return rejectWithValue({
        error: 'Deletion failed',
        _id: 999
      })
    }
    if (bookingData.error) {
      return rejectWithValue({
        ...bookingData,
        _id: 999
      })
    }
    return {
      _id: bookingData._id,
      bookingData: {
        title: 'Booking Deleted'
      }
    }
  }
)

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    clearBooking: (state, action: PayloadAction) => {
      return {
        ...generateEmptyBookingState()
      }
    }
  },
  extraReducers: (builder) => {
    ([
      [getBookingInfo, 'getBookingInfo'],
      [addNewBooking, 'addNewBooking'],
      [editBooking, 'editBooking'],
      [deleteBooking, 'deleteBooking']
    ]).forEach(([action, actionName]: any) => {
      generalStandardCrudBuilder(builder, action, actionName)
    })
  }
})

export const { clearBooking } = bookingSlice.actions

export const bookingSelector = createSelector((state: RootState) => state, (state) => state.booking)

export default bookingSlice.reducer
