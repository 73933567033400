import { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Stack } from 'react-bootstrap'
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { getMyProperties } from '../../../redux/accountData/accountDataSlice'
import { clearProperties } from '../../../redux/properties/propertiesSlice'
import { deleteProperty, getPropertyInfo, propertySelector } from "../../../redux/property/propertySlice"
import { navigateTo } from '../../../redux/router/routerActions'
import { useAppDispatch } from "../../../redux/store"

export default function DeletePropertyPage() {
  const { _id, propertyData, message, loading, error } = useSelector(propertySelector)
  const { propertyId } = useParams()
  const dispatch = useAppDispatch()

  const [deleteClicked, setDeleteClicked] = useState(false)

  useEffect(() => {
    if (loading !== 'pending' && propertyId && _id !== propertyId) {
      dispatch(getPropertyInfo(propertyId))
    }
    return () => { }
  }, [propertyId, _id, dispatch, loading])

  return (
    <Container>
      <Row>
        <Col>
          <h1>Delete Property Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}

          {propertyId && propertyId === _id && propertyData && (
            <Stack gap={3}>
              <div>Are you sure you want to delete property: {_id}</div>

              <div>{propertyData.title}</div>

              <div>
                <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete Property</Button>
              </div>

              {deleteClicked && (
                <div>
                  Once you delete this property it will be gone forever. <br />
                  <Button variant={'danger'} disabled={loading === 'pending'} onClick={async () => {
                    const deleteValue = await dispatch(deleteProperty({_id: _id})).unwrap()
                    if(deleteValue) {
                      dispatch(clearProperties())
                      dispatch(getMyProperties())
                    }
                    dispatch(navigateTo(`/my-properties/`))
                  }}> Confirm Delete Property </Button>
                </div>
              )}
            </Stack>
          )}
        </Col>
      </Row>
    </Container>
  )
}
