import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import BukinEntry from "../../../Components/Bukins/BukinEntry"
import { bukinSelector, getBukinInfo } from "../../../redux/bukin/bukinSlice"
import { useAppDispatch } from "../../../redux/store"

export default function BukinPage() {
  const { _id, bukinData, message, loading, error} = useSelector(bukinSelector)
  const { bukinId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && bukinId && _id !== bukinId) {
      dispatch(getBukinInfo(bukinId))
    }
    return () => {}
  }, [bukinId, _id, dispatch, loading])

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single Bukin Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}

          {bukinId && bukinId === _id && bukinData && (
            <div className="bukininfo">
              <BukinEntry bukinInfo={bukinData} />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
