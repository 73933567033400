import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

// Define a type for the slice state
interface CounterState {
  value: number,
  loading: boolean,
  properties: any[]
}

// Define the initial state using that type
const initialState: CounterState = {
  value: 0,
  loading: false,
  properties: []
}

export const getProperties = createAsyncThunk(
  'counter/getPropertiesCall',
  async (arg, thunkApi: any) => {
    const { loading } = thunkApi.getState().counter
    if(!loading) {
      thunkApi.dispatch(setCounterLoading())
      const propertyList = await fetch('http://127.0.0.1:3000/properties/bro-properties').then((res) => {
        return res.json()
      })
      return propertyList
    }
  }
)

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload
    },
    setCounterLoading: (state) => {
      state.loading = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProperties.fulfilled, (state, action: any) => {
      state.properties = action.payload
      state.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, setCounterLoading } = counterSlice.actions

export const counterSelector = createSelector((state: RootState) => state, (state) => state.counter)

export default counterSlice.reducer
