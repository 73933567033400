import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardCrudBuilder, generateStandardNetworkState, StandardNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface MediaFileState extends StandardNetworkState {
  _id: string | null
  mediaFileData: any
}

const generateEmptyMediaFileState = (): MediaFileState => {
  return {
    _id: '',
    mediaFileData: {},
    ...generateStandardNetworkState()
  }
}

const initialState = generateEmptyMediaFileState()

export const getMediaFileInfo = createAsyncThunk(
  'mediaFile/getMediaFileInfoCall',
  async (mediaFileId: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().mediaFile
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const mediaFileData = await thunkApi.extra.networkRequest.getMediaFileById(mediaFileId)
    if (mediaFileData.error) {
      return rejectWithValue({
        ...mediaFileData,
        _id: mediaFileId
      })
    }
    return {
      _id: mediaFileId,
      mediaFileData
    }
  }
)

export const addNewMediaFile = createAsyncThunk(
  'mediaFile/addNewMediaFileCall',
  async (mediaFilePayload: { mediaFileInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().mediaFile
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { mediaFileInfo } = mediaFilePayload
    const mediaFileData = await thunkApi.extra.networkRequest.addNewMediaFile(mediaFileInfo)
    if (mediaFileData.error) {
      return rejectWithValue({
        ...mediaFileData,
        _id: 999
      })
    }
    return {
      _id: mediaFileData._id,
      mediaFileData: {
        ...mediaFileData
      }
    }
  }
)

export const editMediaFile = createAsyncThunk(
  'mediaFile/editMediaFileCall',
  async (mediaFilePayload: { _id: string, mediaFileInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().mediaFile
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id, mediaFileInfo } = mediaFilePayload
    const mediaFileData = await thunkApi.extra.networkRequest.editMediaFile(_id, mediaFileInfo)
    if (mediaFileData.error) {
      return rejectWithValue({
        ...mediaFileData,
        _id: 999
      })
    }
    return {
      _id: mediaFileData._id,
      mediaFileData: {
        ...mediaFileData
      }
    }
  }
)

export const deleteMediaFile = createAsyncThunk(
  'mediaFile/deleteMediaFileCall',
  async (mediaFilePayload: { _id: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().mediaFile
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id } = mediaFilePayload
    const mediaFileData = await thunkApi.extra.networkRequest.deleteMediaFile(_id)
    if (!mediaFileData) {
      return rejectWithValue({
        error: 'Deletion failed',
        _id: 999
      })
    }
    if (mediaFileData.error) {
      return rejectWithValue({
        ...mediaFileData,
        _id: 999
      })
    }
    return {
      _id: mediaFileData._id,
      mediaFileData: {
        title: 'MediaFile Deleted'
      }
    }
  }
)

export const mediaFileSlice = createSlice({
  name: 'mediaFile',
  initialState,
  reducers: {
    clearMediaFile: (state, action: PayloadAction) => {
      return {
        ...generateEmptyMediaFileState()
      }
    }
  },
  extraReducers: (builder) => {
    ([
      [getMediaFileInfo, 'getMediaFileInfo'],
      [addNewMediaFile, 'addNewMediaFile'],
      [editMediaFile, 'editMediaFile'],
      [deleteMediaFile, 'deleteMediaFile']
    ]).forEach(([action, actionName]: any) => {
      generalStandardCrudBuilder(builder, action, actionName)
    })
  }
})

export const { clearMediaFile } = mediaFileSlice.actions

export const mediaFileSelector = createSelector((state: RootState) => state, (state) => state.mediaFile)

export default mediaFileSlice.reducer
