import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { inquirySelector, getInquiryInfo } from "../../../redux/inquiry/inquirySlice"
import { useAppDispatch } from "../../../redux/store"

export default function InquiryPage() {
  const { _id, inquiryData, message, loading, error} = useSelector(inquirySelector)
  const { inquiryId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && inquiryId && _id !== inquiryId) {
      dispatch(getInquiryInfo(inquiryId))
    }
    return () => {}
  }, [inquiryId, _id, dispatch, loading])

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single Inquiry Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}

          {inquiryId && inquiryId === _id && inquiryData && (
            <div className="inquiryinfo">
              <pre>
                {JSON.stringify(inquiryData, null, 2)}            
              </pre>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
