import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import PropertyTaskForm from "../../../Components/Forms/PropertyTaskForm/PropertyTaskForm";
import useActiveQueryHook from "../../../Hooks/UseActiveQuery/useActiveQueryHook";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import useUserRefs from "../../../Hooks/UseUsersQuery/UseUserRefs";
import useUsersSelectValues from "../../../Hooks/UseUsersQuery/UseUsersSelectValues";
import { accountSelector } from "../../../redux/account/accountSlice";
import { uploadImage } from "../../../redux/generalNetwork/generalNetworkSlice";
import { addNewPropertyTask } from "../../../redux/propertyTask/propertyTaskSlice";
import { clearPropertyTasks } from "../../../redux/propertyTasks/propertyTasksSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";

export default function AddPropertyTaskPage() {

    const userRef = useUserRefs()
    const userSelectOptions = useUsersSelectValues(userRef)

    const { myProperties } = useMyProperties()
    const myPropertySelectOptions = useMyPropertiesSelectValues(myProperties)
    const { email, role } = useSelector(accountSelector)
    const [imageUploadProgress, setImageUploadProgress] = useState('')

    const dispatch = useAppDispatch()

    const { activeQuery } = useActiveQueryHook({})
    const initialData = activeQuery.query

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Add Property Task</h1>
                    <hr />
                    {email && role && (
                        <PropertyTaskForm
                            initialValues={{
                                createdBy: email,
                                status: role === 'admin' || role.indexOf('lead') > -1 ? 'approved' : 'new',
                                ...initialData
                            }}
                            disabled={false}
                            userSelectOptions={userSelectOptions}
                            onSubmit={async (values) => {
                                const newTask = await dispatch(addNewPropertyTask({ propertyTaskInfo: values })).unwrap()
                                if (newTask._id) {
                                    dispatch(navigateTo(`/property-task/${newTask._id}`))
                                }
                                dispatch(clearPropertyTasks())
                                return newTask

                            }}
                            propertyOptionList={myPropertySelectOptions}
                            uploadImageFn={async (imageInfo: { imageToUpload: any, filename: string }) => {
                                const uploadedImageUrl = await dispatch(uploadImage(imageInfo)).unwrap()
                                return uploadedImageUrl
                            }}
                            imageUploadProgress={imageUploadProgress}
                            uploadImageMultiFn={async (imageInfo: { imageToUpload: any, filename: string }[]) => {
                                const uploadedImageUrls = []
                                for (let i = 0; i < imageInfo.length; i++) {
                                    setImageUploadProgress(`Processing ${i + 1} of ${imageInfo.length}`)
                                    const uploadedImageUrl = await dispatch(uploadImage(imageInfo[i])).unwrap()
                                    uploadedImageUrls.push(uploadedImageUrl)
                                }
                                setImageUploadProgress(``)
                                return uploadedImageUrls
                            }}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    )
}