import { createAsyncThunk, createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardPaginatedBuilder, generateStandardNetworkPaginatedState, StandardPaginatedNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface MediaFilesState extends StandardPaginatedNetworkState {
  mediaFilesList: any[] | null
}

const generateEmptyMediaFilesState = (): MediaFilesState => {
  return {
    ...generateStandardNetworkPaginatedState(),
    mediaFilesList: null,
  }
}

export const getMediaFilesAction = createAsyncThunk(
  'mediaFiles/getMediaFilesActionCall',
  async (queryInfo: {query: any, page: number, limit: number, all?: boolean}, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().mediaFiles
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const mediaFilesList = await thunkApi.extra.networkRequest.getMediaFiles(queryInfo.query, {page: queryInfo.page, limit: queryInfo.limit, all: queryInfo.all})
    if (mediaFilesList.error) {
      return rejectWithValue({
        ...mediaFilesList,
        currentQuery: queryInfo
      })
    }
    const {docs, ...paginationInfo} = mediaFilesList
    return {
      docs,
      paginationInfo,
      currentQuery: queryInfo
    }
  }
)

const initialState: MediaFilesState = generateEmptyMediaFilesState()

export const mediaFilesSlice = createSlice({
  name: 'mediaFiles',
  initialState,
  reducers: {
    clearMediaFiles: (state, action: PayloadAction) => {
      return {
        ...generateEmptyMediaFilesState()
      }
    }
  },
  extraReducers: (builder) => {
    generalStandardPaginatedBuilder(builder, getMediaFilesAction, 'mediaFilesList')
  }
})

export const { clearMediaFiles } = mediaFilesSlice.actions

export const mediaFilesSelector = createSelector((state: RootState) => state, (state) => state.mediaFiles)

export default mediaFilesSlice.reducer
