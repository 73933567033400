import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import BookingGuestForm from "../../../Components/Forms/BookingGuestForm/BookingGuestForm";
import { addNewBookingGuest, bookingGuestSelector } from "../../../redux/bookingGuest/bookingGuestSlice";
import { clearBookingGuests } from "../../../redux/bookingGuests/bookingGuestsSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";

export default function AddBookingGuestPage() {

  const { myProperties } = useMyProperties()
  const propertyOptions = useMyPropertiesSelectValues(myProperties)
  
  const {
    // loading,
    error,
    message,
  } = useSelector(bookingGuestSelector)

  const { uploadImageFn, uploadImageMultiFn, imageUploadProgress } = useUploadImage()

  const dispatch = useAppDispatch()
  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    const newBookingGuest = await dispatch(addNewBookingGuest({bookingGuestInfo: values})).unwrap()
    if (newBookingGuest._id) {
      dispatch(navigateTo(`/booking-guest/${newBookingGuest._id}`))
    }
    dispatch(clearBookingGuests())
    return newBookingGuest
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add BookingGuest</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          <BookingGuestForm
            initialValues={{}}
            disabled={false}
            onSubmit={handleSubmit}
            uploadImageFn={uploadImageFn}
            uploadImageMultiFn={uploadImageMultiFn}
            imageUploadProgress={imageUploadProgress}
            propertyOptionList={propertyOptions}
          />

        </Col>
      </Row>
    </Container>
  )
}
