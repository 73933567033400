import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import InquiryForm from "../../../Components/Forms/InquiryForm/InquiryForm";
import { inquirySelector, editInquiry, getInquiryInfo } from "../../../redux/inquiry/inquirySlice";
import { clearInquiries } from "../../../redux/inquiries/inquiriesSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";

export default function EditInquiryPage() {
  const { _id, inquiryData, message, loading, error } = useSelector(inquirySelector)
  const { inquiryId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && inquiryId && _id !== inquiryId) {
      dispatch(getInquiryInfo(inquiryId))
    }
    return () => { }
  }, [inquiryId, _id, dispatch, loading, inquiryData])


  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (_id) {
      const editedInquiry = await dispatch(editInquiry({ _id, inquiryInfo: values })).unwrap()
      if (editedInquiry._id) {
        dispatch(navigateTo(`/inquiry/${editedInquiry._id}`))
      }
      dispatch(clearInquiries())
      return editedInquiry
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Inquiry</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          {inquiryId && inquiryId === _id && inquiryData && (
            <InquiryForm
              disabled={false}
              initialValues={inquiryData}
              onSubmit={handleSubmit}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
