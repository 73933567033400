import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import BookingGuestForm from "../../../Components/Forms/BookingGuestForm/BookingGuestForm";
import { bookingGuestSelector, editBookingGuest, getBookingGuestInfo } from "../../../redux/bookingGuest/bookingGuestSlice";
import { clearBookingGuests } from "../../../redux/bookingGuests/bookingGuestsSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";

export default function EditBookingGuestPage() {

  const { myProperties } = useMyProperties()
  const propertyOptions = useMyPropertiesSelectValues(myProperties)

  const { _id, bookingGuestData, message, loading, error } = useSelector(bookingGuestSelector)
  const { bookingGuestId } = useParams()
  const dispatch = useAppDispatch()

  const { uploadImageFn, uploadImageMultiFn, imageUploadProgress } = useUploadImage()

  useEffect(() => {
    if (loading !== 'pending' && bookingGuestId && _id !== bookingGuestId) {
      dispatch(getBookingGuestInfo(bookingGuestId))
    }
    return () => { }
  }, [bookingGuestId, _id, dispatch, loading, bookingGuestData])


  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (_id) {
      const editedBookingGuest = await dispatch(editBookingGuest({ _id, bookingGuestInfo: values })).unwrap()
      if (editedBookingGuest._id) {
        dispatch(navigateTo(`/booking-guest/${editedBookingGuest._id}`))
      }
      dispatch(clearBookingGuests())
      return editedBookingGuest
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit BookingGuest</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          {bookingGuestId && bookingGuestId === _id && bookingGuestData && (
            <BookingGuestForm
              disabled={false}
              initialValues={bookingGuestData}
              onSubmit={handleSubmit}
              uploadImageFn={uploadImageFn}
              uploadImageMultiFn={uploadImageMultiFn}
              imageUploadProgress={imageUploadProgress}
              propertyOptionList={propertyOptions}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
