import { useEffect, useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { bukibleSelector,  deleteBukible, getBukibleInfo } from "../../../redux/bukible/bukibleSlice"
import { clearBukibles } from "../../../redux/bukibles/bukiblesSlice"
import { navigateTo } from "../../../redux/router/routerActions"
import { useAppDispatch } from "../../../redux/store"

export default function DeleteBukiblePage() {
    const { _id, bukibleData, message, loading, error } = useSelector(bukibleSelector)
    const { bukibleId } = useParams()
    const dispatch = useAppDispatch()

    const [deleteClicked, setDeleteClicked] = useState(false)

    useEffect(() => {
        if (loading !== 'pending' && bukibleId && _id !== bukibleId) {
            dispatch(getBukibleInfo(bukibleId))
        }
        return () => { }
    }, [bukibleId, _id, dispatch, loading])

    const bukibleDeleteFn = async () => {
        if (_id) {
            const deleteValue = await dispatch(deleteBukible({ _id: _id })).unwrap()
            if (deleteValue) {
                dispatch(clearBukibles())
                dispatch(navigateTo(`/bukibles/`))
            }
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Delete Bukible Page</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {message && (<>{message}<br /></>)}

                    {bukibleId && bukibleId === _id && bukibleData && (
                        <div className="bukible-info">
                            {JSON.stringify(bukibleData)}

                            <Stack gap={3}>
                                <div>Are you sure you want to delete Bukible: {_id}</div>
                                <div>
                                    <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete Bukible</Button>
                                </div>
                                {deleteClicked && (
                                    <div>
                                        Once you delete this bukible it will be gone forever. <br />
                                        <Button variant={'danger'} disabled={loading === 'pending'} onClick={() => {
                                            bukibleDeleteFn()
                                        }}> Confirm Delete Bukible </Button>
                                    </div>
                                )}
                            </Stack>

                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
