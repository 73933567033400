import { createAsyncThunk, createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardPaginatedBuilder, generateStandardNetworkPaginatedState, StandardPaginatedNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface PurchasesState extends StandardPaginatedNetworkState {
  purchasesList: any[] | null
}

const generateEmptyPurchasesState = (): PurchasesState => {
  return {
    ...generateStandardNetworkPaginatedState(),
    purchasesList: null,
  }
}

export const getPurchasesAction = createAsyncThunk(
  'purchases/getPurchasesActionCall',
  async (queryInfo: {query: any, page: number, limit: number, all?: boolean}, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().purchases
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const purchasesList = await thunkApi.extra.networkRequest.getPurchases(queryInfo.query, {page: queryInfo.page, limit: queryInfo.limit, all: queryInfo.all})
    if (purchasesList.error) {
      return rejectWithValue({
        ...purchasesList,
        currentQuery: queryInfo
      })
    }
    const {docs, ...paginationInfo} = purchasesList
    return {
      docs,
      paginationInfo,
      currentQuery: queryInfo
    }
  }
)

const initialState: PurchasesState = generateEmptyPurchasesState()

export const purchasesSlice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    clearPurchases: (state, action: PayloadAction) => {
      return {
        ...generateEmptyPurchasesState()
      }
    }
  },
  extraReducers: (builder) => {
    generalStandardPaginatedBuilder(builder, getPurchasesAction, 'purchasesList')
  }
})

export const { clearPurchases } = purchasesSlice.actions

export const purchasesSelector = createSelector((state: RootState) => state, (state) => state.purchases)

export default purchasesSlice.reducer
