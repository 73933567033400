import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { purchaseSelector, getPurchaseInfo } from "../../../redux/purchase/purchaseSlice"
import { useAppDispatch } from "../../../redux/store"
import PurchaseEntry from "../../../Components/Purchases/PurchaseEntry"

export default function PurchasePage() {
  const { _id, purchaseData, message, loading, error } = useSelector(purchaseSelector)
  const { purchaseId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && purchaseId && _id !== purchaseId) {
      dispatch(getPurchaseInfo(purchaseId))
    }
    return () => { }
  }, [purchaseId, _id, dispatch, loading])

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single Purchase Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}

          {purchaseId && purchaseId === _id && purchaseData && (
            <div className="purchaseinfo">
              {/* <pre>
                {JSON.stringify(purchaseData, null, 2)}            
              </pre> */}
              <PurchaseEntry
                purchaseData={purchaseData}
                fullView={true}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
