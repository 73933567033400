import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardCrudBuilder, generateStandardNetworkState, StandardNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface PropertyTaskState extends StandardNetworkState {
  _id: string | null
  propertyTaskData: any
}

const generateEmptyPropertyTaskState = (): PropertyTaskState => {
  return {
    _id: '',
    propertyTaskData: {},
    ...generateStandardNetworkState()
  }
}

const initialState = generateEmptyPropertyTaskState()

export const getPropertyTaskInfo = createAsyncThunk(
  'propertyTask/getPropertyTaskInfoCall',
  async (propertyTaskId: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().propertyTask
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const propertyTaskData = await thunkApi.extra.networkRequest.getPropertyTaskById(propertyTaskId)
    if (propertyTaskData.error) {
      return rejectWithValue({
        ...propertyTaskData,
        _id: propertyTaskId
      })
    }
    return {
      _id: propertyTaskId,
      propertyTaskData
    }
  }
)

export const addNewPropertyTask = createAsyncThunk(
  'propertyTask/addNewPropertyTaskCall',
  async (propertyTaskPayload: { propertyTaskInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().propertyTask
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { propertyTaskInfo } = propertyTaskPayload
    const propertyTaskData = await thunkApi.extra.networkRequest.addNewPropertyTask(propertyTaskInfo)
    if (propertyTaskData.error) {
      return rejectWithValue({
        ...propertyTaskData,
        _id: 999
      })
    }
    return {
      _id: propertyTaskData._id,
      propertyTaskData: {
        ...propertyTaskData
      }
    }
  }
)

export const editPropertyTask = createAsyncThunk(
  'propertyTask/editPropertyTaskCall',
  async (propertyTaskPayload: { _id: string, propertyTaskInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().propertyTask
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id, propertyTaskInfo } = propertyTaskPayload
    const propertyTaskData = await thunkApi.extra.networkRequest.editPropertyTask(_id, propertyTaskInfo)
    if (propertyTaskData.error) {
      return rejectWithValue({
        ...propertyTaskData,
        _id: 999
      })
    }
    return {
      _id: propertyTaskData._id,
      propertyTaskData: {
        ...propertyTaskData
      }
    }
  }
)

export const deletePropertyTask = createAsyncThunk(
  'propertyTask/deletePropertyTaskCall',
  async (propertyTaskPayload: { _id: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().propertyTask
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id } = propertyTaskPayload
    const propertyTaskData = await thunkApi.extra.networkRequest.deletePropertyTask(_id)
    if (!propertyTaskData) {
      return rejectWithValue({
        error: 'Deletion failed',
        _id: 999
      })
    }
    if (propertyTaskData.error) {
      return rejectWithValue({
        ...propertyTaskData,
        _id: 999
      })
    }
    return {
      _id: propertyTaskData._id,
      propertyTaskData: {
        title: 'PropertyTask Deleted'
      }
    }
  }
)

export const propertyTaskSlice = createSlice({
  name: 'propertyTask',
  initialState,
  reducers: {
    clearPropertyTask: (state, action: PayloadAction) => {
      return {
        ...generateEmptyPropertyTaskState()
      }
    }
  },
  extraReducers: (builder) => {
    ([
      [getPropertyTaskInfo, 'getPropertyTaskInfo'],
      [addNewPropertyTask, 'addNewPropertyTask'],
      [editPropertyTask, 'editPropertyTask'],
      [deletePropertyTask, 'deletePropertyTask']
    ]).forEach(([action, actionName]: any) => {
      generalStandardCrudBuilder(builder, action, actionName)
    })
  }
})

export const { clearPropertyTask } = propertyTaskSlice.actions

export const propertyTaskSelector = createSelector((state: RootState) => state, (state) => state.propertyTask)

export default propertyTaskSlice.reducer
