import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardPaginatedBuilder, generateStandardNetworkPaginatedState, StandardPaginatedNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface PropertiesState extends StandardPaginatedNetworkState {
  propertyList: any[] | null
}

const generateEmptyPropertiesState = (): PropertiesState => {
  return {
    ...generateStandardNetworkPaginatedState(),
    propertyList: null,
  }
}

const initialState: PropertiesState = generateEmptyPropertiesState()

export const getPropertiesAction = createAsyncThunk(
  'properties/getPropertiesActionCall',
  async (queryInfo: {query: any, page: number, limit: number}, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().properties
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const propertiesList = await thunkApi.extra.networkRequest.getProperties(queryInfo.query, {page: queryInfo.page, limit: queryInfo.limit})
    if (propertiesList.error) {
      return rejectWithValue({
        ...propertiesList,
        currentQuery: queryInfo
      })
    }
    const {docs, ...paginationInfo} = propertiesList
    return {
      docs,
      paginationInfo,
      currentQuery: queryInfo
    }
  }
)

export const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    clearProperties: (state, action: PayloadAction) => {
      return {
        ...generateEmptyPropertiesState()
      }
    }
  },
  extraReducers: (builder) => {
    generalStandardPaginatedBuilder(builder, getPropertiesAction, 'propertyList')
  }
})

export const { clearProperties } = propertiesSlice.actions

export const propertiesSelector = createSelector((state: RootState) => state, (state) => state.properties)

export default propertiesSlice.reducer
