import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardCrudBuilder, generateStandardNetworkState, StandardNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface DateBlockState extends StandardNetworkState {
  _id: string | null
  dateBlockData: any
}

const generateEmptyDateBlockState = (): DateBlockState => {
  return {
    _id: '',
    dateBlockData: {},
    ...generateStandardNetworkState()
  }
}

const initialState = generateEmptyDateBlockState()

export const getDateBlockInfo = createAsyncThunk(
  'dateBlock/getDateBlockInfoCall',
  async (dateBlockId: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().dateBlock
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const dateBlockData = await thunkApi.extra.networkRequest.getDateBlockById(dateBlockId)
    if (dateBlockData.error) {
      return rejectWithValue({
        ...dateBlockData,
        _id: dateBlockId
      })
    }
    return {
      _id: dateBlockId,
      dateBlockData
    }
  }
)

export const addNewDateBlock = createAsyncThunk(
  'dateBlock/addNewDateBlockCall',
  async (dateBlockPayload: { dateBlockInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().dateBlock
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { dateBlockInfo } = dateBlockPayload
    const dateBlockData = await thunkApi.extra.networkRequest.addNewDateBlock(dateBlockInfo)
    if (dateBlockData.error) {
      return rejectWithValue({
        ...dateBlockData,
        _id: 999
      })
    }
    return {
      _id: dateBlockData._id,
      dateBlockData: {
        ...dateBlockData
      }
    }
  }
)

export const editDateBlock = createAsyncThunk(
  'dateBlock/editDateBlockCall',
  async (dateBlockPayload: { _id: string, dateBlockInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().dateBlock
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id, dateBlockInfo } = dateBlockPayload
    const dateBlockData = await thunkApi.extra.networkRequest.editDateBlock(_id, dateBlockInfo)
    if (dateBlockData.error) {
      return rejectWithValue({
        ...dateBlockData,
        _id: 999
      })
    }
    return {
      _id: dateBlockData._id,
      dateBlockData: {
        ...dateBlockData
      }
    }
  }
)

export const deleteDateBlock = createAsyncThunk(
  'dateBlock/deleteDateBlockCall',
  async (dateBlockPayload: { _id: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().dateBlock
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id } = dateBlockPayload
    const dateBlockData = await thunkApi.extra.networkRequest.deleteDateBlock(_id)
    if (!dateBlockData) {
      return rejectWithValue({
        error: 'Deletion failed',
        _id: 999
      })
    }
    if (dateBlockData.error) {
      return rejectWithValue({
        ...dateBlockData,
        _id: 999
      })
    }
    return {
      _id: dateBlockData._id,
      dateBlockData: {
        title: 'DateBlock Deleted'
      }
    }
  }
)

export const dateBlockSlice = createSlice({
  name: 'dateBlock',
  initialState,
  reducers: {
    clearDateBlock: (state, action: PayloadAction) => {
      return {
        ...generateEmptyDateBlockState()
      }
    }
  },
  extraReducers: (builder) => {
    ([
      [getDateBlockInfo, 'getDateBlockInfo'],
      [addNewDateBlock, 'addNewDateBlock'],
      [editDateBlock, 'editDateBlock'],
      [deleteDateBlock, 'deleteDateBlock']
    ]).forEach(([action, actionName]: any) => {
      generalStandardCrudBuilder(builder, action, actionName)
    })
  }
})

export const { clearDateBlock } = dateBlockSlice.actions

export const dateBlockSelector = createSelector((state: RootState) => state, (state) => state.dateBlock)

export default dateBlockSlice.reducer
