import { useEffect, useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { buildingSelector,  deleteBuilding, getBuildingInfo } from "../../../redux/building/buildingSlice"
import { clearBuildings } from "../../../redux/buildings/buildingsSlice"
import { navigateTo } from "../../../redux/router/routerActions"
import { useAppDispatch } from "../../../redux/store"

export default function DeleteBuildingPage() {
    const { _id, buildingData, message, loading, error } = useSelector(buildingSelector)
    const { buildingId } = useParams()
    const dispatch = useAppDispatch()

    const [deleteClicked, setDeleteClicked] = useState(false)

    useEffect(() => {
        if (loading !== 'pending' && buildingId && _id !== buildingId) {
            dispatch(getBuildingInfo(buildingId))
        }
        return () => { }
    }, [buildingId, _id, dispatch, loading])

    const buildingDeleteFn = async () => {
        if (_id) {
            const deleteValue = await dispatch(deleteBuilding({ _id: _id })).unwrap()
            if (deleteValue) {
                dispatch(clearBuildings())
                dispatch(navigateTo(`/buildings/`))
            }
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Delete Building Page</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {message && (<>{message}<br /></>)}

                    {buildingId && buildingId === _id && buildingData && (
                        <div className="building-info">
                            {JSON.stringify(buildingData)}

                            <Stack gap={3}>
                                <div>Are you sure you want to delete Building: {_id}</div>
                                <div>
                                    <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete Building</Button>
                                </div>
                                {deleteClicked && (
                                    <div>
                                        Once you delete this building it will be gone forever. <br />
                                        <Button variant={'danger'} disabled={loading === 'pending'} onClick={() => {
                                            buildingDeleteFn()
                                        }}> Confirm Delete Building </Button>
                                    </div>
                                )}
                            </Stack>

                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
