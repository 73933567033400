import { Card } from "react-bootstrap";
import { BookingRequestEntryType } from "../../Types/bookingRequestTypes";
import timeService from "../../services/time/timeService";
import { Link } from "react-router-dom";

export default function BookingRequestEntry(props: {
    bookingRequest: BookingRequestEntryType,
    // properyInfo?: any,
    // full?: boolean,
}) {
    const {
        bookingRequest,
        // propertyInfo,
    } = props

    const {
        _id,
        guestName,
        email,
        phone,
        message,
        created,
        checkIn,
        checkOut,
        // propertyId,
        propertyName
    } = bookingRequest

    return (
        <Card className="mb-3">
            <Card.Body>
                <Card.Title>
                    <b>{guestName}</b>
                </Card.Title>
                <Card.Text>
                    <b>Email:</b> {email} <br />
                    <b>Phone:</b> {phone} <br />
                    <b>Created:</b> {timeService.getFormattedTime(created)} <br />
                    <b>Stay:</b> {timeService.getFormattedTime(checkIn, 'MM/dd')} - {timeService.getFormattedTime(checkOut, 'MM/dd/yyyy')} <br />
                    <b>Property:</b> {propertyName} <br />
                </Card.Text>
                {message && (<>
                    <b>Message:</b> <br />
                    <pre style={{
                        // background: '#eee',
                        marginTop: '10px',
                        border: '1px solid #ddd',
                        borderRadius: '5px',
                        padding: '10px',
                    }}>
                        {message}
                    </pre>
                </>)}
            </Card.Body>

            <Card.Footer>
                <Link className="btn btn-primary btn-sm" to={`/booking-request/${_id}`}>View</Link>
                <Link className="btn btn-secondary btn-sm" to={`/edit-booking-request/${_id}`}>Edit</Link>
                <Link className="btn btn-danger btn-sm" to={`/delete-booking-request/${_id}`}>Delete</Link>

            </Card.Footer>
        </Card>
    )
}