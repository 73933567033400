import { useEffect, useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { BookingEntry } from "../../../Components/Bookings/BookingEntry/BookingEntry"
import { isParent } from "../../../Helpers/Property/isParent"
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook"
import useRelatedBookings from "../../../Hooks/UseRelatedBookings/UseRelatedBookings"
import { bookingSelector, clearBooking, deleteBooking, getBookingInfo } from "../../../redux/booking/bookingSlice"
import { clearBookings } from "../../../redux/bookings/bookingsSlice"
import { deleteMultipleBookings } from "../../../redux/generalNetwork/generalNetworkSlice"
import { navigateTo } from "../../../redux/router/routerActions"
import { useAppDispatch } from "../../../redux/store"

export default function DeleteBookingPage() {
  const { _id, bookingData, message, loading, error } = useSelector(bookingSelector)
  const { bookingId } = useParams()
  const dispatch = useAppDispatch()

  const [deleteClicked, setDeleteClicked] = useState(false)

  const { myProperties } = useMyProperties()
  const relatedBookings = useRelatedBookings(bookingId, bookingData, myProperties)

  useEffect(() => {
    if (loading !== 'pending' && bookingId && _id !== bookingId) {
      dispatch(getBookingInfo(bookingId))
    }
    return () => { }
  }, [bookingId, _id, dispatch, loading])

  const bookingDeleteFn = async (settings: {
    deleteRelated?: boolean,
  } = {}) => {
    const {
      deleteRelated
    } = settings

    if (deleteRelated && relatedBookings) {
      const bookingsToDelete: string[] = [
        ...(relatedBookings.map(x => x._id ? x._id : '999')),
        _id ? _id : '999',
      ]
      const deleteMultipleValue: any = await dispatch(deleteMultipleBookings(bookingsToDelete))
      if(deleteMultipleValue) {
        dispatch(clearBookings())
        dispatch(navigateTo('/bookings'))
        dispatch(clearBooking())
      }
      return 
    }
    if (_id) {
      const deleteValue = await dispatch(deleteBooking({ _id: _id })).unwrap()
      if (deleteValue) {
        dispatch(clearBookings())
        dispatch(navigateTo(`/bookings/`))
      }
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Delete Booking Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}

          {bookingId && bookingId === _id && bookingData && (
            <div className="booking-info">
              <BookingEntry
                bookingInfo={bookingData}
                propertyName={myProperties[bookingData.propertyId]?.title}
                options={{
                  details: true,
                  disableLinks: {
                    delete: true,
                  }
                }}
              />

              {relatedBookings && relatedBookings.length > 0 && (
                <div className="related-bookings">
                  <h3>Related Bookings</h3>
                  <div className="related-bookings-list">
                    {relatedBookings.map(relatedBooking => (
                      <BookingEntry
                        key={relatedBooking._id}
                        bookingInfo={relatedBooking}
                        propertyName={myProperties[relatedBooking.propertyId]?.title}
                        options={{
                          header: isParent(myProperties[relatedBooking.propertyId], myProperties[bookingData.propertyId]) ? 'PARENT' : 'CHILD',
                          details: true,
                          disableLinks: {}
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}

              <Stack gap={3}>
                <div>Are you sure you want to delete Booking: {_id}</div>
                <div>
                  <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete Booking</Button>
                </div>
                {deleteClicked && (
                  <div>
                    Once you delete this booking it will be gone forever. <br />
                    <Stack direction="horizontal" gap={3}>
                      <Button variant={'danger'} disabled={loading === 'pending'} onClick={() => {
                        bookingDeleteFn()
                      }}> Confirm Delete Booking </Button>
                      {relatedBookings && relatedBookings.length > 0 && (
                        <Button variant={'danger'} disabled={loading === 'pending'} onClick={() => {
                          bookingDeleteFn({ deleteRelated: true })
                        }}> Confirm Delete Booking and Relatives </Button>
                      )}
                    </Stack>
                  </div>
                )}
              </Stack>

            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
