import { purchasesSelector, getPurchasesAction } from "../../redux/purchases/purchasesSlice"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/store"
import { useEffect } from "react"


export default function usePurchaseQuery(activeQuery: {
    query: any,
    page: number
    limit: number,
    all?: boolean
}, options: {
    skip?: boolean
} = {}) {
    const {
        skip = false
    } = options
    const {
        loading,
        error,
        currentRequestId,
        paginationInfo,
        currentQuery,
        message,
        purchasesList,
    } = useSelector(purchasesSelector)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (loading !== 'pending' && JSON.stringify(activeQuery) !== JSON.stringify(currentQuery) && !skip) {
            dispatch(getPurchasesAction(activeQuery))
        }
        return () => { }
    }, [activeQuery, currentQuery, dispatch, loading])

    if(skip) {
        return { loading, error, message, purchasesList: [], paginationInfo, currentRequestId, currentQuery }
    }

    return { loading, error, message, purchasesList, paginationInfo, currentRequestId, currentQuery }

}