import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { generalStandardCrudBuilder, generateStandardNetworkState, StandardNetworkState } from "../reduxHelperFunctions";
import { RootState } from "../store";

interface PropertyState extends StandardNetworkState {
  _id: string | null
  propertyData: any
}

const generateEmptyPropertyState = (): PropertyState => {
  return {
    _id: '',
    propertyData: {},
    ...generateStandardNetworkState()
  }
}

const initialState = generateEmptyPropertyState()

export const getPropertyInfo = createAsyncThunk(
  'property/getPropertyInfoCall',
  async (propertyId: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().property
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const propertyData = await thunkApi.extra.networkRequest.getPropertyById(propertyId)
    if (propertyData.error) {
      return rejectWithValue({
        ...propertyData,
        _id: propertyId
      })
    }
    return {
      _id: propertyId,
      propertyData
    }
  }
)

export const addNewProperty = createAsyncThunk(
  'property/addNewPropertyCall',
  async (propertyInfo: any, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().property
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const propertyData = await thunkApi.extra.networkRequest.addNewProperty(propertyInfo)
    if (propertyData.error) {
      return rejectWithValue({
        ...propertyData,
        _id: 999
      })
    }
    return {
      _id: propertyData._id,
      propertyData: {
        ...propertyData
      }
    }
  }
)

export const editProperty = createAsyncThunk(
  'property/editPropertyCall',
  async (propertyPayload: { _id: string, propertyInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().property
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id, propertyInfo } = propertyPayload
    const propertyData = await thunkApi.extra.networkRequest.editProperty(_id, propertyInfo)
    if (propertyData.error) {
      return rejectWithValue({
        ...propertyData,
        _id: 999
      })
    }
    return {
      _id: propertyData._id,
      propertyData: {
        ...propertyData
      }
    }
  }
)

export const deleteProperty = createAsyncThunk(
  'property/deletePropertyCall',
  async (propertyPayload: { _id: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().property
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id } = propertyPayload
    const propertyData = await thunkApi.extra.networkRequest.deleteProperty(_id)
    if (!propertyData) {
      return rejectWithValue({
        error: 'Deletion failed',
        _id: 999
      })
    }
    if (propertyData.error) {
      return rejectWithValue({
        ...propertyData,
        _id: 999
      })
    }
    return {
      _id: propertyData._id,
      propertyData: {
        title: 'Property Deleted'
      }
    }
  }
)

export const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    clearProperty: (state, action: PayloadAction) => {
      return {
        ...generateEmptyPropertyState()
      }
    }
  },
  extraReducers: (builder) => {
    ([
      [getPropertyInfo, 'getPropertyInfo'],
      [addNewProperty, 'addNewProperty'],
      [editProperty, 'editProperty'],
      [deleteProperty, 'deleteProperty']
    ]).forEach(([action, actionName]: any) => {
      generalStandardCrudBuilder(builder, action, actionName)
    })
  }
})

export const { clearProperty } = propertySlice.actions

export const propertySelector = createSelector((state: RootState) => state, (state) => state.property)

export default propertySlice.reducer
