import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { ErrorMessage } from "formik";
import timeService from "../../services/time/timeService";
import { PropertyBookingEntry } from "../../Types/bookingTypes";
import { PropertyType } from "../../Types/propertyTypes";
import { generalNetworkBuilder, generateStandardNetworkPaginatedState, generateStandardNetworkState, standardNetworkErrorCall, standardNetworkPendingCall, StandardNetworkState, StandardPaginationState } from "../reduxHelperFunctions";
import { RootState } from "../store";
import { PropertyPriceEntryType } from "../../Types/propertyPriceTypes";

interface GeneralNetworkStateEntry extends StandardNetworkState {
  data?: any
  currentQuery?: any
  paginationInfo?: StandardPaginationState | null
}

interface GeneralNetworkState {
  [callName: string]: GeneralNetworkStateEntry
}

const generateEmptyNetworkState = (): GeneralNetworkState => {
  return {
    uploadImage: { ...generateStandardNetworkState() },
    // property related
    importProperties: { ...generateStandardNetworkState() },
    bulkEditProperties: { ...generateStandardNetworkState() },

    // property price related
    importPropertyPrices: { ...generateStandardNetworkState() },
    bulkEditPropertyPrices: { ...generateStandardNetworkState() },
    loosePropertyPricesQuery: { ...generateStandardNetworkState(), data: null },

    // booking related
    checkBookingDates: { ...generateStandardNetworkState() },
    looseBookingQuery: { ...generateStandardNetworkState(), data: null },
    importBookings: { ...generateStandardNetworkState() },
    bulkEditBookings: { ...generateStandardNetworkState() },
    deleteMultipleBookings: { ...generateStandardNetworkState() },
    // tool related
    fontaineRates: {
      ...generateStandardNetworkPaginatedState(),
      data: null
    },
    // booking message related
    bookingMessage: { ...generateStandardNetworkState() },
    // booking guest related
    sendBookingGuestEmail: { ...generateStandardNetworkState() },

    // block related
    checkBlockDates: { ...generateStandardNetworkState() },


    // general email related
    sendBookingConfirmedEmail: { ...generateStandardNetworkState() },

  }
}

const initialState: GeneralNetworkState = generateEmptyNetworkState()

export const uploadImage = createAsyncThunk(
  'generalNetwork/uploadImageCall',
  async (uploadImageInfo: { imageToUpload: any, filename: string }, thunkApi: any) => {
    const uploadedImage = await thunkApi.extra.networkRequest.uploadImage(uploadImageInfo.imageToUpload, uploadImageInfo.filename)
    if (uploadedImage.error) {
      return thunkApi.rejectWithValue(uploadedImage)
    }
    return uploadedImage
  }
)

export const importProperties = createAsyncThunk(
  'generalNetwork/importPropertiesCall',
  async (properties: PropertyType[], thunkApi: any) => {
    // TODO: implement batching
    const { loading, currentRequestId } = thunkApi.getState().generalNetwork.importProperties
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const propertyData = await thunkApi.extra.networkRequest.importProperties(properties)
    if (propertyData.error) {
      return rejectWithValue(propertyData)
    }
    return propertyData
  }
)

export const bulkEditProperties = createAsyncThunk(
  'generalNetwork/bulkEditPropertiesCall',
  async (properties: PropertyType[], thunkApi: any) => {
    // TODO: implement batching
    const { loading, currentRequestId } = thunkApi.getState().generalNetwork.bulkEditProperties
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const propertyData = await thunkApi.extra.networkRequest.bulkEditProperties(properties)
    if (propertyData.error) {
      return rejectWithValue(propertyData)
    }
    return propertyData
  }
)

export const importPropertyPrices = createAsyncThunk(
  'generalNetwork/importPropertyPricesCall',
  async (propertyPrices: PropertyPriceEntryType[], thunkApi: any) => {
    // TODO: implement batching
    const { loading, currentRequestId } = thunkApi.getState().generalNetwork.importPropertyPrices
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending') { return }
    // if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const propertyPriceData = await thunkApi.extra.networkRequest.importPropertyPrices(propertyPrices)
    if (propertyPriceData.error) {
      return rejectWithValue(propertyPriceData)
    }
    return propertyPriceData
  }
)

export const bulkEditPropertyPrices = createAsyncThunk(
  'generalNetwork/bulkEditPropertyPricesCall',
  async (propertyPrices: PropertyPriceEntryType[], thunkApi: any) => {
    // TODO: implement batching
    const { loading, currentRequestId } = thunkApi.getState().generalNetwork.bulkEditPropertyPrices
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending') { return }
    // if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const propertyPriceData = await thunkApi.extra.networkRequest.bulkEditPropertyPrices(propertyPrices)
    if (propertyPriceData.error) {
      return rejectWithValue(propertyPriceData)
    }
    return propertyPriceData
  }
)

export const loosePropertyPricesQuery = createAsyncThunk(
  'generalNetwork/getPropertyPricesCall',
  async (queryInfo: { query: any, page: number, limit: number, all?: boolean }, thunkApi: any) => {
    const propertyPricesList = await thunkApi.extra.networkRequest.getPropertyPrices(queryInfo.query, { page: queryInfo.page, limit: queryInfo.limit, all: queryInfo.all })
    if (propertyPricesList.error) {
      return thunkApi.rejectWithValue(propertyPricesList)
    }
    return propertyPricesList
  }
)

export const checkBookingDates = createAsyncThunk(
  'generalNetwork/checkBookingDatesCall',
  async (checkBookingPayload: { propertyId: string | string[], checkIn: Date, checkOut: Date }, thunkApi: any) => {
    const bookings = await thunkApi.extra.networkRequest.getBookings({
      ...checkBookingPayload,
      bookingStatus: ['new', 'confirmed', 'pending']
    }, {})
    if (bookings.error) {
      return thunkApi.rejectWithValue(bookings)
    }
    return bookings
  }
)

export const checkBlockDates = createAsyncThunk(
  'generalNetwork/checkBlockDatesCall',
  async (checkBlockPayload: { propertyId: string | string[], checkIn: Date, checkOut: Date }, thunkApi: any) => {
    const blocks = await thunkApi.extra.networkRequest.getDateBlocks({
      ...checkBlockPayload,
      status: ['new', 'confirmed', 'pending']
    }, {})
    if (blocks.error) {
      return thunkApi.rejectWithValue(blocks)
    }
    return blocks
  }
)

export const looseBookingQuery = createAsyncThunk(
  'generalNetwork/looseBookingQuery',
  async (queryInfo: any, thunkApi: any) => {
    const bookings = await thunkApi.extra.networkRequest.getBookings(queryInfo.query, { page: queryInfo.page, limit: queryInfo.limit, all: queryInfo.all })
    if (bookings.error) {
      return thunkApi.rejectWithValue(bookings)
    }
    return bookings
  }
)

export const deleteMultipleBookings = createAsyncThunk(
  'generalNetwork/deleteMultipleBookingsCall',
  async (bookingIds: string[], thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().generalNetwork.deleteMultipleBookings
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const errors: any[] = []
    const deletedBookings = []
    for (const bookingId of bookingIds) {
      const deletedBooking = await thunkApi.extra.networkRequest.deleteBooking(bookingId)
      if (deletedBooking.error) {
        errors.push(deletedBooking)
      } else {
        deletedBookings.push(deletedBooking)
      }
    }
    if (errors && ErrorMessage.length > 0) {
      return rejectWithValue(deletedBookings)
    }
    return deletedBookings
  }
)

export const importBookings = createAsyncThunk(
  'generalNetwork/importBookingsCall',
  async (bookings: PropertyBookingEntry[], thunkApi: any) => {
    // TODO: implement batching
    const { loading, currentRequestId } = thunkApi.getState().generalNetwork.importBookings
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const bookingData = await thunkApi.extra.networkRequest.importBookings(bookings)
    if (bookingData.error) {
      return rejectWithValue(bookingData)
    }
    return bookingData
  }
)

export const bulkEditBookings = createAsyncThunk(
  'generalNetwork/bulkEditBookingsCall',
  async (bookings: PropertyBookingEntry[], thunkApi: any) => {
    // TODO: implement batching
    const { loading, currentRequestId } = thunkApi.getState().generalNetwork.bulkEditBookings
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const bookingData = await thunkApi.extra.networkRequest.bulkEditBookings(bookings)
    if (bookingData.error) {
      return rejectWithValue(bookingData)
    }
    return bookingData
  }
)

export const fontaineRates = createAsyncThunk(
  'generalNetwork/fontaineRatesCall',
  async (queryInfo: { query: any, page: number, limit: number }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().generalNetwork.fontaineRates
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    // convert the dates into the correct format
    const checkInString = timeService.getFormattedTime(queryInfo.query.checkIn, 'yyyy-LL-dd')
    const checkOutString = timeService.getFormattedTime(queryInfo.query.checkOut, 'yyyy-LL-dd')
    // get the rates
    const rates = await thunkApi.extra.networkRequest.getFontaineRates(checkInString, checkOutString)
    if (rates.error) {
      return rejectWithValue({
        ...rates,
        currentQuery: queryInfo
      })
    }
    return {
      data: rates,
      paginationInfo: null,
      currentQuery: queryInfo
    }
  }
)

export const replyBookingMessage = createAsyncThunk(
  'generalNetwork/replyBookingMessageCall',
  async (messageInfo: { bookingMessage: any, replyEmail: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().generalNetwork.bookingMessage
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const newMessage = await thunkApi.extra.networkRequest.replyToBookingMessage(messageInfo.bookingMessage, messageInfo.replyEmail)
    if (newMessage.error) {
      return rejectWithValue(newMessage)
    }
    return newMessage
  }
)

export const sendBookingGuestEmail = createAsyncThunk(
  'generalNetwork/sendBookingGuestEmailCall',
  async (bookingGuestInfo: { bookingGuestId: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().generalNetwork.sendBookingGuestEmail
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const emailData = await thunkApi.extra.networkRequest.sendBookingGuestEmail(bookingGuestInfo.bookingGuestId)
    if (emailData.error) {
      return rejectWithValue(emailData)
    }
    return emailData
  }
)

export const sendBookingConfirmedEmail = createAsyncThunk(
  'generalNetwork/sendBookingConfirmedEmailCall',
  async (bookingId: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().generalNetwork.sendBookingConfirmedEmail
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const emailData = await thunkApi.extra.networkRequest.sendBookingConfirmedEmail(bookingId)
    if (emailData.error) {
      return rejectWithValue(emailData)
    }
    return emailData
  }
)


export const generalNetworkSlice = createSlice({
  name: 'generalNetwork',
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    ([
      [uploadImage, 'uploadImage'],
      [checkBookingDates, 'checkBookingDates'],
      // [looseBookingQuery, 'looseBookingQuery'],
      [importBookings, 'importBookings'],
      [bulkEditBookings, 'bulkEditBookings'],

      [deleteMultipleBookings, 'deleteMultipleBookings'],
      [replyBookingMessage, 'bookingMessage'],

      [bulkEditProperties, 'bulkEditProperties'],
      [importProperties, 'importProperties'],

      [importPropertyPrices, 'importPropertyPrices'],
      [bulkEditPropertyPrices, 'bulkEditPropertyPrices'],

      [sendBookingGuestEmail, 'sendBookingGuestEmail'],
      [sendBookingConfirmedEmail, 'sendBookingConfirmedEmail'],

      [checkBlockDates, 'checkBlockDates'],

    ]).forEach(([action, actionName]: any) => {
      generalNetworkBuilder(builder, action, actionName)
    })

    // looseBookingQuery 
    builder.addCase(looseBookingQuery.pending, standardNetworkPendingCall('Fetching Loose Bookings', {}, 'looseBookingQuery'))
    builder.addCase(looseBookingQuery.rejected, (state, action: any) => {
      return standardNetworkErrorCall('Error getting rates', { currentQuery: action.payload.currentQuery }, 'looseBookingQuery')(state, action)
    })
    builder.addCase(looseBookingQuery.fulfilled, (state, action: any) => {
      return {
        ...state,
        looseBookingQuery: {
          data: action.payload.docs,
          paginationInfo: action.payload.paginationInfo,
          currentQuery: action.payload.currentQuery,
          loading: 'idle',
          message: '',
          error: null,
        }
      }
    })

    // loosePropertyPricesQuery
    builder.addCase(loosePropertyPricesQuery.pending, standardNetworkPendingCall('Fetching Loose Property Prices', {}, 'loosePropertyPricesQuery'))
    builder.addCase(loosePropertyPricesQuery.rejected, (state, action: any) => {
      return standardNetworkErrorCall('Error getting rates', { currentQuery: action.payload.currentQuery }, 'loosePropertyPricesQuery')(state, action)
    })
    builder.addCase(loosePropertyPricesQuery.fulfilled, (state, action: any) => {
      return {
        ...state,
        loosePropertyPricesQuery: {
          data: action.payload.docs,
          paginationInfo: action.payload.paginationInfo,
          currentQuery: action.payload.currentQuery,
          loading: 'idle',
          message: '',
          error: null,
        }
      }
    })

    // FONTAINE RATES FUNCTION
    builder.addCase(fontaineRates.pending, standardNetworkPendingCall('Fetching Fontaine Rates', {}, 'fontaineRates'))
    builder.addCase(fontaineRates.rejected, (state, action: any) => {
      return standardNetworkErrorCall('Error getting rates', { currentQuery: action.payload.currentQuery }, 'fontaineRates')(state, action)
    })
    builder.addCase(fontaineRates.fulfilled, (state, action: any) => {
      return {
        ...state,
        fontaineRates: {
          data: action.payload.data,
          paginationInfo: action.payload.paginationInfo,
          currentQuery: action.payload.currentQuery,
          loading: 'idle',
          message: '',
          error: null,
        }
      }
    })

  }
})


// Action creators are generated for each case reducer function
export const generalNetworkSelector = createSelector((state: RootState) => state, (state) => state.generalNetwork)

export default generalNetworkSlice.reducer
