import { Col, Container, Row } from "react-bootstrap";
import { fontaineRates, generalNetworkSelector } from "../../redux/generalNetwork/generalNetworkSlice";
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/store";
import { useEffect, useMemo, useState } from "react";
import QuickForm from "../../Components/Forms/QuickForm/QuickForm";
import timeService from "../../services/time/timeService";

// TODO: deprecate this page

export default function FontaineRates() {

    const {
        loading,
        error,
        // currentRequestId,
        // paginationInfo,
        currentQuery,
        message,
        data,
    } = useSelector(generalNetworkSelector).fontaineRates;
    const dispatch = useAppDispatch()

    const [selectedPropertyType, setSelectedPropertyType] = useState("");
    const propertyType = (selectedPropertyType).replace(/-/g, ' ').replace('Tresor', 'Trésor')

    const [activeQuery, setActiveQuery] = useState<any>({
        page: 1,
        limit: 10,
        query: {
            checkIn: null,
            checkOut: null,
        }
    });

    useEffect(() => {
        if (
            loading !== 'pending' &&
            JSON.stringify(activeQuery) !== JSON.stringify(currentQuery) &&
            activeQuery.query.checkIn !== null &&
            activeQuery.query.checkOut !== null
        ) {
            dispatch(fontaineRates(activeQuery))
        }
        return () => { }
    }, [activeQuery, dispatch, loading, currentQuery])

    const handleFontaineSearch = (query: any) => {
        setActiveQuery({
            ...activeQuery,
            query: {
                checkIn: (new Date(query.checkIn)).getTime(),
                checkOut: (new Date(query.checkOut)).getTime(),
            }
        })
    }

    const dataExists = useMemo(() => {
        if (data?.data?.data['@attributes']) {
            return true
        }
        return false
    }, [data])

    const filteredRateData = useMemo(() => {
        if (!dataExists || !data.data.data.availability.record) {
            return null
        }

        const filteredData = data.data.data.availability.record.filter((x: FontaineRoomTypeRecord) => x.name === propertyType)

        return filteredData && filteredData.length > 0 ? filteredData[0] : null
    }, [data, dataExists, propertyType])

    const filteredRateDataRoomRecord = useMemo(() => {
        if (!filteredRateData || filteredRateData === null) {
            return null
        }
        return filteredRateData.rooms.record instanceof Array ? filteredRateData.rooms.record[0] : filteredRateData.rooms.record
    }, [filteredRateData])

    const nights = timeService.calculateNights(activeQuery.query.checkIn, activeQuery.query.checkOut)

    const totalStayPrice = useMemo(() => {
        if (!filteredRateDataRoomRecord) {
            return 0
        }
        let stayPrice = 0
        let totalFees = 0

        filteredRateDataRoomRecord.rates.record[0].fees.record.forEach((feeData: FontaineFeeData) => {
            totalFees += +feeData['@attributes'].amount
        })
        if (nights > 1) {
            filteredRateDataRoomRecord.rates.record[0].dailyrates.record.forEach((nightPrice: FontainNightPriceData) => {
                stayPrice += +nightPrice['@attributes'].price
            })
        } else {
            stayPrice += +filteredRateDataRoomRecord.rates.record[0].dailyrates.record['@attributes'].price
        }

        return stayPrice + totalFees
    }, [filteredRateDataRoomRecord, nights])

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Fontaine Rates</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {message && (<>{message}<br /></>)}

                    {/* query: {JSON.stringify(activeQuery)}<br /> */}

                    <QuickForm
                        initialData={{
                            dateRange: null,
                            checkIn: null,
                            checkOut: null,
                            propertyType: propertyType
                        }}
                        fields={[
                            {
                                fieldName: 'dateRange',
                                fieldLabel: 'Date Range',
                                fieldType: 'dateRange',
                                placeholder: 'mm/dd/yyyy - mm/dd/yyyy',
                                extra: {
                                    startDate: 'checkIn',
                                    endDate: 'checkOut',
                                }
                            },
                            {
                                fieldName: 'checkIn',
                                fieldLabel: 'Check In',
                                fieldType: 'date',
                                required: true,
                                hidden: true,
                            },
                            {
                                fieldName: 'checkOut',
                                fieldLabel: 'Check Out',
                                fieldType: 'date',
                                required: true,
                                hidden: true,
                            },
                            {
                                fieldName: 'propertyType',
                                fieldLabel: 'Property Type',
                                fieldType: 'select',
                                required: true,
                                placeholder: 'Select Property Type',
                                onChange: (e: any) => { 
                                    setSelectedPropertyType(e.target.value)
                                },
                                options: [
                                    {
                                        value: 'Sorrento-Ocean-View-Junior-Suite',
                                        text: 'Sorrento-Ocean-View-Junior-Suite',
                                    },
                                    {
                                        value: 'Tresor-Bay-View-Junior-Suite',
                                        text: 'Tresor-Bay-View-Junior-Suite',
                                    },
                                    {
                                        value: 'Tresor-Bay-View-One-Bedroom-Suite',
                                        text: 'Tresor-Bay-View-One-Bedroom-Suite',
                                    },
                                    {
                                        value: 'Tresor-Ocean-View-Junior-Suite',
                                        text: 'Tresor-Ocean-View-Junior-Suite',
                                    },
                                    {
                                        value: 'Tresor-Ocean-View-One-Bedroom-Suite',
                                        text: 'Tresor-Ocean-View-One-Bedroom-Suite',
                                    },
                                ]
                            }
                        ]}
                        onSubmit={handleFontaineSearch} />

                    <h3>{propertyType}</h3>

                    {loading === 'pending' && (<p>Loading...</p>)}

                    {dataExists !== false && filteredRateData && (
                        <div>
                            <hr />
                            {/* {JSON.stringify(filteredRateData)} */}
                            Lowest Rate: ${(+filteredRateData.lowestRate).toFixed(2)} <br />
                            Fees per day: <br />
                            <ul>
                                {filteredRateDataRoomRecord.rates.record[0].fees.record.map((feeData: any, idx: number) => (
                                    <li key={idx}>
                                        {feeData.name}: ${(feeData['@attributes'].amount / nights).toFixed(2)}
                                    </li>
                                ))}
                            </ul>

                            Total Price: ${totalStayPrice} <br />
                            Price Per Night with Fees: ${(totalStayPrice / nights).toFixed(2)} <br /> <br />
                            <b>
                                List between:
                                ${(filteredRateData.lowestRate * .6).toFixed(2)} - 
                                ${(filteredRateData.lowestRate * .75).toFixed(2)} <br />
                            </b>
                            <span className="small">This range is 25-40% off the lowest listed price without cleaning(~$50)</span>
                        </div>
                    )}

                    {dataExists && !filteredRateData && (
                        <div>
                            <hr />
                            <p>No data found for {propertyType}</p>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}

interface FontaineFeeData {
    '@attributes': { amount: number },
    name: string
}

interface FontainNightPriceData {
    '@attributes': { date: Date, price: number },
}
interface FontaineRoomTypeRecord {
    name: string,
    lowestRate: string,
}
