import { createAsyncThunk, createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardPaginatedBuilder, generateStandardNetworkPaginatedState, StandardPaginatedNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface DateBlocksState extends StandardPaginatedNetworkState {
  dateBlocksList: any[] | null
}

const generateEmptyDateBlocksState = (): DateBlocksState => {
  return {
    ...generateStandardNetworkPaginatedState(),
    dateBlocksList: null,
  }
}

export const getDateBlocksAction = createAsyncThunk(
  'dateBlocks/getDateBlocksActionCall',
  async (queryInfo: {query: any, page: number, limit: number, all?: boolean}, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().dateBlocks
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const dateBlocksList = await thunkApi.extra.networkRequest.getDateBlocks(queryInfo.query, {page: queryInfo.page, limit: queryInfo.limit, all: queryInfo.all})
    if (dateBlocksList.error) {
      return rejectWithValue({
        ...dateBlocksList,
        currentQuery: queryInfo
      })
    }
    const {docs, ...paginationInfo} = dateBlocksList
    return {
      docs,
      paginationInfo,
      currentQuery: queryInfo
    }
  }
)

const initialState: DateBlocksState = generateEmptyDateBlocksState()

export const dateBlocksSlice = createSlice({
  name: 'dateBlocks',
  initialState,
  reducers: {
    clearDateBlocks: (state, action: PayloadAction) => {
      return {
        ...generateEmptyDateBlocksState()
      }
    }
  },
  extraReducers: (builder) => {
    generalStandardPaginatedBuilder(builder, getDateBlocksAction, 'dateBlocksList')
  }
})

export const { clearDateBlocks } = dateBlocksSlice.actions

export const dateBlocksSelector = createSelector((state: RootState) => state, (state) => state.dateBlocks)

export default dateBlocksSlice.reducer
