import { createAsyncThunk, createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardPaginatedBuilder, generateStandardNetworkPaginatedState, StandardPaginatedNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface BukinsState extends StandardPaginatedNetworkState {
  bukinsList: any[] | null
}

const generateEmptyBukinsState = (): BukinsState => {
  return {
    ...generateStandardNetworkPaginatedState(),
    bukinsList: null,
  }
}

export const getBukinsAction = createAsyncThunk(
  'bukins/getBukinsActionCall',
  async (queryInfo: {query: any, page: number, limit: number, all?: boolean}, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bukins
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const bukinsList = await thunkApi.extra.networkRequest.getBukins(queryInfo.query, {page: queryInfo.page, limit: queryInfo.limit, all: queryInfo.all})
    if (bukinsList.error) {
      return rejectWithValue({
        ...bukinsList,
        currentQuery: queryInfo
      })
    }
    const {docs, ...paginationInfo} = bukinsList
    return {
      docs,
      paginationInfo,
      currentQuery: queryInfo
    }
  }
)

const initialState: BukinsState = generateEmptyBukinsState()

export const bukinsSlice = createSlice({
  name: 'bukins',
  initialState,
  reducers: {
    clearBukins: (state, action: PayloadAction) => {
      return {
        ...generateEmptyBukinsState()
      }
    }
  },
  extraReducers: (builder) => {
    generalStandardPaginatedBuilder(builder, getBukinsAction, 'bukinsList')
  }
})

export const { clearBukins } = bukinsSlice.actions

export const bukinsSelector = createSelector((state: RootState) => state, (state) => state.bukins)

export default bukinsSlice.reducer
