import { useMemo } from "react";
import useUsersQuery from "./UseUsersQuery";

export default function useUserRefs() {
    const {
        userList
    } = useUsersQuery({
        query: {},
        limit: 500,
        page: 1
    })

    const userRef = useMemo(() => {
        const userRefData: any = {}
        if (userList) {
            userList.forEach((user: any) => {
                userRefData[user.email] = user
            })
        }
        return userRefData
    }, [userList])

    return userRef
}