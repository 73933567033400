import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { bookingGuestSelector, getBookingGuestInfo } from "../../../redux/bookingGuest/bookingGuestSlice"
import { useAppDispatch } from "../../../redux/store"
import BookingGuestEntry from "../../../Components/BookingGuests/BookingGuestEntry/BookingGuestEntry"
import { bookingSelector, getBookingInfo } from "../../../redux/booking/bookingSlice"
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook"

export default function BookingGuestPage() {

  const { myProperties } = useMyProperties()
  const { _id, bookingGuestData, message, loading, error } = useSelector(bookingGuestSelector)
  const { bookingGuestId } = useParams()
  const dispatch = useAppDispatch()

  const {
    bookingData,
    loading: bookingDataLoading,
  } = useSelector(bookingSelector)

  const {
    bookingId,
    propertyId,
  } = bookingGuestData || {}

  const propertyData = myProperties[propertyId] || {}

  useEffect(() => {
    if (loading !== 'pending' && bookingGuestId && _id !== bookingGuestId) {
      dispatch(getBookingGuestInfo(bookingGuestId))
    }
    if (bookingDataLoading !== 'pending' && bookingId && bookingId !== bookingData?._id) {
      dispatch(getBookingInfo(bookingId))
    }
    return () => { }
  }, [bookingGuestId, _id, dispatch, loading, bookingDataLoading, bookingId, bookingData?._id])

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single BookingGuest Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}

          {bookingGuestId && bookingGuestId === _id && bookingGuestData && (
            <div className="booking-guestinfo">
              {/* <pre>
                {JSON.stringify(bookingGuestData, null, 2)}            
              </pre> */}
              <BookingGuestEntry
                bookingGuestInfo={bookingGuestData}
                bookingData={bookingData}
                propertyData={propertyData}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
