import { useEffect, useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { bukinSelector,  deleteBukin, getBukinInfo } from "../../../redux/bukin/bukinSlice"
import { clearBukins } from "../../../redux/bukins/bukinsSlice"
import { navigateTo } from "../../../redux/router/routerActions"
import { useAppDispatch } from "../../../redux/store"

export default function DeleteBukinPage() {
    const { _id, bukinData, message, loading, error } = useSelector(bukinSelector)
    const { bukinId } = useParams()
    const dispatch = useAppDispatch()

    const [deleteClicked, setDeleteClicked] = useState(false)

    useEffect(() => {
        if (loading !== 'pending' && bukinId && _id !== bukinId) {
            dispatch(getBukinInfo(bukinId))
        }
        return () => { }
    }, [bukinId, _id, dispatch, loading])

    const bukinDeleteFn = async () => {
        if (_id) {
            const deleteValue = await dispatch(deleteBukin({ _id: _id })).unwrap()
            if (deleteValue) {
                dispatch(clearBukins())
                dispatch(navigateTo(`/bukins/`))
            }
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Delete Bukin Page</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {message && (<>{message}<br /></>)}

                    {bukinId && bukinId === _id && bukinData && (
                        <div className="bukin-info">
                            {JSON.stringify(bukinData)}

                            <Stack gap={3}>
                                <div>Are you sure you want to delete Bukin: {_id}</div>
                                <div>
                                    <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete Bukin</Button>
                                </div>
                                {deleteClicked && (
                                    <div>
                                        Once you delete this bukin it will be gone forever. <br />
                                        <Button variant={'danger'} disabled={loading === 'pending'} onClick={() => {
                                            bukinDeleteFn()
                                        }}> Confirm Delete Bukin </Button>
                                    </div>
                                )}
                            </Stack>

                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
