import * as yup from 'yup'

export const blankProperty = () => {
  return {
    title: '',
    description: '',
    aliases: [],
    owner: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    },
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    },
    featuredImage: '',
    thumbnailImage: '',
    imageGallery: [],
    calendars: [],
    listed: [],
    about: {
      summary: '',
      sections: [],
      amenities: [], // needs mega checkbox mechanism
      guests: '',
      guestInfo: {
        adults: 0,
        children: 0,
        infants: 0,
      },
      bedrooms: 0,
      beds: 0,
      otherBeds: 0,
      bathrooms: 0,
      baths: 0,
      halfBathrooms: 0,
    },
    multiUnit: {
      isMultiUnit: false,
      linkedUnits: [],
    },
    pricing: {
      baseRate: '',
      deposit: '',
      cleaning: '',
      tax: '',
      extraFees: [],
    },
    // TODO: ADD PRICING OBJECT
    virtualTour: '',
    // add custom validator to make sure at least one person is on the list
    authorizedUsers: [], // fill on submit - or server side - validate or send email
    public: true,
    services: {
      cleaning: false,
      bookingGuestInfo: false,
      bookingGuestEmail: '',
    },
    linkedServices: [],
  }
}

export const propertySchema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  aliases: yup.array().of(yup.object().shape({
    alias: yup.string().required(),
    aliasSource: yup.string().required(),
  })),
  owner: yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    phone: yup.string(),
    email: yup.string().email()
  }),
  address: yup.object({
    address1: yup.string().required(),
    address2: yup.string(),
    city: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().required(),
    country: yup.string().required(),
  }),
  featuredImage: yup.string().url(),
  thumbnailImage: yup.string().url(),
  imageGallery: yup.array().of(yup.object().shape({
    url: yup.string().url().required(),
    title: yup.string(),
    description: yup.string(),
    thumbUrl: yup.string().url()
  })),
  calendars: yup.array().of(yup.object().shape({
    source: yup.string().required(),
    url: yup.string().url().required()
  })),
  listed: yup.array().of(yup.object().shape({
    source: yup.string().required(),
    url: yup.string().url().required(),
    internalName: yup.string().required(),
    internalTitle: yup.string().required()
  })),
  about: yup.object({
    summary: yup.string(),
    sections: yup.array().of(yup.object().shape({
      sectionTitle: yup.string().required(),
      content: yup.string().required()
    })),
    amenities: yup.array().of(yup.string()), // needs mega checkbox mechanism
    guests: yup.string().required(),
    guestInfo: yup.object({
      adults: yup.number().nullable(),
      children: yup.number().nullable(),
      infants: yup.number().nullable(),
    }),
    bedrooms: yup.number().required(),
    beds: yup.number().required(),
    otherBeds: yup.number(),
    bathrooms: yup.number().required(),
    baths: yup.number().required(),
    halfBathrooms: yup.number(),
  }),
  multiUnit: yup.object({
    isMultiUnit: yup.bool(),
    linkedUnits: yup.array().of(yup.object().shape({
      propertyId: yup.string().required(),
      relationship: yup.string().required()
    })).when('isMultiUnit', {
      is: true,
      then: yup.array().min(1).required()
    })
  }),
  pricing: yup.object({
    baseRate: yup.number().required(),
    deposit: yup.number().required(),
    cleaning: yup.number().required(),
    tax: yup.number().required(),
    extraFees: yup.array().of(yup.object().shape({
      name: yup.string().required(),
      amount: yup.number().required(),
      frequency: yup.string().required(),
    })),
  }),
  virtualTour: yup.string().url(),
  authorizedUsers: yup.array().of(yup.object().shape({
    email: yup.string().email().required(),
    role: yup.string().required()
  })),
  public: yup.bool(),
  services: yup.object({
    cleaning: yup.bool(),
    bookingGuestInfo: yup.bool(),
  })
})
