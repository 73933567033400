import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BookingMessageEntry from "../../../Components/BookingMessage/BookingMessageEntry";
import QuickForm from "../../../Components/Forms/QuickForm/QuickForm";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import { bookingSelector, getBookingInfo } from "../../../redux/booking/bookingSlice";
import { bookingMessagesSelector, getBookingMessagesAction } from "../../../redux/bookingMessages/bookingMessagesSlice";
import { generalNetworkSelector, replyBookingMessage } from "../../../redux/generalNetwork/generalNetworkSlice";
import { useAppDispatch } from "../../../redux/store";
import timeService from "../../../services/time/timeService";
import { PropertyBookingMessageType } from "../../../Types/bookingMessageType";
import { shouldLoadNetwork } from "../../../Helpers/ValueHandlers/shouldLoad";

export default function ViewBookingMessagesPage() {

    const {
        loading,
        error,
        // currentRequestId,
        // paginationInfo,
        currentQuery,
        message,
        bookingMessagesList,
        lastLoad
    } = useSelector(bookingMessagesSelector)

    const bookingState = useSelector(bookingSelector)
    const { _id, bookingData } = bookingState

    const { bookingMessage } = useSelector(generalNetworkSelector)

    const { bookingId } = useParams()

    const { myProperties } = useMyProperties()

    const activeQuery = {
        query: {
            bookingId: bookingId
        },
        limit: 500,
        page: 1,
    }

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (bookingState.loading !== 'pending' && bookingId && _id !== bookingId) {
            dispatch(getBookingInfo(bookingId))
        }
        if (shouldLoadNetwork(loading, activeQuery, currentQuery, lastLoad)) {
            dispatch(getBookingMessagesAction(activeQuery))
        }
        return () => { }
    })

    const propertyInfo: any = bookingData && bookingData.propertyId && myProperties[bookingData.propertyId] ? myProperties[bookingData.propertyId] : {}

    const replyToBookingMessage = async (replyMessage: string) => {
        if (!replyMessage || !propertyInfo || !bookingData || !bookingMessagesList) return

        const lastMessage = bookingMessagesList[bookingMessagesList.length - 1]
        const {
            place,
            // email,
            subjectLine
        } = lastMessage
        const emailRef: { [s: string]: string } = {}
        bookingMessagesList.forEach((m) => {
            if (m.name !== 'Benichay Brothers' && !emailRef[m.name]) {
                emailRef[m.name] = m.email
            }
        })
        const email = [...(Object.keys(emailRef).map((name) => {
            return `"${emailRef[name]}"`
        }))].join(', ')


        const newBookingMessage = {
            propertyId: propertyInfo._id,
            bookingId: bookingData._id,
            email: "benichaybrothers@gmail.com",
            date: new Date(),
            checkIn: bookingData.checkIn,
            checkOut: bookingData.checkOut,
            place: place,
            source: "airbnb",
            name: "Benichay Brothers",
            subjectLine: `RE: ${subjectLine.replace('RE:', '').trim()}`,
            message: replyMessage,
        }

        await dispatch(replyBookingMessage({
            replyEmail: email,
            bookingMessage: newBookingMessage
        })).unwrap()

        alert('this is still in dev mode, the reply email is inactive on the server.')

        dispatch(getBookingMessagesAction(activeQuery))
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Mesasges for booking: {bookingId}</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {message && (<>{message}<br /></>)}
                    {/* {currentRequestId && (<>{currentRequestId}<br /></>)} */}
                    {bookingData && (
                        <>
                            <b>
                                {propertyInfo && propertyInfo.title ? propertyInfo.title : bookingData.propertyId} <br />
                            </b>
                            <b>Guest Name:</b> {bookingData?.contactInfo?.firstName} {bookingData?.contactInfo?.lastName} <br />
                            <b>Staying:</b> {timeService.getFormattedTime(bookingData.checkIn, 'MM/dd')} - {timeService.getFormattedTime(bookingData.checkOut, 'MM/dd/yyyy')} <br />
                        </>
                    )}
                    <hr />
                    <h3>Messages</h3>
                    {bookingMessagesList && bookingMessagesList.map((message: PropertyBookingMessageType, idx: number) => (
                        <BookingMessageEntry
                            key={idx}
                            bookingMessage={message}
                        />
                    ))}

                    <hr />
                    <QuickForm
                        initialData={{
                            message: ''
                        }}
                        onSubmit={(values: any) => {
                            replyToBookingMessage(values.message)
                        }}
                        fields={[
                            {
                                fieldName: 'message',
                                fieldLabel: 'Message',
                                fieldType: 'text',
                                required: true,
                            }
                        ]}
                        disabled={bookingMessage.loading === 'pending'}
                        submitText="Send Message"
                    />

                </Col>
            </Row>
        </Container>
    )
}