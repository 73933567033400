import jwt_decode from "jwt-decode";


interface TokenType {
  email: string
  role: string
  exp?: number
  iat: number
}

class AuthenticationService {

  logOutFn: () => void;

  constructor() {
    this.logOutFn = () => {}
  }

  setLogOutFunction(fn: () => void) {
    this.logOutFn = fn
  }

  setToken(token: string) {
    localStorage.setItem('token', token)
    localStorage.setItem('selectedProperties', '')
  }

  clearToken() {
    localStorage.removeItem('token')
    localStorage.removeItem('selectedProperties')
  }


  validateToken(token: string) {
  // async validateToken(token: string) {
    try {
      const decodedToken: TokenType = jwt_decode(token)
      // check contents
      if(!decodedToken || !decodedToken.email || !decodedToken.role || !decodedToken.iat) {
        throw new Error('invalid token')
      }
      // check expiration
      if(decodedToken.exp) {
        const expiry = decodedToken.exp * 1000
        if (expiry <= Date.now()) {
          throw new Error('expired token')
          // attempt to refresh token
        }
      }
      return true
    } catch (err) {
      // clear Token
      this.clearToken()
      // log out user
    }
    return false
  }

  getToken() {
    const token = localStorage.getItem('token')
    if(token && this.validateToken(token)) {
      return token
    }
    this.logOutFn()
    return ''
  }


  // EXPERIMENTAL TOKEN FLOW
  async getTokenFlow() {
    const token = localStorage.getItem('token')
    if(token && await this.validateTokenFlow(token)) {
      return token
    }
    this.logOutFn()
    return ''
  }

  async validateTokenFlow(token: string) {
    try {
      const decodedToken: TokenType = jwt_decode(token)
      // check contents
      if(!decodedToken || !decodedToken.email || !decodedToken.role || !decodedToken.iat) {
        throw new Error('invalid token')
      }
      // check expiration
      if(decodedToken.exp) {
        const expiry = decodedToken.exp * 1000
        if (expiry <= Date.now()) {
          throw new Error('expired token')
          // attempt to refresh token
        }
      }
      return true
    } catch (err) {
      // clear Token
      this.clearToken()
      this.logOutFn()
    }
    return false
  }

}

const authenticationService = new AuthenticationService()

export default authenticationService
