import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import PropertyPriceForm from "../../../Components/Forms/PropertyPriceForm/PropertyPriceForm";
import { addNewPropertyPrice, propertyPriceSelector } from "../../../redux/propertyPrice/propertyPriceSlice";
import { clearPropertyPrices } from "../../../redux/propertyPrices/propertyPricesSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";

export default function AddPropertyPricePage() {
  const {
    // loading,
    error,
    message,
  } = useSelector(propertyPriceSelector)
  const dispatch = useAppDispatch()
  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    const newPropertyPrice = await dispatch(addNewPropertyPrice({propertyPriceInfo: values})).unwrap()
    if (newPropertyPrice._id) {
      dispatch(navigateTo(`/property-price/${newPropertyPrice._id}`))
    }
    dispatch(clearPropertyPrices())
    return newPropertyPrice
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add PropertyPrice</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          <PropertyPriceForm
            initialValues={{
                price: 0,
                date: undefined,
                propertyId: '',
            }}
            disabled={false}
            onSubmit={handleSubmit}
          />

        </Col>
      </Row>
    </Container>
  )
}
