import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardCrudBuilder, generateStandardNetworkState, StandardNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface BookingGuestState extends StandardNetworkState {
  _id: string | null
  bookingGuestData: any
}

const generateEmptyBookingGuestState = (): BookingGuestState => {
  return {
    _id: '',
    bookingGuestData: {},
    ...generateStandardNetworkState()
  }
}

const initialState = generateEmptyBookingGuestState()

export const getBookingGuestInfo = createAsyncThunk(
  'bookingGuest/getBookingGuestInfoCall',
  async (bookingGuestId: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bookingGuest
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const bookingGuestData = await thunkApi.extra.networkRequest.getBookingGuestById(bookingGuestId)
    if (bookingGuestData.error) {
      return rejectWithValue({
        ...bookingGuestData,
        _id: bookingGuestId
      })
    }
    return {
      _id: bookingGuestId,
      bookingGuestData
    }
  }
)

export const addNewBookingGuest = createAsyncThunk(
  'bookingGuest/addNewBookingGuestCall',
  async (bookingGuestPayload: { bookingGuestInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bookingGuest
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { bookingGuestInfo } = bookingGuestPayload
    const bookingGuestData = await thunkApi.extra.networkRequest.addNewBookingGuest(bookingGuestInfo)
    if (bookingGuestData.error) {
      return rejectWithValue({
        ...bookingGuestData,
        _id: 999
      })
    }
    return {
      _id: bookingGuestData._id,
      bookingGuestData: {
        ...bookingGuestData
      }
    }
  }
)

export const editBookingGuest = createAsyncThunk(
  'bookingGuest/editBookingGuestCall',
  async (bookingGuestPayload: { _id: string, bookingGuestInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bookingGuest
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id, bookingGuestInfo } = bookingGuestPayload
    const bookingGuestData = await thunkApi.extra.networkRequest.editBookingGuest(_id, bookingGuestInfo)
    if (bookingGuestData.error) {
      return rejectWithValue({
        ...bookingGuestData,
        _id: 999
      })
    }
    return {
      _id: bookingGuestData._id,
      bookingGuestData: {
        ...bookingGuestData
      }
    }
  }
)

export const deleteBookingGuest = createAsyncThunk(
  'bookingGuest/deleteBookingGuestCall',
  async (bookingGuestPayload: { _id: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bookingGuest
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id } = bookingGuestPayload
    const bookingGuestData = await thunkApi.extra.networkRequest.deleteBookingGuest(_id)
    if (!bookingGuestData) {
      return rejectWithValue({
        error: 'Deletion failed',
        _id: 999
      })
    }
    if (bookingGuestData.error) {
      return rejectWithValue({
        ...bookingGuestData,
        _id: 999
      })
    }
    return {
      _id: bookingGuestData._id,
      bookingGuestData: {
        title: 'BookingGuest Deleted'
      }
    }
  }
)

export const bookingGuestSlice = createSlice({
  name: 'bookingGuest',
  initialState,
  reducers: {
    clearBookingGuest: (state, action: PayloadAction) => {
      return {
        ...generateEmptyBookingGuestState()
      }
    }
  },
  extraReducers: (builder) => {
    ([
      [getBookingGuestInfo, 'getBookingGuestInfo'],
      [addNewBookingGuest, 'addNewBookingGuest'],
      [editBookingGuest, 'editBookingGuest'],
      [deleteBookingGuest, 'deleteBookingGuest']
    ]).forEach(([action, actionName]: any) => {
      generalStandardCrudBuilder(builder, action, actionName)
    })
  }
})

export const { clearBookingGuest } = bookingGuestSlice.actions

export const bookingGuestSelector = createSelector((state: RootState) => state, (state) => state.bookingGuest)

export default bookingGuestSlice.reducer
