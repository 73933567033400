import { Button, Col, Container, ProgressBar, Row, Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/store";
import { getAllPropertyIcalData, icalDataSelector } from "../../redux/icalData/icalDataSlice";
import useMyProperties from "../../Hooks/UseMyProperties/UseMyPropertiesHook";
import { useEffect, useState } from "react";
import IcalEntry from "../../Components/Icals/IcalEntry/IcalEntry";
import timeService from "../../services/time/timeService";
import useBookingQuery from "../../Hooks/UseBookingQuery/UseBookingQueryHook";
import { UseAllIcalsDecorated } from "../../Hooks/UseIcals/UseIcalsDecorated";
import { convertIcalToBookings } from "../../Helpers/Icals/IcalHelpers";
import { importBookings } from "../../redux/generalNetwork/generalNetworkSlice";
import { navigateTo } from "../../redux/router/routerActions";
import { clearBookings } from "../../redux/bookings/bookingsSlice";
import IcalFilterForm from "../../Components/Forms/IcalFilterForm/IcalFilterForm";
import UseIcalsFilter from "../../Hooks/UseIcals/UseIcalsFilter";
import { IcalEntryType } from "../../Types/icalEntryType";

export default function IcalsPage() {
    const dispatch: any = useAppDispatch()
    const { icalData, loading, error, message, progress } = useSelector(icalDataSelector)
    const { myProperties } = useMyProperties()

    const startOfYesterday = timeService.getCurrentTime().minus({ days: 1 }).startOf('day').toMillis()
    const activeQuery = {
        query: {
            checkIn: startOfYesterday,
            bookingStatus: ['confirmed', 'pending', 'new']
        },
        limit: 500,
        page: 1
    }
    const { bookingsList } = useBookingQuery(activeQuery)

    const [icalFilter, setIcalFilter]: any = useState({
        checkIn: new Date(startOfYesterday),
        checkOut: null,
        source: '',
        hideExisting: true,
        hideBlocked: true,
    })

    useEffect(() => {
        if (
            myProperties && Object.keys(myProperties).length > 0 &&
            // Object.keys(icalData).length !== Object.keys(myProperties).length &&
            progress.total === 0 &&
            loading !== 'pending'
        ) {
            dispatch(getAllPropertyIcalData())
        }
        return () => { }
    }, [icalData, myProperties, loading, error, message, dispatch, progress.total])

    const allIcalData = UseAllIcalsDecorated({
        icalData,
        bookingsList,
        myProperties,
    })

    const filteredIcalData = UseIcalsFilter(allIcalData, icalFilter)

    const convertIcalBooking = async (iCalEntry: any) => {
        const bookingsToAdd = convertIcalToBookings(iCalEntry, myProperties)
        const newBookings = await dispatch(importBookings(bookingsToAdd)).unwrap()
        if (newBookings && newBookings.acknowledged) {
            dispatch(navigateTo(`/bookings?query={"firstName":"${bookingsToAdd[0].contactInfo?.firstName}","lastName":"${bookingsToAdd[0].contactInfo?.lastName}"}`))
        }
        dispatch(clearBookings())
    }

    const updateIcalFilter = (values: any) => {
        setIcalFilter({
            ...values,
        })
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Icals Page</h1>
                    <hr />

                    <h3>All Icals</h3>

                    {loading === 'pending' ? (
                        <>
                            {message} <br />
                            <ProgressBar now={progress.total > 0 ? (progress.count / progress.total) * 100 : 0} label={`${progress.count} of ${progress.total}`} />
                        </>
                    ) : (
                        <Button
                            size={'sm'}
                            variant={'warning'}
                            onClick={() => {
                                dispatch(getAllPropertyIcalData())
                            }}>
                            Hard Refresh
                        </Button>
                    )}

                    {bookingsList && bookingsList.length > 0 && (
                        <div>{bookingsList.length} bookings</div>
                    )}

                    <h3>Ical Bookings</h3>
                    <IcalFilterForm
                        checkIn={icalFilter.checkIn}
                        checkOut={icalFilter.checkOut ? icalFilter.checkOut : null}
                        hideExisting={icalFilter.hideExisting}
                        hideBlocked={icalFilter.hideBlocked}
                        source={icalFilter.source}
                        onSubmit={updateIcalFilter}
                    />

                    {allIcalData && allIcalData.length > 0 && filteredIcalData && (
                        <>
                            <Stack gap={3}>
                                {filteredIcalData.map((icalInfo: IcalEntryType, idx: number) => (
                                    <IcalEntry
                                        key={`${idx}-${icalInfo.unit}-${icalInfo.source}`}
                                        icalData={icalInfo}
                                        convertIcalBooking={convertIcalBooking}
                                    />
                                ))}
                            </Stack>
                        </>
                    )}

                </Col>
            </Row>
        </Container>
    )
}