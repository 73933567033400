import { useEffect, useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { purchaseSelector,  deletePurchase, getPurchaseInfo } from "../../../redux/purchase/purchaseSlice"
import { clearPurchases } from "../../../redux/purchases/purchasesSlice"
import { navigateTo } from "../../../redux/router/routerActions"
import { useAppDispatch } from "../../../redux/store"

export default function DeletePurchasePage() {
    const { _id, purchaseData, message, loading, error } = useSelector(purchaseSelector)
    const { purchaseId } = useParams()
    const dispatch = useAppDispatch()

    const [deleteClicked, setDeleteClicked] = useState(false)

    useEffect(() => {
        if (loading !== 'pending' && purchaseId && _id !== purchaseId) {
            dispatch(getPurchaseInfo(purchaseId))
        }
        return () => { }
    }, [purchaseId, _id, dispatch, loading])

    const purchaseDeleteFn = async () => {
        if (_id) {
            const deleteValue = await dispatch(deletePurchase({ _id: _id })).unwrap()
            if (deleteValue) {
                dispatch(clearPurchases())
                dispatch(navigateTo(`/purchases/`))
            }
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Delete Purchase Page</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {message && (<>{message}<br /></>)}

                    {purchaseId && purchaseId === _id && purchaseData && (
                        <div className="purchase-info">
                            {JSON.stringify(purchaseData)}

                            <Stack gap={3}>
                                <div>Are you sure you want to delete Purchase: {_id}</div>
                                <div>
                                    <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete Purchase</Button>
                                </div>
                                {deleteClicked && (
                                    <div>
                                        Once you delete this purchase it will be gone forever. <br />
                                        <Button variant={'danger'} disabled={loading === 'pending'} onClick={() => {
                                            purchaseDeleteFn()
                                        }}> Confirm Delete Purchase </Button>
                                    </div>
                                )}
                            </Stack>

                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
