import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardCrudBuilder, generateStandardNetworkState, StandardNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface BukibleState extends StandardNetworkState {
  _id: string | null
  bukibleData: any
}

const generateEmptyBukibleState = (): BukibleState => {
  return {
    _id: '',
    bukibleData: {},
    ...generateStandardNetworkState()
  }
}

const initialState = generateEmptyBukibleState()

export const getBukibleInfo = createAsyncThunk(
  'bukible/getBukibleInfoCall',
  async (bukibleId: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bukible
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const bukibleData = await thunkApi.extra.networkRequest.getBukibleById(bukibleId)
    if (bukibleData.error) {
      return rejectWithValue({
        ...bukibleData,
        _id: bukibleId
      })
    }
    return {
      _id: bukibleId,
      bukibleData
    }
  }
)

export const addNewBukible = createAsyncThunk(
  'bukible/addNewBukibleCall',
  async (bukiblePayload: { bukibleInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bukible
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { bukibleInfo } = bukiblePayload
    const bukibleData = await thunkApi.extra.networkRequest.addNewBukible(bukibleInfo)
    if (bukibleData.error) {
      return rejectWithValue({
        ...bukibleData,
        _id: 999
      })
    }
    return {
      _id: bukibleData._id,
      bukibleData: {
        ...bukibleData
      }
    }
  }
)

export const editBukible = createAsyncThunk(
  'bukible/editBukibleCall',
  async (bukiblePayload: { _id: string, bukibleInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bukible
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id, bukibleInfo } = bukiblePayload
    const bukibleData = await thunkApi.extra.networkRequest.editBukible(_id, bukibleInfo)
    if (bukibleData.error) {
      return rejectWithValue({
        ...bukibleData,
        _id: 999
      })
    }
    return {
      _id: bukibleData._id,
      bukibleData: {
        ...bukibleData
      }
    }
  }
)

export const deleteBukible = createAsyncThunk(
  'bukible/deleteBukibleCall',
  async (bukiblePayload: { _id: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bukible
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id } = bukiblePayload
    const bukibleData = await thunkApi.extra.networkRequest.deleteBukible(_id)
    if (!bukibleData) {
      return rejectWithValue({
        error: 'Deletion failed',
        _id: 999
      })
    }
    if (bukibleData.error) {
      return rejectWithValue({
        ...bukibleData,
        _id: 999
      })
    }
    return {
      _id: bukibleData._id,
      bukibleData: {
        title: 'Bukible Deleted'
      }
    }
  }
)

export const bukibleSlice = createSlice({
  name: 'bukible',
  initialState,
  reducers: {
    clearBukible: (state, action: PayloadAction) => {
      return {
        ...generateEmptyBukibleState()
      }
    }
  },
  extraReducers: (builder) => {
    ([
      [getBukibleInfo, 'getBukibleInfo'],
      [addNewBukible, 'addNewBukible'],
      [editBukible, 'editBukible'],
      [deleteBukible, 'deleteBukible']
    ]).forEach(([action, actionName]: any) => {
      generalStandardCrudBuilder(builder, action, actionName)
    })
  }
})

export const { clearBukible } = bukibleSlice.actions

export const bukibleSelector = createSelector((state: RootState) => state, (state) => state.bukible)

export default bukibleSlice.reducer
