import { Col, Container, Row } from "react-bootstrap"
import Pagination from "../../Components/Pagination/Pagination"
import useInquiryQuery from "../../Hooks/UseInquiryQuery/useInquiryQueryHook"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import { Link } from "react-router-dom"
import QuickForm from "../../Components/Forms/QuickForm/QuickForm"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers";
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook";

export default function InquiriesPage() {

    const dispatch = useAppDispatch()

    const { activeQuery } = useActiveQueryHook({
            query: {},
            limit: 10,
    })

    const {
        loading,
        error,
        currentRequestId,
        paginationInfo,
        currentQuery,
        message,
        inquiriesList,
    } = useInquiryQuery(activeQuery)

    const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
        const destination = `/inquiries?${convertActiveQueryToUrl(pageParams)}`
        dispatch(navigateTo(destination))
    }


    return (
        <Container>
            <Row>
                <Col>
                    <h1>Inquiries</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {message && (<>{message}<br /></>)}

                    <QuickForm
                        key={currentRequestId}
                        initialData={{
                            name: activeQuery.query.name,
                            email: activeQuery.query.email,
                            message: activeQuery.query.message,
                        }}
                        fields={[
                            {
                                fieldName: 'name',
                                fieldLabel: 'Name',
                                fieldType: 'text',
                                placeholder: 'Name',
                            },
                            {
                                fieldName: 'email',
                                fieldLabel: 'Email',
                                fieldType: 'text',
                                placeholder: 'Email',
                            },
                            {
                                fieldName: 'message',
                                fieldLabel: 'Message',
                                fieldType: 'text',
                                placeholder: 'Message',
                            },
                        ]}
                        disabled={loading === 'pending'}
                        onSubmit={(values: any) => {
                            loadPage({
                                query: values,
                                page: 1,
                                limit: currentQuery.limit
                            })
                        }}
                    />

                    <div className="inquiry-list-container">
                        {inquiriesList && inquiriesList.length > 0 && inquiriesList.map((inquiryEntry, idx) => (
                            <div key={idx}>
                                {JSON.stringify(inquiryEntry, null, 2)} <br />
                                <Link className="btn btn-primary btn-sm" to={`/inquiry/${inquiryEntry._id}`}>View</Link>
                                <Link className="btn btn-secondary btn-sm" to={`/edit-inquiry/${inquiryEntry._id}`}>Edit</Link>
                                <Link className="btn btn-danger btn-sm" to={`/delete-inquiry/${inquiryEntry._id}`}>Delete</Link>
                            </div>
                        ))
                        }
                    </div>

                    {paginationInfo && (
                        <Pagination
                            page={paginationInfo.page}
                            limit={paginationInfo.limit}
                            nextPage={paginationInfo.nextPage}
                            prevPage={paginationInfo.prevPage}
                            totalPages={paginationInfo.totalPages}
                            loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                                loadPage({
                                    query: currentQuery.query,
                                    limit: currentQuery.limit,
                                    ...pageLoadInfo
                                })
                            }}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    )
}
