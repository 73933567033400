export const shouldLoadNetwork = (
    loading: string | undefined,
    currentQuery: any, 
    activeQuery: any,
    lastLoad: number | undefined,
    options: any = {}
): boolean => {
    const refreshTime = options.refreshTime || 1000 * 60 * 5
    return loading !== 'pending' && (
        JSON.stringify(activeQuery) !== JSON.stringify(currentQuery) ||
        !lastLoad || lastLoad < Date.now() - refreshTime
    )
}