import { createAsyncThunk, createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardPaginatedBuilder, generateStandardNetworkPaginatedState, StandardPaginatedNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface PropertyPricesState extends StandardPaginatedNetworkState {
  propertyPricesList: any[] | null
}

const generateEmptyPropertyPricesState = (): PropertyPricesState => {
  return {
    ...generateStandardNetworkPaginatedState(),
    propertyPricesList: null,
  }
}

export const getPropertyPricesAction = createAsyncThunk(
  'propertyPrices/getPropertyPricesActionCall',
  async (queryInfo: {query: any, page: number, limit: number, all?: boolean}, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().propertyPrices
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const propertyPricesList = await thunkApi.extra.networkRequest.getPropertyPrices(queryInfo.query, {page: queryInfo.page, limit: queryInfo.limit, all: queryInfo.all})
    if (propertyPricesList.error) {
      return rejectWithValue({
        ...propertyPricesList,
        currentQuery: queryInfo
      })
    }
    const {docs, ...paginationInfo} = propertyPricesList
    return {
      docs,
      paginationInfo,
      currentQuery: queryInfo
    }
  }
)

const initialState: PropertyPricesState = generateEmptyPropertyPricesState()

export const propertyPricesSlice = createSlice({
  name: 'propertyPrices',
  initialState,
  reducers: {
    clearPropertyPrices: (state, action: PayloadAction) => {
      return {
        ...generateEmptyPropertyPricesState()
      }
    }
  },
  extraReducers: (builder) => {
    generalStandardPaginatedBuilder(builder, getPropertyPricesAction, 'propertyPricesList')
  }
})

export const { clearPropertyPrices } = propertyPricesSlice.actions

export const propertyPricesSelector = createSelector((state: RootState) => state, (state) => state.propertyPrices)

export default propertyPricesSlice.reducer
