import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardCrudBuilder, generateStandardNetworkState, StandardNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface BukinState extends StandardNetworkState {
  _id: string | null
  bukinData: any
}

const generateEmptyBukinState = (): BukinState => {
  return {
    _id: '',
    bukinData: {},
    ...generateStandardNetworkState()
  }
}

const initialState = generateEmptyBukinState()

export const getBukinInfo = createAsyncThunk(
  'bukin/getBukinInfoCall',
  async (bukinId: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bukin
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const bukinData = await thunkApi.extra.networkRequest.getBukinById(bukinId)
    if (bukinData.error) {
      return rejectWithValue({
        ...bukinData,
        _id: bukinId
      })
    }
    return {
      _id: bukinId,
      bukinData
    }
  }
)

export const addNewBukin = createAsyncThunk(
  'bukin/addNewBukinCall',
  async (bukinPayload: { bukinInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bukin
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { bukinInfo } = bukinPayload
    const bukinData = await thunkApi.extra.networkRequest.addNewBukin(bukinInfo)
    if (bukinData.error) {
      return rejectWithValue({
        ...bukinData,
        _id: 999
      })
    }
    return {
      _id: bukinData._id,
      bukinData: {
        ...bukinData
      }
    }
  }
)

export const editBukin = createAsyncThunk(
  'bukin/editBukinCall',
  async (bukinPayload: { _id: string, bukinInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bukin
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id, bukinInfo } = bukinPayload
    const bukinData = await thunkApi.extra.networkRequest.editBukin(_id, bukinInfo)
    if (bukinData.error) {
      return rejectWithValue({
        ...bukinData,
        _id: 999
      })
    }
    return {
      _id: bukinData._id,
      bukinData: {
        ...bukinData
      }
    }
  }
)

export const deleteBukin = createAsyncThunk(
  'bukin/deleteBukinCall',
  async (bukinPayload: { _id: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bukin
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id } = bukinPayload
    const bukinData = await thunkApi.extra.networkRequest.deleteBukin(_id)
    if (!bukinData) {
      return rejectWithValue({
        error: 'Deletion failed',
        _id: 999
      })
    }
    if (bukinData.error) {
      return rejectWithValue({
        ...bukinData,
        _id: 999
      })
    }
    return {
      _id: bukinData._id,
      bukinData: {
        title: 'Bukin Deleted'
      }
    }
  }
)

export const bukinSlice = createSlice({
  name: 'bukin',
  initialState,
  reducers: {
    clearBukin: (state, action: PayloadAction) => {
      return {
        ...generateEmptyBukinState()
      }
    }
  },
  extraReducers: (builder) => {
    ([
      [getBukinInfo, 'getBukinInfo'],
      [addNewBukin, 'addNewBukin'],
      [editBukin, 'editBukin'],
      [deleteBukin, 'deleteBukin']
    ]).forEach(([action, actionName]: any) => {
      generalStandardCrudBuilder(builder, action, actionName)
    })
  }
})

export const { clearBukin } = bukinSlice.actions

export const bukinSelector = createSelector((state: RootState) => state, (state) => state.bukin)

export default bukinSlice.reducer
