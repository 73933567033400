import { API_URL } from "../../Config/config"

class NetworkRequestService {

  apiBaseUrl: string
  authService: any
  storeService: any

  constructor() {
    this.apiBaseUrl = API_URL
    // this.apiBaseUrl = 'http://10.0.0.246:3008/api'
    // this.apiBaseUrl = 'https://rocko-lee.herokuapp.com/api'
    // this.apiBaseUrl = 'http://127.0.0.1:3008/api'
    // this.apiBaseUrl = 'http://192.168.0.219:3008/api'
  }

  setAuthService(auth: any) {
    this.authService = auth
  }

  setStoreService(store: any) {
    this.storeService = store
  }

  // HELPER FUNCTIONS
  generateTokenHeader(token: string = '', options: any = {}) {
    const header: any = {
      'Content-Type': options.contentType ? options.contentType : 'application/json',
    }
    if (token) {
      header['Authorization'] = `Bearer ${token}`
    }
    return header
  }

  async standardApiRequest(endpoint: string, options: {
    token?: boolean,
    headers?: any,
    method?: 'POST' | 'GET',
    body?: any
  }) {
    const {
      token,
      headers,
      method,
      body
    } = options
    const header: any = {
      'Content-Type': headers && headers.contentType ? headers.contentType : 'application/json',
    }
    if (token && this.authService) {
      const tokenVal = await this.authService.getTokenFlow()
      if (tokenVal) {
        header['Authorization'] = `Bearer ${tokenVal}`
      } else {
        return {
          error: 'invalid token'
        }
      }
    }
    return fetch(`${this.apiBaseUrl}/${endpoint}`, {
      method: method ? method : 'GET',
      headers: header,
      ...(body ? { body: body } : {})
    }).then((res) => {
      return res.json()
    }).catch((err) => {
      return {
        error: err
      }
    })
  }

  // BEGIN QUERY DECORATORS
  decorateQueryWithMyProperties(query: any) {
    const queryToSend = { ...query }
    if (!query.propertyId && this.storeService && this.storeService.getState) {
      const { myProperties } = this.storeService.getState().accountData
      queryToSend.propertyId = Object.keys(myProperties).filter(x => myProperties[x].public)
    }
    return queryToSend
  }
  // END QUERY DECORATORS

  // ASYNC LOOP PAGINATION
  async getAllPaginatedData(endpoint: string, options: {
    baseQuery: any,
    pagination: {
      page: number,
      limit: number
    }
  }) {
    const { baseQuery, pagination } = options
    const dataList: any[] = []
    let done = false
    try {
      while (!done) {
        const dataBatch = await this.standardApiRequest(endpoint, {
          method: 'POST',
          body: JSON.stringify({ ...baseQuery, pagination: pagination }),
          token: true
        })
        if (dataBatch.error) {
          return dataBatch
        }
        if (dataBatch && dataBatch.docs) {
          dataList.push(...dataBatch.docs)
          if (dataBatch.nextPage) {
            pagination.page = dataBatch.nextPage
            continue
          }
        }
        done = true
      }
    } catch (err) {
      done = true
    }
    return {
      docs: dataList,
      ...pagination,
      page: 1,
      limit: dataList.length,
      nextPage: null,
      prevPage: null,
      totalDocs: dataList.length,
      totalPages: 1,
    }
  }

  // ACCOUNT DATA RELATED CALLS
  async getMyProperties() {
    const propertyList: { docs: any[] } = await this.getAllPaginatedData('properties/my-properties', {
      baseQuery: {},
      pagination: {
        page: 1,
        limit: 100
      },
      // projection: {
      //     _id: 1,
      //     title: 1,
      //     description: 1,
      //     address: 1,
      //     owner: 1,
      //     featuredImage: 1,
      //     public: 1,
      //     services: 1,
      //   }
    })
    return { propertyList: propertyList.docs }
  }

  // ICAL RELATED CALLS
  async getPropertyIcalData(propertyId: any) {
    return await this.standardApiRequest(`properties/ical-data/${propertyId}`, {
      token: true
    })
  }

  // PROPERTIES RELATED CALLS
  async getProperties(propertiesQuery: any, pagination: any) {
    return await this.standardApiRequest('properties/find', {
      method: 'POST',
      body: JSON.stringify({ propertiesQuery, pagination }),
      token: true
    })
  }

  async addNewProperty(propertyInfo: any) {
    return await this.standardApiRequest('properties/add-property', {
      method: 'POST',
      body: JSON.stringify({ propertyInfo }),
      token: true
    })
  }

  async editProperty(propertyId: string, propertyInfo: any) {
    return await this.standardApiRequest('properties/edit-property', {
      method: 'POST',
      body: JSON.stringify({ _id: propertyId, propertyInfo }),
      token: true
    })
  }

  async deleteProperty(propertyId: string) {
    return await this.standardApiRequest('properties/delete-property', {
      method: 'POST',
      body: JSON.stringify({ _id: propertyId }),
      token: true
    })
  }

  async importProperties(properties: any) {
    return await this.standardApiRequest('properties/import-properties', {
      method: 'POST',
      body: JSON.stringify({ properties }),
      token: true
    })
  }

  async bulkEditProperties(properties: any) {
    return await this.standardApiRequest('properties/bulk-edit-properties', {
      method: 'POST',
      body: JSON.stringify({ properties }),
      token: true
    })
  }

  // PROPERTY RELATED CALLS
  async getPropertyById(propertyId: string) {
    return await this.standardApiRequest(`properties/view/${propertyId}`, {
      token: true
    })
  }

  // BOOKINGS RELATED CALLS
  async getBookings(bookingsQuery: any, pagination: any) {
    const queryToSend = this.decorateQueryWithMyProperties(bookingsQuery)
    if (pagination.all) {
      return await this.getAllPaginatedData('bookings/find', {
        baseQuery: { bookingsQuery: queryToSend },
        // baseQuery: { bookingsQuery },
        pagination: {
          page: 1,
          limit: 500
        }
      })
    }
    return await this.standardApiRequest('bookings/find', {
      method: 'POST',
      body: JSON.stringify({ bookingsQuery: queryToSend, pagination }),
      // body: JSON.stringify({ bookingsQuery, pagination }),
      token: true
    })
  }

  async addNewBooking(bookingInfo: any) {
    return await this.standardApiRequest('bookings/add-booking', {
      method: 'POST',
      body: JSON.stringify({ bookingInfo }),
      token: true
    })
  }

  async editBooking(bookingId: string, bookingInfo: any) {
    return await this.standardApiRequest('bookings/edit-booking', {
      method: 'POST',
      body: JSON.stringify({ _id: bookingId, bookingInfo }),
      token: true
    })
  }

  async deleteBooking(bookingId: string) {
    return await this.standardApiRequest('bookings/delete-booking', {
      method: 'POST',
      body: JSON.stringify({ _id: bookingId }),
      token: true
    })
  }

  async importBookings(bookings: any) {
    return await this.standardApiRequest('bookings/import-bookings', {
      method: 'POST',
      body: JSON.stringify({ bookings }),
      token: true
    })
  }

  async bulkEditBookings(bookings: any) {
    return await this.standardApiRequest('bookings/bulk-edit-bookings', {
      method: 'POST',
      body: JSON.stringify({ bookings }),
      token: true
    })
  }

  // BOOKING RELATED CALLS
  async getBookingById(bookingId: string) {
    return await this.standardApiRequest(`bookings/view/${bookingId}`, {
      token: true
    })
  }

  // BOOKING MESSAGES RELATED CALLS
  async getBookingMessages(bookingMessagesQuery: any, pagination: any) {
    const queryToSend = this.decorateQueryWithMyProperties(bookingMessagesQuery)
    return await this.standardApiRequest('booking-messages/find', {
      method: 'POST',
      body: JSON.stringify({ bookingMessagesQuery: queryToSend, pagination }),
      token: true
    })
  }

  async replyToBookingMessage(bookingMessageInfo: any, replyEmail: string) {
    return await this.standardApiRequest('booking-messages/reply-booking-message', {
      method: 'POST',
      body: JSON.stringify({ bookingMessageInfo, replyEmail }),
      token: true
    })
  }

  // PROPERTY TASKS RELATED CALLS
  async getPropertyTasks(propertyTasksQuery: any, pagination: any) {
    const queryToSend = this.decorateQueryWithMyProperties(propertyTasksQuery)
    return await this.standardApiRequest('property-tasks/find', {
      method: 'POST',
      body: JSON.stringify({ propertyTasksQuery: queryToSend, pagination }),
      token: true
    })
  }

  async getPropertyTaskById(propertyTaskId: string) {
    return await this.standardApiRequest(`property-tasks/view/${propertyTaskId}`, {
      token: true
    })
  }

  async addNewPropertyTask(propertyTaskInfo: any) {
    return await this.standardApiRequest('property-tasks/add-property-task', {
      method: 'POST',
      body: JSON.stringify({ propertyTaskInfo }),
      token: true
    })
  }

  async editPropertyTask(propertyTaskId: string, propertyTaskInfo: any) {
    return await this.standardApiRequest('property-tasks/edit-property-task', {
      method: 'POST',
      body: JSON.stringify({ _id: propertyTaskId, propertyTaskInfo }),
      token: true
    })
  }

  async deletePropertyTask(propertyTaskId: string) {
    return await this.standardApiRequest('property-tasks/delete-property-task', {
      method: 'POST',
      body: JSON.stringify({ _id: propertyTaskId }),
      token: true
    })
  }

  // USER RELATED CALLS
  async checkUserCredentials(token: string) {
    return await this.standardApiRequest(`users/userInfo`, {
      token: true
    })
  }

  async loginUser(credentials: any) {
    return await this.standardApiRequest('users/login', {
      method: 'POST',
      body: JSON.stringify(credentials)
    })
  }

  async updateProfile(updatedProfileInfo: any) {
    return await this.standardApiRequest('users/update-user', {
      method: 'POST',
      body: JSON.stringify(updatedProfileInfo),
      token: true
    })
  }

  async editUser(userId: string, updatedProfileInfo: any) {
    return await this.standardApiRequest('users/edit-user', {
      method: 'POST',
      body: JSON.stringify({
        _id: userId,
        updatedUserInfo: updatedProfileInfo
      }),
      token: true
    })
  }

  async changePassword(updatedPasswordInfo: any) {
    return await this.standardApiRequest('users/change-password', {
      method: 'POST',
      body: JSON.stringify(updatedPasswordInfo),
      token: true
    })
  }

  // USERS RELATED CALLS
  async getUsers(usersQuery: any, pagination: any) {
    return await this.standardApiRequest('users/find', {
      method: 'POST',
      body: JSON.stringify({ usersQuery, pagination }),
      token: true
    })
  }

  async deleteUser(userId: string) {
    return await this.standardApiRequest('users/delete-user', {
      method: 'POST',
      body: JSON.stringify({ _id: userId }),
      token: true
    })
  }

  // SINGLE USER RELATED CALLS
  async getUserInfo(userId: string) {
    return await this.standardApiRequest(`users/profile/${userId}`, {})
  }

  // REGISTRATION RELATED CALLS
  async registerNewUser(newUserInfo: any) {
    return await this.standardApiRequest('users/register', {
      method: 'POST',
      body: JSON.stringify({
        user: {
          email: newUserInfo.email,
          firstName: newUserInfo.firstName,
          lastName: newUserInfo.lastName,
          password: newUserInfo.password,
          // role: newUserInfo.role // only assign if current user is an admin
        }
      }),
    })
  }

  // FORGOT PASSWORD RELATED CALLS
  async forgotPassword(username: string) {
    return await this.standardApiRequest('users/forgot-password', {
      method: 'POST',
      body: JSON.stringify({ username: username }),
      // token: true
    })
  }

  // UPLOAD FILE STUFF
  async uploadImage(fixedImage: any, fileName: string) {
    fixedImage.lastModifiedDate = new Date()
    fixedImage.name = fileName
    // fixedImage.filename = fileName
    // console.log(fileName)
    const formData = new FormData()
    formData.append('image', fixedImage, fileName)
    const uploadedImageData = await fetch(`${this.apiBaseUrl}/upload/image-upload`, {
      method: 'POST',
      body: formData
    }).then((res) => {
      return res.json()
    }).catch(err => {
      return {
        error: err
      }
    })
    if (uploadedImageData && uploadedImageData.imageUrl) {
      return uploadedImageData.imageUrl
    }
    return ''
  }

  // TOOL RELATED STUFF 
  async getFontaineRates(checkIn: string, checkOut: string) {
    const fontaineRateData = await fetch(`${this.apiBaseUrl}/fontaine/fontaine-rates/${checkIn}/${checkOut}`).then((res) => {
      return res.json()
    }).catch((err) => {
      return {
        error: err
      }
    })
    return fontaineRateData
  }

  // Bukins RELATED CALLS
  async getBukins(bukinsQuery: any, pagination: any) {
    if (pagination.all) {
      return await this.getAllPaginatedData('bukins/find', {
        baseQuery: { bukinsQuery },
        pagination: {
          page: 1,
          limit: 500
        }
      })
    }
    return await this.standardApiRequest('bukins/find', {
      method: 'POST',
      body: JSON.stringify({ bukinsQuery, pagination }),
      token: true
    })
  }

  async addNewBukin(bukinInfo: any) {
    return await this.standardApiRequest('bukins/add-bukin', {
      method: 'POST',
      body: JSON.stringify({ bukinInfo }),
      token: true
    })
  }

  async editBukin(bukinId: string, bukinInfo: any) {
    return await this.standardApiRequest('bukins/edit-bukin', {
      method: 'POST',
      body: JSON.stringify({ _id: bukinId, bukinInfo }),
      token: true
    })
  }

  async deleteBukin(bukinId: string) {
    return await this.standardApiRequest('bukins/delete-bukin', {
      method: 'POST',
      body: JSON.stringify({ _id: bukinId }),
      token: true
    })
  }

  async importBukins(bukins: any) {
    return await this.standardApiRequest('bukins/import-bukins', {
      method: 'POST',
      body: JSON.stringify({ bukins }),
      token: true
    })
  }

  async bulkEditBukins(bukins: any) {
    return await this.standardApiRequest('bukins/bulk-edit-bukins', {
      method: 'POST',
      body: JSON.stringify({ bukins }),
      token: true
    })
  }

  async getBukinById(bukinId: string) {
    return await this.standardApiRequest(`bukins/view/${bukinId}`, {
      token: true
    })
  }
  // END Bukins RELATED CALLS

  // Bukibles RELATED CALLS
  async getBukibles(bukiblesQuery: any, pagination: any) {
    if (pagination.all) {
      return await this.getAllPaginatedData('bukibles/find', {
        baseQuery: { bukiblesQuery },
        pagination: {
          page: 1,
          limit: 500
        }
      })
    }
    return await this.standardApiRequest('bukibles/find', {
      method: 'POST',
      body: JSON.stringify({ bukiblesQuery, pagination }),
      token: true
    })
  }

  async addNewBukible(bukibleInfo: any) {
    return await this.standardApiRequest('bukibles/add-bukible', {
      method: 'POST',
      body: JSON.stringify({ bukibleInfo }),
      token: true
    })
  }

  async editBukible(bukibleId: string, bukibleInfo: any) {
    return await this.standardApiRequest('bukibles/edit-bukible', {
      method: 'POST',
      body: JSON.stringify({ _id: bukibleId, bukibleInfo }),
      token: true
    })
  }

  async deleteBukible(bukibleId: string) {
    return await this.standardApiRequest('bukibles/delete-bukible', {
      method: 'POST',
      body: JSON.stringify({ _id: bukibleId }),
      token: true
    })
  }

  async importBukibles(bukibles: any) {
    return await this.standardApiRequest('bukibles/import-bukibles', {
      method: 'POST',
      body: JSON.stringify({ bukibles }),
      token: true
    })
  }

  async bulkEditBukibles(bukibles: any) {
    return await this.standardApiRequest('bukibles/bulk-edit-bukibles', {
      method: 'POST',
      body: JSON.stringify({ bukibles }),
      token: true
    })
  }

  async getBukibleById(bukibleId: string) {
    return await this.standardApiRequest(`bukibles/view/${bukibleId}`, {
      token: true
    })
  }

  // END Bukibles RELATED CALLS


  // BookingRequests RELATED CALLS
  async getBookingRequests(bookingRequestsQuery: any, pagination: any) {
    if (pagination.all) {
      return await this.getAllPaginatedData('booking-requests/find', {
        baseQuery: { bookingRequestsQuery },
        pagination: {
          page: 1,
          limit: 500
        }
      })
    }
    return await this.standardApiRequest('booking-requests/find', {
      method: 'POST',
      body: JSON.stringify({ bookingRequestsQuery, pagination }),
      token: true
    })
  }

  async addNewBookingRequest(bookingRequestInfo: any) {
    return await this.standardApiRequest('booking-requests/add-booking-request', {
      method: 'POST',
      body: JSON.stringify({ bookingRequestInfo }),
      token: true
    })
  }

  async editBookingRequest(bookingRequestId: string, bookingRequestInfo: any) {
    return await this.standardApiRequest('booking-requests/edit-booking-request', {
      method: 'POST',
      body: JSON.stringify({ _id: bookingRequestId, bookingRequestInfo }),
      token: true
    })
  }

  async deleteBookingRequest(bookingRequestId: string) {
    return await this.standardApiRequest('booking-requests/delete-booking-request', {
      method: 'POST',
      body: JSON.stringify({ _id: bookingRequestId }),
      token: true
    })
  }

  async importBookingRequests(bookingRequests: any) {
    return await this.standardApiRequest('booking-requests/import-booking-requests', {
      method: 'POST',
      body: JSON.stringify({ bookingRequests }),
      token: true
    })
  }

  async bulkEditBookingRequests(bookingRequests: any) {
    return await this.standardApiRequest('booking-requests/bulk-edit-booking-requests', {
      method: 'POST',
      body: JSON.stringify({ bookingRequests }),
      token: true
    })
  }

  async getBookingRequestById(bookingRequestId: string) {
    return await this.standardApiRequest(`booking-requests/view/${bookingRequestId}`, {
      token: true
    })
  }
  // END BookingRequests RELATED CALLS

  // MediaFiles RELATED CALLS
  async getMediaFiles(mediaFilesQuery: any, pagination: any) {
    if (pagination.all) {
      return await this.getAllPaginatedData('mediaFiles/find', {
        baseQuery: { mediaFilesQuery },
        pagination: {
          page: 1,
          limit: 500
        }
      })
    }
    return await this.standardApiRequest('mediaFiles/find', {
      method: 'POST',
      body: JSON.stringify({ mediaFilesQuery, pagination }),
      token: true
    })
  }

  async addNewMediaFile(mediaFileInfo: any) {
    return await this.standardApiRequest('mediaFiles/add-mediaFile', {
      method: 'POST',
      body: JSON.stringify({ mediaFileInfo }),
      token: true
    })
  }

  async editMediaFile(mediaFileId: string, mediaFileInfo: any) {
    return await this.standardApiRequest('mediaFiles/edit-mediaFile', {
      method: 'POST',
      body: JSON.stringify({ _id: mediaFileId, mediaFileInfo }),
      token: true
    })
  }

  async deleteMediaFile(mediaFileId: string) {
    return await this.standardApiRequest('mediaFiles/delete-mediaFile', {
      method: 'POST',
      body: JSON.stringify({ _id: mediaFileId }),
      token: true
    })
  }

  async importMediaFiles(mediaFiles: any) {
    return await this.standardApiRequest('mediaFiles/import-mediaFiles', {
      method: 'POST',
      body: JSON.stringify({ mediaFiles }),
      token: true
    })
  }

  async bulkEditMediaFiles(mediaFiles: any) {
    return await this.standardApiRequest('mediaFiles/bulk-edit-mediaFiles', {
      method: 'POST',
      body: JSON.stringify({ mediaFiles }),
      token: true
    })
  }

  async getMediaFileById(mediaFileId: string) {
    return await this.standardApiRequest(`mediaFiles/view/${mediaFileId}`, {
      token: true
    })
  }

  // END MediaFiles RELATED CALLS

  // Inquiries RELATED CALLS
  async getInquiries(inquiriesQuery: any, pagination: any) {
    if (pagination.all) {
      return await this.getAllPaginatedData('inquiries/find', {
        baseQuery: { inquiriesQuery },
        pagination: {
          page: 1,
          limit: 500
        }
      })
    }
    return await this.standardApiRequest('inquiries/find', {
      method: 'POST',
      body: JSON.stringify({ inquiriesQuery, pagination }),
      token: true
    })
  }

  async addNewInquiry(inquiryInfo: any) {
    return await this.standardApiRequest('inquiries/add-inquiry', {
      method: 'POST',
      body: JSON.stringify({ inquiryInfo }),
      token: true
    })
  }

  async editInquiry(inquiryId: string, inquiryInfo: any) {
    return await this.standardApiRequest('inquiries/edit-inquiry', {
      method: 'POST',
      body: JSON.stringify({ _id: inquiryId, inquiryInfo }),
      token: true
    })
  }

  async deleteInquiry(inquiryId: string) {
    return await this.standardApiRequest('inquiries/delete-inquiry', {
      method: 'POST',
      body: JSON.stringify({ _id: inquiryId }),
      token: true
    })
  }

  async importInquiries(inquiries: any) {
    return await this.standardApiRequest('inquiries/import-inquiries', {
      method: 'POST',
      body: JSON.stringify({ inquiries }),
      token: true
    })
  }

  async bulkEditInquiries(inquiries: any) {
    return await this.standardApiRequest('inquiries/bulk-edit-inquiries', {
      method: 'POST',
      body: JSON.stringify({ inquiries }),
      token: true
    })
  }

  async getInquiryById(inquiryId: string) {
    return await this.standardApiRequest(`inquiries/view/${inquiryId}`, {
      token: true
    })
  }

  // END Inquiries RELATED CALLS


  // BookingGuests RELATED CALLS
  async getBookingGuests(bookingGuestsQuery: any, pagination: any) {
    const queryToSend = this.decorateQueryWithMyProperties(bookingGuestsQuery)
    if (pagination.all) {
      return await this.getAllPaginatedData('booking-guests/find', {
        baseQuery: { bookingGuestsQuery: queryToSend },
        pagination: {
          page: 1,
          limit: 500
        }
      })
    }
    return await this.standardApiRequest('booking-guests/find', {
      method: 'POST',
      body: JSON.stringify({ bookingGuestsQuery: queryToSend, pagination }),
      token: true
    })
  }

  async addNewBookingGuest(bookingGuestInfo: any) {
    return await this.standardApiRequest('booking-guests/add-booking-guest', {
      method: 'POST',
      body: JSON.stringify({ bookingGuestInfo }),
      token: true
    })
  }

  async editBookingGuest(bookingGuestId: string, bookingGuestInfo: any) {
    return await this.standardApiRequest('booking-guests/edit-booking-guest', {
      method: 'POST',
      body: JSON.stringify({ _id: bookingGuestId, bookingGuestInfo }),
      token: true
    })
  }

  async deleteBookingGuest(bookingGuestId: string) {
    return await this.standardApiRequest('booking-guests/delete-booking-guest', {
      method: 'POST',
      body: JSON.stringify({ _id: bookingGuestId }),
      token: true
    })
  }

  async importBookingGuests(bookingGuests: any) {
    return await this.standardApiRequest('booking-guests/import-booking-guests', {
      method: 'POST',
      body: JSON.stringify({ bookingGuests }),
      token: true
    })
  }

  async bulkEditBookingGuests(bookingGuests: any) {
    return await this.standardApiRequest('booking-guests/bulk-edit-booking-guests', {
      method: 'POST',
      body: JSON.stringify({ bookingGuests }),
      token: true
    })
  }

  async getBookingGuestById(bookingGuestId: string) {
    return await this.standardApiRequest(`booking-guests/view/${bookingGuestId}`, {
      token: true
    })
  }

  async sendBookingGuestEmail(bookingGuestId: string) {
    return await this.standardApiRequest(`booking-guests/send-booking-guest-info/${bookingGuestId}`, {
      token: true
    })
  }

  // END BookingGuests RELATED CALLS

  // Buildings RELATED CALLS
  async getBuildings(buildingsQuery: any, pagination: any) {
    if (pagination.all) {
      return await this.getAllPaginatedData('buildings/find', {
        baseQuery: { buildingsQuery },
        pagination: {
          page: 1,
          limit: 500
        }
      })
    }
    return await this.standardApiRequest('buildings/find', {
      method: 'POST',
      body: JSON.stringify({ buildingsQuery, pagination }),
      token: true
    })
  }

  async addNewBuilding(buildingInfo: any) {
    return await this.standardApiRequest('buildings/add-building', {
      method: 'POST',
      body: JSON.stringify({ buildingInfo }),
      token: true
    })
  }

  async editBuilding(buildingId: string, buildingInfo: any) {
    return await this.standardApiRequest('buildings/edit-building', {
      method: 'POST',
      body: JSON.stringify({ _id: buildingId, buildingInfo }),
      token: true
    })
  }

  async deleteBuilding(buildingId: string) {
    return await this.standardApiRequest('buildings/delete-building', {
      method: 'POST',
      body: JSON.stringify({ _id: buildingId }),
      token: true
    })
  }

  async importBuildings(buildings: any) {
    return await this.standardApiRequest('buildings/import-buildings', {
      method: 'POST',
      body: JSON.stringify({ buildings }),
      token: true
    })
  }

  async bulkEditBuildings(buildings: any) {
    return await this.standardApiRequest('buildings/bulk-edit-buildings', {
      method: 'POST',
      body: JSON.stringify({ buildings }),
      token: true
    })
  }

  async getBuildingById(buildingId: string) {
    return await this.standardApiRequest(`buildings/view/${buildingId}`, {
      token: true
    })
  }

  // END Buildings RELATED CALLS

  // Purchases RELATED CALLS
  async getPurchases(purchasesQuery: any, pagination: any) {
    if (pagination.all) {
      return await this.getAllPaginatedData('purchases/find', {
        baseQuery: { purchasesQuery },
        pagination: {
          page: 1,
          limit: 500
        }
      })
    }
    return await this.standardApiRequest('purchases/find', {
      method: 'POST',
      body: JSON.stringify({ purchasesQuery, pagination }),
      token: true
    })
  }

  async addNewPurchase(purchaseInfo: any) {
    return await this.standardApiRequest('purchases/add-purchase', {
      method: 'POST',
      body: JSON.stringify({ purchaseInfo }),
      token: true
    })
  }

  async editPurchase(purchaseId: string, purchaseInfo: any) {
    return await this.standardApiRequest('purchases/edit-purchase', {
      method: 'POST',
      body: JSON.stringify({ _id: purchaseId, purchaseInfo }),
      token: true
    })
  }

  async deletePurchase(purchaseId: string) {
    return await this.standardApiRequest('purchases/delete-purchase', {
      method: 'POST',
      body: JSON.stringify({ _id: purchaseId }),
      token: true
    })
  }

  async importPurchases(purchases: any) {
    return await this.standardApiRequest('purchases/import-purchases', {
      method: 'POST',
      body: JSON.stringify({ purchases }),
      token: true
    })
  }

  async bulkEditPurchases(purchases: any) {
    return await this.standardApiRequest('purchases/bulk-edit-purchases', {
      method: 'POST',
      body: JSON.stringify({ purchases }),
      token: true
    })
  }

  async getPurchaseById(purchaseId: string) {
    return await this.standardApiRequest(`purchases/view/${purchaseId}`, {
      token: true
    })
  }

  // END Purchases RELATED CALLS

  // PropertyPrices RELATED CALLS
  async getPropertyPrices(propertyPricesQuery: any, pagination: any) {
    if (pagination.all) {
      return await this.getAllPaginatedData('property-prices/find', {
        baseQuery: { propertyPricesQuery },
        pagination: {
          page: 1,
          limit: 500
        }
      })
    }
    return await this.standardApiRequest('property-prices/find', {
      method: 'POST',
      body: JSON.stringify({ propertyPricesQuery, pagination }),
      token: true
    })
  }

  async addNewPropertyPrice(propertyPriceInfo: any) {
    return await this.standardApiRequest('property-prices/add-property-price', {
      method: 'POST',
      body: JSON.stringify({ propertyPriceInfo }),
      token: true
    })
  }

  async editPropertyPrice(propertyPriceId: string, propertyPriceInfo: any) {
    return await this.standardApiRequest('property-prices/edit-property-price', {
      method: 'POST',
      body: JSON.stringify({ _id: propertyPriceId, propertyPriceInfo }),
      token: true
    })
  }

  async deletePropertyPrice(propertyPriceId: string) {
    return await this.standardApiRequest('property-prices/delete-property-price', {
      method: 'POST',
      body: JSON.stringify({ _id: propertyPriceId }),
      token: true
    })
  }

  async importPropertyPrices(propertyPrices: any) {
    return await this.standardApiRequest('property-prices/import-property-prices', {
      method: 'POST',
      body: JSON.stringify({ propertyPrices }),
      token: true
    })
  }

  async bulkEditPropertyPrices(propertyPrices: any) {
    return await this.standardApiRequest('property-prices/bulk-edit-property-prices', {
      method: 'POST',
      body: JSON.stringify({ propertyPrices }),
      token: true
    })
  }

  async getPropertyPriceById(propertyPriceId: string) {
    return await this.standardApiRequest(`property-prices/view/${propertyPriceId}`, {
      token: true
    })
  }

  // END PropertyPrices RELATED CALLS


  // EMAIL TEMPLATES RELATED CALLS
  async sendBookingConfirmedEmail(bookingId: string) {
    return await this.standardApiRequest(`email/send-booking-confirmed-email/${bookingId}`, {
      token: true
    })
  }

  // END EMAIL TEMPLATES RELATED CALLS


  // DateBlocks RELATED CALLS
  async getDateBlocks(dateBlocksQuery: any, pagination: any) {
    if (pagination.all) {
      return await this.getAllPaginatedData('date-blocks/find', {
        baseQuery: { dateBlocksQuery },
        pagination: {
          page: 1,
          limit: 500
        }
      })
    }
    return await this.standardApiRequest('date-blocks/find', {
      method: 'POST',
      body: JSON.stringify({ dateBlocksQuery, pagination }),
      token: true
    })
  }

  async addNewDateBlock(dateBlockInfo: any) {
    return await this.standardApiRequest('date-blocks/add-date-block', {
      method: 'POST',
      body: JSON.stringify({ dateBlockInfo }),
      token: true
    })
  }

  async editDateBlock(dateBlockId: string, dateBlockInfo: any) {
    return await this.standardApiRequest('date-blocks/edit-date-block', {
      method: 'POST',
      body: JSON.stringify({ _id: dateBlockId, dateBlockInfo }),
      token: true
    })
  }

  async deleteDateBlock(dateBlockId: string) {
    return await this.standardApiRequest('date-blocks/delete-date-block', {
      method: 'POST',
      body: JSON.stringify({ _id: dateBlockId }),
      token: true
    })
  }

  async importDateBlocks(dateBlocks: any) {
    return await this.standardApiRequest('date-blocks/import-date-blocks', {
      method: 'POST',
      body: JSON.stringify({ dateBlocks }),
      token: true
    })
  }

  async bulkEditDateBlocks(dateBlocks: any) {
    return await this.standardApiRequest('date-blocks/bulk-edit-date-blocks', {
      method: 'POST',
      body: JSON.stringify({ dateBlocks }),
      token: true
    })
  }

  async getDateBlockById(dateBlockId: string) {
    return await this.standardApiRequest(`date-blocks/view/${dateBlockId}`, {
      token: true
    })
  }

  // END DateBlocks RELATED CALLS


}

const networkRequestService = new NetworkRequestService()

export default networkRequestService
