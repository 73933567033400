import { createAsyncThunk, createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardPaginatedBuilder, generateStandardNetworkPaginatedState, StandardPaginatedNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface BookingsState extends StandardPaginatedNetworkState {
  bookingsList: any[] | null
}

const generateEmptyBookingsState = (): BookingsState => {
  return {
    ...generateStandardNetworkPaginatedState(),
    bookingsList: null,
  }
}

export const getBookingsAction = createAsyncThunk(
  'bookings/getBookingsActionCall',
  async (queryInfo: { query: any, page: number, limit: number, all?: boolean }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bookings
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const bookingsList = await thunkApi.extra.networkRequest.getBookings(queryInfo.query, { page: queryInfo.page, limit: queryInfo.limit, all: queryInfo.all })
    if (bookingsList.error) {
      return rejectWithValue({
        ...bookingsList,
        currentQuery: queryInfo
      })
    }
    const { docs, ...paginationInfo } = bookingsList
    return {
      docs,
      paginationInfo,
      currentQuery: queryInfo
    }
  }
)

const initialState: BookingsState = generateEmptyBookingsState()

export const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    clearBookings: (state, action: PayloadAction) => {
      return {
        ...generateEmptyBookingsState()
      }
    }
  },
  extraReducers: (builder) => {
    generalStandardPaginatedBuilder(builder, getBookingsAction, 'bookingsList')
  }
})

export const { clearBookings } = bookingsSlice.actions

export const bookingsSelector = createSelector((state: RootState) => state, (state) => state.bookings)

export default bookingsSlice.reducer
