import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import DateBlockForm from "../../../Components/Forms/DateBlockForm/DateBlockForm";
import { addNewDateBlock, dateBlockSelector } from "../../../redux/dateBlock/dateBlockSlice";
import { clearDateBlocks } from "../../../redux/dateBlocks/dateBlocksSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import useActiveQueryHook from "../../../Hooks/UseActiveQuery/useActiveQueryHook";
import { useState } from "react";
import { checkBlockDates } from "../../../redux/generalNetwork/generalNetworkSlice";
import timeService from "../../../services/time/timeService";

export default function AddDateBlockPage() {

  const {
    myProperties,
  } = useMyProperties()
  const propertyOptions = useMyPropertiesSelectValues(myProperties)

  const { activeQuery } = useActiveQueryHook({})
  const initialData = activeQuery.query

  const {
    // loading,
    error,
    message,
  } = useSelector(dateBlockSelector)
  const dispatch = useAppDispatch()
  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    const newDateBlock = await dispatch(addNewDateBlock({ dateBlockInfo: values })).unwrap()
    if (newDateBlock._id && newDateBlock.dateBlockData?.propertyId) {
      const newBlockCheckIn = timeService.dateToMillis(newDateBlock.dateBlockData.checkIn)
      dispatch(navigateTo(`/date-blocks?query={"status":["new","pending","confirmed"], "propertyId": "${newDateBlock.dateBlockData.propertyId}", "checkIn": ${newBlockCheckIn}}`))
      // dispatch(navigateTo(`/date-block/${newDateBlock._id}`))
    }
    dispatch(clearDateBlocks())
    return newDateBlock
  }

  const [dateValidationStatus, setDateValidationStatus] = useState({
    available: true,
    status: '',
    message: ''
  })

  const validateDate = async (selectedPropertyId: string, checkIn: Date, checkOut: Date) => {
    setDateValidationStatus({
      available: false,
      status: 'warning',
      message: 'Checking date range for property'
    })
    const propertyId = [selectedPropertyId]
    // if (myProperties[selectedPropertyId]) {
    //   const {
    //     multiUnit: {
    //       isMultiUnit,
    //       linkedUnits,
    //     }
    //   } = myProperties[selectedPropertyId]
    //   if (isMultiUnit) {
    //     linkedUnits.forEach((unit: PropertyLinkedPropertyType) => {
    //       propertyId.push(unit.propertyId)
    //     })
    //   }
    // }
    const blockData = await dispatch(checkBlockDates({
      propertyId,
      checkIn,
      checkOut
    })).unwrap()
    if (blockData && blockData.docs && blockData.docs.length > 0) {
      setDateValidationStatus({
        available: false,
        status: 'danger',
        message: 'WARNING: selected date range is unavailable for this property'
      })
      return
    }
    setDateValidationStatus({
      available: true,
      status: 'success',
      message: 'This property is available for  the date range'
    })
    return
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add DateBlock</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          <DateBlockForm
            initialValues={{
              propertyId: '',
              ...initialData
            }}
            disabled={false}
            onSubmit={handleSubmit}
            propertyOptionList={propertyOptions}
            propertyData={myProperties}
            dateValidationStatus={dateValidationStatus}
            dateValidationFn={validateDate}
          />

        </Col>
      </Row>
    </Container>
  )
}
