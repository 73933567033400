import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import PropertySearchForm from "../../Components/Forms/PropertySearchForm/PropertySearchForm"
import Pagination from "../../Components/Pagination/Pagination"
import { PropertyEntry } from "../../Components/PropertyEntry/PropertyEntry"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers"
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook"
import { getPropertiesAction, propertiesSelector } from "../../redux/properties/propertiesSlice"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import './propertiesPage.scss'
import { shouldLoadNetwork } from "../../Helpers/ValueHandlers/shouldLoad"

export default function PropertiesPage() {
  const {
    loading,
    error,
    currentRequestId,
    paginationInfo,
    currentQuery,
    message,
    propertyList,
    lastLoad
  } = useSelector(propertiesSelector)
  const dispatch = useAppDispatch()

  const { activeQuery } = useActiveQueryHook({
    query: {
      publicProperties: true,
    },
    limit: 10
  })

  useEffect(() => {
    if (shouldLoadNetwork(loading, currentQuery, activeQuery, lastLoad)) {
      dispatch(getPropertiesAction(activeQuery))
    }
    return () => { }
  })

  const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
    const destination = `/properties?${convertActiveQueryToUrl(pageParams)}`
    dispatch(navigateTo(destination))
  }
  return (
    <Container>
      <Row>
        <Col>
          <h1>Properties</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}

          <PropertySearchForm
            key={currentRequestId}
            disabled={loading === 'pending'}
            onSubmit={(values: any) => {
              loadPage({
                query: values,
                page: 1,
                limit: currentQuery.limit
              })
            }}
            title={activeQuery.query.title}
            publicProperty={activeQuery.query.publicProperties}
          />

          <div className="property-search-form">
            RequestID: {currentRequestId}
          </div>

          {propertyList && propertyList.length > 0 && (
            <div className="property-list-container">
              {propertyList.map((property, idx) => (
                <PropertyEntry
                  key={`property-${property._id}`}
                  propertyInfo={property}
                />
              ))}
            </div>
          )}

          {paginationInfo && (
            <Pagination
              page={paginationInfo.page}
              limit={paginationInfo.limit}
              nextPage={paginationInfo.nextPage}
              prevPage={paginationInfo.prevPage}
              totalPages={paginationInfo.totalPages}
              loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                loadPage({
                  query: currentQuery.query,
                  limit: currentQuery.limit,
                  ...pageLoadInfo
                })
              }}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}
