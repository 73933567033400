import { configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { useDispatch } from "react-redux";
import { createReduxHistoryContext } from "redux-first-history";
import authenticationService from "../services/authentication/authenticationService";
import networkRequestService from "../services/networkRequest/networkRequestService";
import { sleeper } from "../services/sleeper/sleeper";
import accountSlice from "./account/accountSlice";
import accountDataSlice from "./accountData/accountDataSlice";
import bookingSlice from "./booking/bookingSlice";
import bookingsSlice from "./bookings/bookingsSlice";
import counterSlice from "./counter/counterSlice";
import forgotPasswordSlice from "./forgotPassword/forgotPasswordSlice";
import generalNetworkSlice from "./generalNetwork/generalNetworkSlice";
import propertiesSlice from "./properties/propertiesSlice";
import propertySlice from "./property/propertySlice";
import registerUserSlice from "./registerUser/registerUserSlice";
import userSlice from "./user/userSlice";
import usersSlice from "./users/usersSlice";
import icalDataSlice from "./icalData/icalDataSlice";
import bookingMessagesSlice from "./bookingMessages/bookingMessagesSlice";
import propertyTasksSlice from "./propertyTasks/propertyTasksSlice";
import propertyTaskSlice from "./propertyTask/propertyTaskSlice";
import bukinsSlice from "./bukins/bukinsSlice";
import bukinSlice from "./bukin/bukinSlice";
import bukiblesSlice from "./bukibles/bukiblesSlice";
import bukibleSlice from "./bukible/bukibleSlice";
import bookingRequestSlice from "./bookingRequest/bookingRequestSlice";
import bookingRequestsSlice from "./bookingRequests/bookingRequestsSlice";
import mediaFileSlice from "./mediaFile/mediaFileSlice";
import mediaFilesSlice from "./mediaFiles/mediaFilesSlice";
import inquirySlice from "./inquiry/inquirySlice";
import inquiriesSlice from "./inquiries/inquiriesSlice";
import bookingGuestSlice from "./bookingGuest/bookingGuestSlice";
import bookingGuestsSlice from "./bookingGuests/bookingGuestsSlice";
import buildingSlice from "./building/buildingSlice";
import buildingsSlice from "./buildings/buildingsSlice";
import purchaseSlice from "./purchase/purchaseSlice";
import purchasesSlice from "./purchases/purchasesSlice";
import propertyPriceSlice from "./propertyPrice/propertyPriceSlice";
import propertyPricesSlice from "./propertyPrices/propertyPricesSlice";
import dateBlockSlice from "./dateBlock/dateBlockSlice";
import dateBlocksSlice from "./dateBlocks/dateBlocksSlice";

// redux-first-history thing
const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory(), reduxTravelling: true, });

export const store = configureStore({
  devTools: true,
  reducer: {
    router: routerReducer,
    generalNetwork: generalNetworkSlice,
    counter: counterSlice,
    account: accountSlice,
    accountData: accountDataSlice,
    bookings: bookingsSlice,
    booking: bookingSlice,
    bookingMessages: bookingMessagesSlice,
    properties: propertiesSlice,
    property: propertySlice,
    propertyTasks: propertyTasksSlice,
    propertyTask: propertyTaskSlice,
    users: usersSlice,
    user: userSlice,
    registerUser: registerUserSlice,
    forgotPassword: forgotPasswordSlice,
    icalData: icalDataSlice,
    bukins: bukinsSlice,
    bukin: bukinSlice,
    bukibles: bukiblesSlice,
    bukible: bukibleSlice,

    purchases: purchasesSlice,
    purchase: purchaseSlice,

    bookingGuests: bookingGuestsSlice,
    bookingGuest: bookingGuestSlice,

    bookingRequests: bookingRequestsSlice,
    bookingRequest: bookingRequestSlice,

    mediaFiles: mediaFilesSlice,
    mediaFile: mediaFileSlice,

    inquiries: inquiriesSlice,
    inquiry: inquirySlice,

    buildings: buildingsSlice,
    building: buildingSlice,

    propertyPrices: propertyPricesSlice,
    propertyPrice: propertyPriceSlice,

    dateBlocks: dateBlocksSlice,
    dateBlock: dateBlockSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: {
      extraArgument: {
        auth: authenticationService,
        sleep: sleeper,
        networkRequest: networkRequestService
      }
    }
  }).concat(routerMiddleware)
})

// redux-first-history thing
export const history = createReduxHistory(store)

// export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
