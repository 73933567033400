import { propertyPricesSelector, getPropertyPricesAction } from "../../redux/propertyPrices/propertyPricesSlice"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/store"
import { useEffect } from "react"


export default function usePropertyPriceQuery(activeQuery: {
    query: any,
    page: number
    limit: number,
    all?: boolean
}) {
    const {
        loading,
        error,
        currentRequestId,
        paginationInfo,
        currentQuery,
        message,
        propertyPricesList,
    } = useSelector(propertyPricesSelector)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (loading !== 'pending' && JSON.stringify(activeQuery) !== JSON.stringify(currentQuery)) {
            dispatch(getPropertyPricesAction(activeQuery))
        }
        return () => { }
    }, [activeQuery, currentQuery, dispatch, loading])

    return { loading, error, message, propertyPricesList, paginationInfo, currentRequestId, currentQuery }

}