import {  useSelector } from 'react-redux';
import { registerNewUser, registerUserSelector } from '../../../redux/registerUser/registerUserSlice';
import { useAppDispatch } from '../../../redux/store';
import { navigateTo } from '../../../redux/router/routerActions';
import { accountSelector, loginUser } from '../../../redux/account/accountSlice';
import RegisterForm from '../../../Components/Forms/RegisterForm/RegisterForm';
import { Col, Container, Row } from 'react-bootstrap';

export default function RegisterUserPage() {

  const { loading, error, message } = useSelector(registerUserSelector)
  const { token, username } = useSelector(accountSelector)
  const dispatch = useAppDispatch()

  return (
    <Container>
      <Row>
        <Col>
          <h1>Register Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          <RegisterForm
            disabled={loading === 'pending'}
            onSubmit={async(formValues: {firstName: string, lastName: string, email: string, password: string}) => {
              try {
                const createdUser = await dispatch(registerNewUser(formValues)).unwrap()

                if(createdUser) {
                  if(token && username) {
                    dispatch(navigateTo('/users'))
                    return true
                  }
                  await dispatch(loginUser({
                    username: formValues.email,
                    password: formValues.password
                  })).unwrap()
                  if(!token || !username) {
                    dispatch(navigateTo('/'))
                  }
                }
                return true
              } catch(err) {
                // error handler
              }
              return false
            }}
          />
        </Col>
      </Row>
    </Container>
  )
}
