import { useEffect } from "react"
import { useSelector } from "react-redux"
import { accountDataSelector, getMyProperties } from "../../redux/accountData/accountDataSlice"
import { useAppDispatch } from "../../redux/store"
import { shouldLoadNetwork } from "../../Helpers/ValueHandlers/shouldLoad"

export default function useMyProperties() {
  const {
    loading,
    message,
    error,
    myProperties,
    lastLoad
  } = useSelector(accountDataSelector)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (shouldLoadNetwork(loading, {}, {}, lastLoad, { refreshTime: 1000 * 60 * 30 })) {
      dispatch(getMyProperties())
    }
    return () => { }
  })

  return { myProperties, loading, message, error }
}
