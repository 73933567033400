import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import BookingMessageEntry from "../../Components/BookingMessage/BookingMessageEntry";
import { FieldGeneratorFieldType } from "../../Components/FormFields/FieldGenerator/FieldGenerator";
import QuickForm from "../../Components/Forms/QuickForm/QuickForm";
import Pagination from "../../Components/Pagination/Pagination";
import useMyProperties from "../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import { bookingMessagesSelector, getBookingMessagesAction } from "../../redux/bookingMessages/bookingMessagesSlice";
import { useAppDispatch } from "../../redux/store";
import timeService from "../../services/time/timeService";
import { PropertyBookingMessageType } from "../../Types/bookingMessageType";

export default function BookingMessagesPage() {

    const {
        loading,
        error,
        currentRequestId,
        paginationInfo,
        currentQuery,
        message,
        bookingMessagesList,
    } = useSelector(bookingMessagesSelector)

    const { myProperties } = useMyProperties()

    const propertySelectOptions = useMyPropertiesSelectValues(myProperties)

    const [activeQuery, setActiveQuery] = useState<any>({
        query: {
            dateFrom: timeService.getCurrentTime().minus({ days: 2 }).startOf('day').toMillis(),
            dateTo: timeService.getCurrentTime().plus({ days: 2 }).endOf('day').toMillis(),
        },
        limit: paginationInfo?.limit && paginationInfo.limit <= 50 ? paginationInfo.limit : 10,
        page: 1,
    })

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (loading !== 'pending' && JSON.stringify(activeQuery) !== JSON.stringify(currentQuery)) {
            dispatch(getBookingMessagesAction(activeQuery))
        }
        return () => { }
    }, [activeQuery, currentQuery, dispatch, loading])

    const handleBookingMessageSubmit = (data: any) => {
        if (!data.dateFrom || !data.dateTo) {
            return
        }
        const newQuery = {
            ...activeQuery,
            page: 1,
            query: {
                ...activeQuery.query
            }
        }
        newQuery.query.dateFrom = timeService.dateToMillis(data.dateFrom)
        newQuery.query.dateTo = timeService.dateToMillis(data.dateTo)

        if (data.propertyId) {
            newQuery.query.propertyId = data.propertyId
        } else {
            delete newQuery.query.propertyId
        }
        setActiveQuery(newQuery)
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Booking Messages</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {message && (<>{message}<br /></>)}
                    {currentRequestId && (<>{currentRequestId}<br /></>)}

                    <QuickForm
                        fields={[
                            ...bookingMessagesField,
                            {
                                fieldName: 'propertyId',
                                fieldLabel: 'Property',
                                fieldType: 'select',
                                placeholder: 'Select Property',
                                options: propertySelectOptions,
                            }
                        ]}
                        initialData={activeQuery.query}
                        onSubmit={handleBookingMessageSubmit}
                    />

                    {bookingMessagesList && bookingMessagesList.map((b: PropertyBookingMessageType, index: number) => (
                        <BookingMessageEntry
                            key={index}
                            propertyInfo={myProperties && b.propertyId ? myProperties[b.propertyId] : undefined}
                            bookingMessage={b} 
                            full={true}
                            />
                    ))}

                    {paginationInfo && paginationInfo.page && (
                        <Pagination
                            page={paginationInfo.page}
                            limit={paginationInfo.limit}
                            nextPage={paginationInfo.nextPage}
                            prevPage={paginationInfo.prevPage}
                            totalPages={paginationInfo.totalPages}
                            loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                                setActiveQuery({
                                    query: currentQuery.query,
                                    limit: currentQuery.limit,
                                    ...pageLoadInfo
                                })
                            }}
                        />
                    )}


                </Col>
            </Row>
        </Container>
    )
}

const bookingMessagesField: FieldGeneratorFieldType[] = [
    {
        fieldName: 'dateRange',
        fieldLabel: 'Date Range',
        fieldType: 'dateRange',
        placeholder: 'mm/dd/yyyy - mm/dd/yyyy',
        extra: {
            startDate: 'dateFrom',
            endDate: 'dateTo',
        }
    },
    {
        fieldName: 'dateFrom',
        fieldLabel: 'Date From',
        fieldType: 'date',
        required: true,
        hidden: true,
    },
    {
        fieldName: 'dateTo',
        fieldLabel: 'Date To',
        fieldType: 'date',
        required: true,
        hidden: true,
    }
]