import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { generateStandardNetworkState, standardNetworkErrorCall, standardNetworkPendingCall, StandardNetworkState } from "../reduxHelperFunctions";
import { RootState } from "../store";
import { setRole } from "../../services/accessService/accessService";
import { getMyProperties } from "../accountData/accountDataSlice";

interface AccountState extends StandardNetworkState {
  _id?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  role: string;
  profilePic?: string
  token?: string,
  initialized: boolean
}

const generateEmptyUserState = (): AccountState => {
  return {
    initialized: false,
    _id: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    role: 'guest',
    profilePic: '',
    token: '',
    ...generateStandardNetworkState()
  }
}

const initialState: AccountState = generateEmptyUserState()

export const checkUserCredentials = createAsyncThunk(
  'account/checkUserCredentialsCall',
  async (_, thunkApi: any) => {
    const { loading, currentRequestId, initialized } = thunkApi.getState().account
    const { requestId, dispatch } = thunkApi
    if(!initialized) {
      thunkApi.extra.auth.setLogOutFunction(() => {
        thunkApi.dispatch(logoutUser())
      })
      thunkApi.extra.networkRequest.setAuthService(thunkApi.extra.auth)
      thunkApi.extra.networkRequest.setStoreService(thunkApi)
    }
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const userToken = await thunkApi.extra.auth.getTokenFlow()
    if (userToken) {
      const userInfo = await thunkApi.extra.networkRequest.checkUserCredentials()
      if (userInfo.error) {
        return thunkApi.rejectWithValue(userInfo)
      }
      if (userInfo && userInfo.email && userInfo.role) {
        setRole(userInfo.role)
        await dispatch(getMyProperties()).unwrap()
        return {
          token: userToken,
          user: userInfo
        }
      }
    }
    return thunkApi.rejectWithValue('invalid token')
  }
)

export const loginUser = createAsyncThunk(
  'account/loginUserCall',
  async (credentials: any, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().account
    const { requestId, dispatch } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const userInfo = await thunkApi.extra.networkRequest.loginUser(credentials)
    if (userInfo.error) {
      return thunkApi.rejectWithValue(userInfo)
    }
    if (userInfo.token) {
      thunkApi.extra.auth.setToken(userInfo.token)
    }
    if(userInfo.user.role) {
      setRole(userInfo.user.role)
      await dispatch(getMyProperties()).unwrap()
    }
    return userInfo
  }
)

export const updateProfile = createAsyncThunk(
  'account/updateProfileCall',
  async (updatedProfileInfo: any, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().account
    const { requestId } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const userInfo = await thunkApi.extra.networkRequest.updateProfile(updatedProfileInfo)
    if (userInfo.error) {
      return thunkApi.rejectWithValue(userInfo)
    }
    return {
      user: userInfo
    }
  }
)

export const changePassword = createAsyncThunk(
  'account/changePasswordCall',
  async (updatedPasswordInfo: { oldPassword: string, newPassword: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().account
    const { requestId } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const updatedPasswordResponse = await thunkApi.extra.networkRequest.changePassword(updatedPasswordInfo)
    if (updatedPasswordResponse.error) {
      return thunkApi.rejectWithValue(updatedPasswordResponse)
    }
    // do something better here
    return updatedPasswordResponse
  }
)

export const logoutUser = createAsyncThunk(
  'account/logoutUserCall',
  async (_, thunkApi: any) => {
    thunkApi.extra.auth.clearToken()
    setRole('guest')
    return generateEmptyUserState()
  }
)

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<AccountState>) => {
      return {
        ...state,
        ...action.payload,
        loading: 'idle',
        message: ''
      }
    },
  },
  extraReducers: (builder) => {

    // LOGIN USER
    builder.addCase(loginUser.pending, standardNetworkPendingCall('Attempting to login user'))
    builder.addCase(loginUser.rejected, standardNetworkErrorCall())
    builder.addCase(loginUser.fulfilled, (state, action: any) => {
      return {
        ...state,
        ...action.payload.user,
        token: action.payload.token,
        loading: 'idle',
        message: '',
        error: null,
      }
    })

    // LOGOUT USER
    builder.addCase(logoutUser.fulfilled, (state, action: any) => {
      return {
        ...state,
        ...action.payload,
      }
    })

    // CHECK USER CREDENTIALS
    builder.addCase(checkUserCredentials.pending, standardNetworkPendingCall('Checking user credentials'))
    builder.addCase(checkUserCredentials.rejected, (state) => {
      return {
        ...state,
        ...generateEmptyUserState(),
        initialized: true
      }
    })
    builder.addCase(checkUserCredentials.fulfilled, (state, action: any) => {
      return {
        ...state,
        ...action.payload.user,
        token: action.payload.token,
        loading: 'idle',
        message: '',
        initialized: true
      }
    })

    // UPDATE USER
    builder.addCase(updateProfile.pending, standardNetworkPendingCall('Attempting to update profile'))
    builder.addCase(updateProfile.rejected, standardNetworkErrorCall())
    builder.addCase(updateProfile.fulfilled, (state, action: any) => {
      return {
        ...state,
        ...action.payload.user,
        loading: 'idle',
        message: '',
        error: null,
      }
    })

    // CHANGE PASSWORD
    builder.addCase(changePassword.pending, standardNetworkPendingCall('Attempting to update password'))
    builder.addCase(changePassword.rejected, standardNetworkErrorCall())
    builder.addCase(changePassword.fulfilled, (state, action: any) => {
      return {
        ...state,
        loading: 'idle',
        message: '',
        error: null,
      }
    })

  }
})

// Action creators are generated for each case reducer function
export const { setUserInfo } = accountSlice.actions

export const accountSelector = createSelector((state: RootState) => state, (state) => state.account)

export default accountSlice.reducer
