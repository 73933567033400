import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import PropertyTaskEntryView from "../../../Components/PropertyTasks/PropertyTaskEntryView"
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook"
import useOccupancyRef from "../../../Hooks/UseOccupancyRef/UseOccupancyRef"
import useUserRefs from "../../../Hooks/UseUsersQuery/UseUserRefs"
import { accountSelector } from "../../../redux/account/accountSlice"
import { uploadImage } from "../../../redux/generalNetwork/generalNetworkSlice"
import { propertyTaskSelector, getPropertyTaskInfo, editPropertyTask, clearPropertyTask } from "../../../redux/propertyTask/propertyTaskSlice"
import { clearPropertyTasks } from "../../../redux/propertyTasks/propertyTasksSlice"
import { useAppDispatch } from "../../../redux/store"
import { PropertyTaskType } from "../../../Types/propertyTaskType"

export default function PropertyTaskPage() {
  const { username, role } = useSelector(accountSelector)
  const { _id, propertyTaskData, message, loading, error } = useSelector(propertyTaskSelector)
  const { myProperties } = useMyProperties()
  const { propertyTaskId } = useParams()
  const dispatch = useAppDispatch()
  const [imageUploadProgress, setImageUploadProgress] = useState('')

  const userRef = useUserRefs()

  const propertyOccupancyRef = useOccupancyRef({
    myProperties
  })

  useEffect(() => {
    if (loading !== 'pending' && propertyTaskId && _id !== propertyTaskId) {
      dispatch(getPropertyTaskInfo(propertyTaskId))
    }
    return () => { }
  }, [propertyTaskId, _id, dispatch, loading])

  const propertyInfo = myProperties && propertyTaskData && propertyTaskData.propertyId ? myProperties[propertyTaskData.propertyId] : undefined

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single PropertyTask Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}
          {propertyTaskId && propertyTaskId === _id && propertyTaskData && (
            <PropertyTaskEntryView
              taskInfo={propertyTaskData}
              userRef={userRef}
              propertyInfo={propertyInfo}
              username={username}
              occupancyInfo={propertyOccupancyRef[propertyTaskData.propertyId]}
              disabled={loading === 'pending'}
              role={role}
              updateTask={async (updateTaskPayload: {
                _id: string,
                propertyTaskInfo: Partial<PropertyTaskType>
              }) => {
                await dispatch(editPropertyTask(updateTaskPayload)).unwrap()
                dispatch(clearPropertyTask())
                dispatch(clearPropertyTasks())
              }}
              imageUploadProgress={imageUploadProgress}
              uploadImageMultiFn={async (imageInfo: { imageToUpload: any, filename: string }[]) => {
                const uploadedImageUrls = []
                for (let i = 0; i < imageInfo.length; i++) {
                  setImageUploadProgress(`Processing ${i + 1} of ${imageInfo.length}`)
                  const uploadedImageUrl = await dispatch(uploadImage(imageInfo[i])).unwrap()
                  uploadedImageUrls.push(uploadedImageUrl)
                }
                setImageUploadProgress(``)
                return uploadedImageUrls
              }}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}
