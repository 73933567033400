import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import BukibleEntry from "../../../Components/Bukibles/BukibleEntry"
import { bukibleSelector, getBukibleInfo } from "../../../redux/bukible/bukibleSlice"
import { useAppDispatch } from "../../../redux/store"

export default function BukiblePage() {
  const { _id, bukibleData, message, loading, error} = useSelector(bukibleSelector)
  const { bukibleId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && bukibleId && _id !== bukibleId) {
      dispatch(getBukibleInfo(bukibleId))
    }
    return () => {}
  }, [bukibleId, _id, dispatch, loading])

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single Bukible Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}

          {bukibleId && bukibleId === _id && bukibleData && (
            <BukibleEntry bukibleInfo={bukibleData} />
          )}
        </Col>
      </Row>
    </Container>
  )
}
