import './pagination.scss'

export default function Pagination(props: any) {
  const {
    page,
    limit,
    nextPage,
    prevPage,
    totalPages,
    loadPage,
    totalDocs
  } = props
  return (
    <div className="pagination-container">
      <div className="pages-list">

        {prevPage && (
          <div onClick={() => {
            if (prevPage) {
              loadPage({ page: prevPage })
            }
          }} className="pagination-entry page-btn">
            &larr; {prevPage}
          </div>
        )}

        <div className="pagination-entry">
          {page} of {totalPages}
          {totalDocs && <span className="small" style={{color: '#aaa'}}> ({totalDocs} entries)</span>}
        </div>

        {nextPage && (
          <div onClick={() => {
            if (nextPage) {
              loadPage({ page: nextPage })
            }
          }} className="pagination-entry page-btn">
            {nextPage} &rarr;
          </div>
        )}

      </div>

      <div className="pagination-select-container">
        <div className="pagination-page-select">
          <label>
            Page:
            <select value={page} onChange={(val: any) => {
              if (val.target.value !== page) {
                loadPage({ page: val.target.value })
              }
            }} id="setPage">
              {(new Array(totalPages)).fill(0).map((page, idx) => (
                <option key={`page-${idx}`} value={idx + 1}>{idx + 1}</option>
              ))}
            </select>
          </label>
        </div>

        <div className="pagination-page-select">
          <label>
            Page Size:
            <select value={limit} onChange={(val: any) => {
              loadPage({ page: 1, limit: val.target.value })
            }} id="pageSize">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </label>
        </div>
      </div>

    </div>
  )
}
