import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { accountSelector } from "../../../redux/account/accountSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { deleteUser, getUserInfo, userSelector } from "../../../redux/user/userSlice";
import { clearUsers } from "../../../redux/users/usersSlice";

export default function DeleteUserPage() {
  const accountInfo = useSelector(accountSelector)
  const { _id, email, loading, firstName, lastName, message, error } = useSelector(userSelector)
  const { userId } = useParams()
  const dispatch = useAppDispatch()

  const [deleteClicked, setDeleteClicked] = useState(false)

  useEffect(() => {
    if (loading !== 'pending' && userId && _id !== userId) {
      dispatch(getUserInfo(userId))
    }
    return () => { }
  }, [_id, dispatch, loading, userId])

  return (
    <Container>
      <Row>
        <Col>
          <h1>Delete User Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}


          {userId && userId === _id && accountInfo.role && accountInfo.role === 'admin' && accountInfo._id !== _id && (
            <div className="user-info">

              <Stack gap={3}>
                <div>
                  Are you sure you want to delete User: {_id}
                  <br />
                  {(firstName || lastName) && (<>{firstName} {lastName}</>)}
                  <br />
                  {email && (<>{email}</>)}
                </div>
                <div>
                  <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete User</Button>
                </div>
                {deleteClicked && (
                  <div>
                    Once you delete this user it will be gone forever. <br />
                    <Button variant={'danger'} disabled={loading === 'pending'} onClick={async () => {
                      const deleteValue = await dispatch(deleteUser({ _id: _id })).unwrap()
                      if (deleteValue) {
                        dispatch(clearUsers())
                      }
                      dispatch(navigateTo(`/users/`))
                    }}> Confirm Delete User </Button>
                  </div>
                )}
              </Stack>

            </div>
          )}

        </Col>
      </Row>
    </Container>
  )
}
