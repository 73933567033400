import { createAsyncThunk, createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardPaginatedBuilder, generateStandardNetworkPaginatedState, StandardPaginatedNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface PropertyTaskssState extends StandardPaginatedNetworkState {
  propertyTasksList: any[] | null
}

const generateEmptyPropertyTasksState = (): PropertyTaskssState => {
  return {
    ...generateStandardNetworkPaginatedState(),
    propertyTasksList: null,
  }
}

export const getPropertyTasksAction = createAsyncThunk(
  'bookingMessages/getPropertyTasksActionCall',
  async (queryInfo: {query: any, page: number, limit: number}, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().propertyTasks
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const propertyTasksList = await thunkApi.extra.networkRequest.getPropertyTasks(queryInfo.query, {page: queryInfo.page, limit: queryInfo.limit})
    if (propertyTasksList.error) {
      return rejectWithValue({
        ...propertyTasksList,
        currentQuery: queryInfo
      })
    }
    const {docs, ...paginationInfo} = propertyTasksList
    return {
      docs,
      paginationInfo,
      currentQuery: queryInfo
    }
  }
)

const initialState: PropertyTaskssState = generateEmptyPropertyTasksState()

export const propertyTasksSlice = createSlice({
  name: 'propertyTasks',
  initialState,
  reducers: {
    clearPropertyTasks: (state, action: PayloadAction) => {
      return {
        ...generateEmptyPropertyTasksState()
      }
    }
  },
  extraReducers: (builder) => {
    generalStandardPaginatedBuilder(builder, getPropertyTasksAction, 'propertyTasksList')
  }
})

export const { clearPropertyTasks } = propertyTasksSlice.actions

export const propertyTasksSelector = createSelector((state: RootState) => state, (state) => state.propertyTasks)

export default propertyTasksSlice.reducer
