import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { EditUserForm } from "../../../Components/Forms/EditUserForm/EditUserForm";
import { accountSelector } from "../../../redux/account/accountSlice";
import { generalNetworkSelector, uploadImage } from "../../../redux/generalNetwork/generalNetworkSlice";
import { useAppDispatch } from "../../../redux/store";
import { editUserInfo, getUserInfo, userSelector } from "../../../redux/user/userSlice";
import { clearUsers } from "../../../redux/users/usersSlice";

export default function EditUserPage() {

  const accountInfo = useSelector(accountSelector)
  const { _id, email, loading, firstName, lastName, profilePic, role, message, error } = useSelector(userSelector)
  const generalNetworkState = useSelector(generalNetworkSelector)
  const { userId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && userId && _id !== userId) {
      dispatch(getUserInfo(userId))
    }
    return () => { }
  }, [_id, dispatch, loading, userId])

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit User Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          Editing User: {userId} <br />
          <br />
          {/*
          {(firstName || lastName) && (<>{firstName} {lastName}</>)}
          <br />
          {email && (<>{email}</>)}
          <br />
          {role}
          <br />
          */}
          Use the Form Below to Edit the User Information <br />
          <br />
          {_id === userId && (
            <>
              <EditUserForm
                firstName={firstName}
                lastName={lastName}
                email={email}
                role={role}
                profilePic={profilePic ? profilePic : ''}
                disabled={loading === 'pending' || generalNetworkState.uploadImage.loading === 'pending'}
                onSubmit={async (info: any) => {
                  if(role === 'admin' && accountInfo.role !== 'admin') {
                    alert('You do not have permission to edit this user')
                    return
                  }
                  if(_id) {
                    await dispatch(editUserInfo({
                      userId: _id,
                      updatedUserInfo: info
                    })).unwrap()
                    dispatch(clearUsers())
                  }
                  return
                }}
                uploadImageFn={async (imageInfo: { imageToUpload: any, filename: string }) => {
                  const uploadedImageUrl = await dispatch(uploadImage(imageInfo)).unwrap()
                  return uploadedImageUrl
                }}
              />
            </>
          )}


        </Col>
      </Row>
    </Container>
  )
}
