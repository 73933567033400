import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { PropertyForm } from "../../../Components/Forms/PropertyForm/PropertyForm";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import { accountSelector } from "../../../redux/account/accountSlice";
import { clearMyProperties } from "../../../redux/accountData/accountDataSlice";
import { uploadImage } from "../../../redux/generalNetwork/generalNetworkSlice";
import { clearProperties } from "../../../redux/properties/propertiesSlice";
import { addNewProperty, propertySelector } from "../../../redux/property/propertySlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { PropertyType } from "../../../Types/propertyTypes";
import './addProperty.scss'

export default function AddPropertyPage() {
  const { email, role } = useSelector(accountSelector)
  const { loading, error, message } = useSelector(propertySelector)
  const dispatch = useAppDispatch()
  const [imageUploadProgress, setImageUploadProgress] = useState('')
  const { myProperties } = useMyProperties()
  const propertyOptions = useMyPropertiesSelectValues(myProperties)

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add Property</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          {email && role && (
            <PropertyForm
              disabled={loading === 'pending'}
              propertyOptionList={propertyOptions}
              onSubmit={async (values: Partial<PropertyType>) => {
                if (!values.authorizedUsers) {
                  values.authorizedUsers = [{
                    email: email,
                    role: role
                  }]
                } else if (values.authorizedUsers.filter((x) => {
                  return x.email.toLowerCase() === email.toLowerCase()
                }).length === 0) {
                  values.authorizedUsers.push({
                    email: email,
                    role: role
                  })
                }
                const newProperty = await dispatch(addNewProperty(values)).unwrap()
                if (newProperty._id) {
                  dispatch(clearMyProperties())
                  dispatch(clearProperties())
                  dispatch(navigateTo(`/property/${newProperty._id}`))
                }
                return newProperty
              }}
              uploadImageFn={async (imageInfo: { imageToUpload: any, filename: string }) => {
                const uploadedImageUrl = await dispatch(uploadImage(imageInfo)).unwrap()
                return uploadedImageUrl
              }}
              imageUploadProgress={imageUploadProgress}
              uploadImageMultiFn={async (imageInfo: { imageToUpload: any, filename: string }[]) => {
                const uploadedImageUrls = []
                for (let i = 0; i < imageInfo.length; i++) {
                  setImageUploadProgress(`Processing ${i + 1} of ${imageInfo.length}`)
                  const uploadedImageUrl = await dispatch(uploadImage(imageInfo[i])).unwrap()
                  uploadedImageUrls.push(uploadedImageUrl)
                }
                setImageUploadProgress(``)
                return uploadedImageUrls
              }}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}
