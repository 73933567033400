import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { bookingRequestSelector, getBookingRequestInfo } from "../../../redux/bookingRequest/bookingRequestSlice"
import { useAppDispatch } from "../../../redux/store"

export default function BookingRequestPage() {
  const { _id, bookingRequestData, message, loading, error} = useSelector(bookingRequestSelector)
  const { bookingRequestId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && bookingRequestId && _id !== bookingRequestId) {
      dispatch(getBookingRequestInfo(bookingRequestId))
    }
    return () => {}
  }, [bookingRequestId, _id, dispatch, loading])

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single Booking Request Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}

          {bookingRequestId && bookingRequestId === _id && bookingRequestData && (
            <div className="booking-requestinfo">
              <pre>
                {JSON.stringify(bookingRequestData, null, 2)}
              </pre>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
