import { Button, Col, Container, Modal, Row, Stack } from "react-bootstrap";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import { getBookingsAction } from "../../../redux/bookings/bookingsSlice";
import { useAppDispatch } from "../../../redux/store";
import { useMemo, useState } from "react";
import timeService from "../../../services/time/timeService";
import { BookingEntry } from "../../../Components/Bookings/BookingEntry/BookingEntry";
import { PropertyBookingEntry } from "../../../Types/bookingTypes";
import { editBooking } from "../../../redux/booking/bookingSlice";
import { bulkEditBookings } from "../../../redux/generalNetwork/generalNetworkSlice";
import QuickForm from "../../../Components/Forms/QuickForm/QuickForm";
import useBookingsByPropertyMemo from "../../../Memos/Bookings/UseBookingsByPropertyMemo";
import useListToRefMemo from "../../../Memos/General/UseListToRefMemo";
import { FieldGeneratorFieldType } from "../../../Components/FormFields/FieldGenerator/FieldGenerator";
import useBookingQuery from "../../../Hooks/UseBookingQuery/UseBookingQueryHook";
import { copyToClipboard } from "../../../Helpers/ValueHandlers/copyText";
import { FONTAINE_PUPPET_CHECK_BLACKOUTS, FONTAINE_PUPPET_CHECK_BOOKINGS, FONTAINE_PUPPET_VALIDATE_BOOKING } from "../../../Config/config";
import useDateBlockQuery from "../../../Hooks/UseDateBlockQuery/useDateBlockQueryHook";
import DateBlockForm from "../../../Components/Forms/DateBlockForm/DateBlockForm";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import { editDateBlock } from "../../../redux/dateBlock/dateBlockSlice";
import { clearDateBlocks } from "../../../redux/dateBlocks/dateBlocksSlice";
import Collapsible from "../../../Components/Collapsible/Collapsible";
import canAccess from "../../../services/accessService/accessService";
import { createBlockShiftLogic } from "../../../Helpers/Blocks/createBlockShiftLogic";
import { DateBlockEntryType } from "../../../Types/dateBlockTypes";

export default function UnconfirmedBookingsPage() {

    const [releaseMode, setReleaseMode] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const startOfToday = timeService.getCurrentTime().minus({ days: 1 }).startOf('day').toMillis()
    const queryEndDate = timeService.getCurrentTime().plus({ months: 1 }).endOf('day').toMillis()
    const [activeQuery, setActiveQuery] = useState<any>({
        query: {
            checkIn: startOfToday,
            checkOut: queryEndDate,
            bookingStatus: ['new', 'pending'],
            requiresConfirmation: true,
            confirmationCode: ['', null, 'submitted']
        },
        limit: 500,
        page: 1
    })

    const releaseBlockQuery = {
        query: {
            checkIn: activeQuery.query.checkIn,
            checkOut: activeQuery.query.checkOut,
            bookingStatus: ['new', 'pending', 'confirmed'],
            requiresConfirmation: true,
        },
        limit: 500,
        page: 1
    }

    const {
        error,
        message,
        bookingsList,
        paginationInfo
    } = useBookingQuery(releaseMode ? releaseBlockQuery : activeQuery)

    const [expandAllProperties, setExpandAllProperties] = useState<boolean>(false)
    const [openProperties,] = useState<any>({})
    // const [openProperties, setOpenProperties] = useState<any>({})
    // const { location } = useSelector(routerSelector)
    const { myProperties } = useMyProperties()
    const propertyOptions = useMyPropertiesSelectValues(myProperties)
    const [bookingQueue, setBookingQueue] = useState<any>({})
    const [activeConfirmationForm, setActiveConfirmationForm] = useState<string>('')
    const bookingsByProperty = useBookingsByPropertyMemo(bookingsList ? bookingsList : [])
    const bookingRef = useListToRefMemo(bookingsList ? bookingsList : [])
    const [fontaineBlackoutDates, setFontaineBlackoutDates] = useState<any>({})


    // begin block logic //----------------------------------------------
    const blockEndDate = timeService.getCurrentTime().plus({ months: 2 }).endOf('day').toMillis()
    const [activeBlocksQuery, setActiveBlocksQuery] = useState<any>({
        query: {
            checkIn: startOfToday,
            checkOut: blockEndDate,
            status: ['new', 'pending', 'confirmed'],
        },
        limit: 500,
        page: 1
    })
    const {
        // error: blockError,
        // message: blockMessage,
        dateBlocksList,
    } = useDateBlockQuery(activeBlocksQuery)

    const [activeBlockEdit, setActiveBlockEdit] = useState<string>('')
    const {
        blocksByProperties,
        overlapProperty,
        overlapBlocks
    } = useMemo(() => {
        const blockByPropertyRef: any = {}
        const overlapPropertyRef: any = {}
        const overlapBlocksRef: any = {}
        if (!dateBlocksList || !Array.isArray(dateBlocksList)) {
            return {
                blocksByProperties: blockByPropertyRef,
                overlapProperty: overlapPropertyRef,
                overlapBlocks: overlapBlocksRef
            }
        }

        dateBlocksList.forEach((block) => {
            if (!block.propertyId) { return }
            if (!blockByPropertyRef[block.propertyId]) {
                blockByPropertyRef[block.propertyId] = []
            } else {
                const checkInMillis = timeService.createLuxonDate(block.checkIn).toMillis()
                const checkOutMillis = timeService.createLuxonDate(block.checkOut).toMillis()
                let overlapFound = false
                blockByPropertyRef[block.propertyId].forEach((b: any) => {
                    const bCheckIn = timeService.createLuxonDate(b.checkIn).toMillis()
                    const bCheckOut = timeService.createLuxonDate(b.checkOut).toMillis()
                    if (checkInMillis <= bCheckOut && checkOutMillis >= bCheckIn) {
                        overlapFound = true
                    }
                })
                if (overlapFound) {
                    overlapPropertyRef[block.propertyId] = true
                    overlapBlocksRef[block._id] = true
                }
            }
            blockByPropertyRef[block.propertyId].push(block)
        })

        return {
            blocksByProperties: blockByPropertyRef,
            overlapProperty: overlapPropertyRef,
            overlapBlocks: overlapBlocksRef
        }
    }, [dateBlocksList])
    const blockListRef = useListToRefMemo(dateBlocksList ? dateBlocksList : [])
    // end block logic //----------------------------------------------


    const editBookingEntry = async (updatedBooking: PropertyBookingEntry) => {
        if (!updatedBooking._id) { return }
        await dispatch(editBooking({
            _id: updatedBooking._id,
            bookingInfo: updatedBooking
        })).unwrap()
        setActiveConfirmationForm('')
        dispatch(getBookingsAction(activeQuery))
    }

    const createExtraButtons = (booking: PropertyBookingEntry) => {
        const extraButtons: any = []

        if (!canAccess('routes', 'edit-booking')) {
            return extraButtons
        }

        if (booking.bookingStatus !== 'new') {
            extraButtons.push({
                label: 'Mark New',
                onClick: (b: PropertyBookingEntry) => {
                    editBookingEntry({
                        ...b,
                        bookingStatus: 'new',
                        confirmationCode: ''
                    })
                },
                variant: 'secondary'
            })
        }

        if (booking.confirmationCode !== 'submitted') {
            extraButtons.push({
                label: 'Mark Submitted',
                onClick: (b: PropertyBookingEntry) => {
                    editBookingEntry({
                        ...b,
                        bookingStatus: 'pending',
                        confirmationCode: 'submitted'
                    })
                },
                variant: 'warning'
            })
        }

        if (booking._id) {
            if (!bookingQueue[booking._id]) {
                extraButtons.push({
                    label: 'Add to Queue',
                    onClick: addBookingToQueue,
                    variant: 'primary'
                })
            }
            if (bookingQueue[booking._id]) {
                extraButtons.push({
                    label: 'Remove from Queue',
                    onClick: removeBookingFromQueue,
                    variant: 'danger'
                })
            }
        }

        if ((!booking.confirmationCode || booking.confirmationCode === 'submitted') && activeConfirmationForm !== booking._id) {
            extraButtons.push({
                label: 'Add Confirmation',
                onClick: (b: PropertyBookingEntry) => {
                    setActiveConfirmationForm(b._id ? b._id : '')
                },
                variant: 'success'
            })
        }

        // if (activeConfirmationForm === booking._id) {
        //     extraButtons.push({
        //         label: 'Cancel Confirmation',
        //         onClick: (b: PropertyBookingEntry) => {
        //             setActiveConfirmationForm('')
        //         },
        //         variant: 'danger'
        //     })
        // }

        return extraButtons
    }

    const createCopyData = () => {
        let copyData = ''
        Object.keys(bookingQueue).forEach(x => {
            const booking = bookingRef[x]
            const propertyInfo: any = myProperties[booking.propertyId] ? myProperties[booking.propertyId] : {}
            if (booking && propertyInfo) {
                if (copyData.length > 0) {
                    copyData += '\n'
                }
                copyData += `${propertyInfo?.address?.address2}\tCONFIRMATION\tSTATUS\t${booking.contactInfo.firstName}\t${booking.contactInfo.lastName}\t${timeService.getFormattedTime(booking.checkIn, 'MM/dd/yyyy')}\t${timeService.getFormattedTime(booking.checkOut, 'MM/dd/yyyy')}\t${timeService.calculateNights(booking.checkIn, booking.checkOut)}\t${propertyInfo.owner.firstName}\t${propertyInfo.owner.lastName}\tCLEANING\t4\tSPA`
                if (propertyInfo && propertyInfo.linkedServices) {
                    propertyInfo.linkedServices.forEach((s: any) => {
                        if (s.linkedService === 'fontainePortal' && s.linkedServiceId) {
                            const linkedServiceData = JSON.parse(s.linkedServiceId)
                            if (linkedServiceData && linkedServiceData.email && linkedServiceData.phone) {
                                copyData += `\t${linkedServiceData.email}\t${linkedServiceData.phone}`
                            }
                        }
                    })
                }
            }
        })
        return copyData
    }

    const copyContentToClipboard = () => {
        const copyData = createCopyData()
        // navigator.clipboard.writeText(copyData)
        // alert('data copied to clipboard')
        copyToClipboard(copyData)
    }

    const markAllAsSubmitted = async () => {
        if (!bookingQueue) { return }
        const bookingsToEdit = Object.keys(bookingQueue).map(b => {
            return {
                ...bookingRef[b],
                bookingStatus: 'pending',
                confirmationCode: 'submitted'
            }
        })
        setBookingQueue({})
        await dispatch(bulkEditBookings(bookingsToEdit)).unwrap()
        dispatch(getBookingsAction(activeQuery))
    }

    const addBookingToQueue = (b: PropertyBookingEntry) => {
        if (b._id) {
            const newQueue = { ...bookingQueue }
            newQueue[b._id] = b
            setBookingQueue({ ...newQueue })
        }
    }

    const queueAllBookings = () => {
        if (bookingsList) {
            const newQueue: any = {}
            bookingsList.forEach((b: PropertyBookingEntry) => {
                if (b._id) {
                    newQueue[b._id] = b
                }
            })
            setBookingQueue({ ...newQueue })
        }
    }

    const removeBookingFromQueue = (b: PropertyBookingEntry) => {
        if (b._id) {
            const newQueue = { ...bookingQueue }
            delete newQueue[b._id]
            setBookingQueue({ ...newQueue })
        }
    }

    const handleUnconfirmedBookingsFormSubmit = async (data: any) => {
        const newQuery = {
            ...activeQuery,
            query: {
                ...activeQuery.query,
                confirmationCode: ['', null, 'submitted']
            }
        }

        const newBlockQuery = {
            ...activeBlocksQuery,
            query: {
                ...activeBlocksQuery.query,
                status: ['new', 'pending', 'confirmed']
            }
        }

        setBookingQueue({})
        setActiveBlockEdit('')
        setActiveConfirmationForm('')

        if (data.checkIn) {
            newQuery.query.checkIn = timeService.dateToMillis(data.checkIn)
            newBlockQuery.query.checkIn = timeService.dateToMillis(data.checkIn)
        } else {
            newQuery.query.checkIn = null
            newBlockQuery.query.checkIn = null
        }
        if (data.checkOut) {
            newQuery.query.checkOut = timeService.dateToMillis(data.checkOut)
            newBlockQuery.query.checkOut = timeService.createLuxonDate(data.checkOut).plus({ months: 1 }).toMillis()
            // newBlockQuery.query.checkOut = timeService.dateToMillis(data.checkOut)
        } else {
            newQuery.query.checkOut = null
            newBlockQuery.query.checkOut = null
        }
        if (data.hideSubmitted === false) {
            newQuery.query.confirmationCode.push('submitted')
        }
        setActiveQuery(newQuery)
        setActiveBlocksQuery(newBlockQuery)
    }

    const forceFontaineValidation = async () => {
        const response = await fetch(FONTAINE_PUPPET_VALIDATE_BOOKING, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            // body: fontainePayload
        }).then((res) => {
            return res.json()
        }).catch((err) => {
            return {
                error: err
            }
        })

        console.log(response)
        alert(response.error ? response.error : 'Request has been sent for puppet to validate and email with results will be sent shortly')
    }

    const forceFontainePuppet = async () => {
        const fontaineResponse = await fetch(FONTAINE_PUPPET_CHECK_BOOKINGS, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            // body: fontainePayload
        }).then((res) => {
            return res.json()
        }).catch((err) => {
            return {
                error: err
            }
        })

        console.log(fontaineResponse)
        alert(fontaineResponse.error ? fontaineResponse.error : 'Request has been sent to puppet and email with results will be sent shortly')
    }

    const checkFontaineBlackoutDates = async () => {
        setFontaineBlackoutDates({
            loading: true
        })
        const fontaineResponse = await fetch(FONTAINE_PUPPET_CHECK_BLACKOUTS, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            // body: fontainePayload
        }).then((res) => {
            return res.json()
        }).catch((err) => {
            return {
                error: err
            }
        })

        // const convertDateStringToMillis = (dateString: string) => {
        //     const [month, day, year] = dateString.split('/')
        //     const date = timeService.createLuxonDateFromObject({
        //         month: +month,
        //         day: +day,
        //         year: +year,
        //         hour: 16,
        //         minute: 0,
        //         second: 0
        //     })
        //     return date.toMillis()
        // }

        console.log(fontaineResponse)
        // if (fontaineResponse && fontaineResponse.blackoutDates && fontaineResponse.blackoutDates.blackoutDates) {
        //     const blackOutsByDate: any = {}

        //     const rightNow = Date.now()
        //     const millisIn24Hours = 24 * 60 * 60 * 1000
        //     const blackoutDates = fontaineResponse.blackoutDates.blackoutDates.map((x: any) => {
        //         const blackOutcheckIn = convertDateStringToMillis(x.resArrival)
        //         const blackOutcheckOut = convertDateStringToMillis(x.resDeparture)
        //         return {
        //             ...x,
        //             checkIn: blackOutcheckIn,
        //             checkOut: blackOutcheckOut,
        //             isRange: blackOutcheckOut - blackOutcheckIn > millisIn24Hours
        //         }
        //     }).filter((x: any) => {
        //         if (x.checkIn < rightNow) {
        //             return false
        //         }

        //         const [month, day, year] = x.resArrival.split('/')

        //         if (!blackOutsByDate[year]) {
        //             blackOutsByDate[year] = {}
        //         }
        //         if (!blackOutsByDate[year][month]) {
        //             blackOutsByDate[year][month] = []
        //         }
        //         blackOutsByDate[year][month].push(+day)
        //         return true
        //     })
        //     console.log(blackoutDates)
        //     console.log(blackOutsByDate)
        //     fontaineResponse.blackoutDates = blackoutDates
        //     fontaineResponse.blackOutsByDate = blackOutsByDate
        // }
        setFontaineBlackoutDates({
            loading: false,
            ...fontaineResponse,
            blackoutLines: fontaineResponse.blackoutDates.blackoutLines
        })
    }

    const handleEditDateBlock = async (_id: string, data: any) => {
        const values: any = {
            ...data
        }
        if (_id) {
            const editedDateBlock = await dispatch(editDateBlock({ _id, dateBlockInfo: values })).unwrap()
            dispatch(clearDateBlocks())
            setActiveBlockEdit('')
            return editedDateBlock
        }
    }

    const copyShiftBlocks = () => {
        const copyData = createBlockShiftLogic(myProperties, bookingQueue, blocksByProperties)
        copyToClipboard(copyData)
    }

    const createReleaseData = () => {
        const currentTimeMillis = timeService.getCurrentTime().toMillis()
        let currentQueryCheckIn = timeService.createLuxonDate(activeQuery.query.checkIn).set({ hour: 16 }).toMillis()
        if (currentQueryCheckIn < currentTimeMillis) {
            currentQueryCheckIn = timeService.createLuxonDate(activeQuery.query.checkIn).minus({ days: 5 }).set({ hour: 16 }).toMillis()
        }
        const currentQueryCheckOut = timeService.createLuxonDate(activeQuery.query.checkOut).set({ hour: 11 }).toMillis()
        const dummyBooking = {
            checkIn: currentQueryCheckIn,
            checkOut: currentQueryCheckOut,
            bookingStatus: 'new',
            confirmationCode: '',
            contactInfo: {
                firstName: 'DELETE',
                lastName: 'DELETE'
            },
            _id: 'DELETE',
            propertyId: 'DELETE'
        }
        const fakeBookingClear: any = {}

        const confirmedBookings: any = {}

        bookingsList?.forEach((b: PropertyBookingEntry) => {
            if (b._id) {
                fakeBookingClear[b._id] = b
            }
            const checkInMillis = timeService.createLuxonDate(b.checkIn).toMillis()
            const checkOutMillis = timeService.createLuxonDate(b.checkOut).toMillis()
            if (b.bookingStatus === 'confirmed' && b.confirmationCode && b.confirmationCode !== 'submitted') {
                confirmedBookings[`${b.propertyId}-${checkInMillis}-${checkOutMillis}`] = 1
            }
        })

        const cleanBlockList: any = {}

        Object.keys(blocksByProperties).forEach((x: string) => {
            const blockList = blocksByProperties[x]
            if (!blockList || blockList.length < 1) { return }

            cleanBlockList[x] = []

            let overlappingBlocks = false

            blockList.forEach((b: DateBlockEntryType) => {
                const blockCheckInMillis = timeService.dateToMillis(b.checkIn)
                const blockCheckOutMillis = timeService.dateToMillis(b.checkOut)
                if (blockCheckOutMillis >= currentQueryCheckIn && blockCheckInMillis <= currentQueryCheckOut) {
                    overlappingBlocks = true
                }
                const confirmedBookingsKey = `${b.propertyId}-${blockCheckInMillis}-${blockCheckOutMillis}`
                if (b.confirmationCode && b.status === 'confirmed' && !confirmedBookings[confirmedBookingsKey]) {
                    cleanBlockList[x].push(b)
                }
            })
            if (!overlappingBlocks) {
                return
            }

            const newFakeBooking = {
                ...dummyBooking,
                propertyId: x,
                _id: `DELETE-${x}`,
            }
            fakeBookingClear[newFakeBooking._id] = newFakeBooking
        })

        const releaseDataText = createBlockShiftLogic(myProperties, fakeBookingClear, cleanBlockList, { disableNewBookings: true })
        // const releaseDataText = createBlockShiftLogic(myProperties, fakeBookingClear, blocksByProperties, { disableNewBookings: true })
        // console.log(releaseDataText)
        return releaseDataText
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Unconfirmed Bookings</h1>
                    {/* <a href="/assets/fontaine-filler.zip" download="fontaine-filler.zip">Click here to download chrome extension</a> */}

                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {message && (<>{message}<br /></>)}

                    {canAccess('routes', 'fontaine-tools') && (
                        <>
                            {/* force puppet button */}
                            <Button onClick={forceFontainePuppet} className="me-2 mb-2">
                                Force Fontaine
                            </Button>

                            {/* check blackout dates */}
                            <Button onClick={checkFontaineBlackoutDates} className="me-2 mb-2">
                                Check Blackout Dates
                            </Button>

                            {/* validate fontaine booking */}
                            <Button onClick={forceFontaineValidation} className="me-2 mb-2">
                                Validate Fontaine Books
                            </Button>

                            <div className="w-full my-2 small fst-italic">
                                NOTE: Puppet runs every 15 minutes
                            </div>

                            <hr />
                            {fontaineBlackoutDates && Object.keys(fontaineBlackoutDates).length > 0 && (
                                <div className="mb-4">
                                    <h3>Blackout Dates</h3>
                                    {fontaineBlackoutDates.loading && (
                                        <div>Loading...</div>
                                    )}
                                    {fontaineBlackoutDates.error && (
                                        <div>{fontaineBlackoutDates.error}</div>
                                    )}

                                    {fontaineBlackoutDates.blackoutLines && fontaineBlackoutDates.blackoutLines.map((x: any, idx: number) => (
                                        <div key={idx}>
                                            {x}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </>
                    )}

                    <QuickForm
                        initialData={{
                            checkIn: startOfToday,
                            checkOut: queryEndDate,
                            hideSubmitted: false,
                        }}
                        onSubmit={handleUnconfirmedBookingsFormSubmit}
                        fields={unconfirmedBookingsFormFields}
                    />
                    <hr />
                    {!releaseMode && (
                        <>
                            <Button
                                onClick={() => {
                                    setExpandAllProperties(!expandAllProperties)
                                }}
                                className="me-2 mb-2"
                                variant={'secondary'}>
                                {expandAllProperties || Object.keys(openProperties).length > 0 ? 'Collapse All' : 'Expand All'}
                            </Button>

                            {bookingQueue && bookingsList?.length && Object.keys(bookingQueue).length < bookingsList?.length ? (
                                <Button
                                    onClick={queueAllBookings}
                                    className="me-2 mb-2"
                                    variant={'primary'}>
                                    Queue All
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => {
                                        setBookingQueue({})
                                    }}
                                    className="me-2 mb-2"
                                    variant={'danger'}>
                                    Clear Queue
                                </Button>
                            )}


                            {paginationInfo?.totalDocs && (
                                <div>
                                    {paginationInfo.totalDocs} unconfirmed
                                </div>
                            )}
                            <hr />

                        </>
                    )}

                    {!releaseMode && bookingsByProperty.map((property: { propertyId: string, bookings: PropertyBookingEntry[] }, idx: number) => (
                        <div key={`${property.propertyId}-${idx}`}>
                            <Collapsible
                                key={expandAllProperties ? `expanded-${idx}` : `closed-${idx}`}
                                initialValue={expandAllProperties}
                                title={myProperties[property.propertyId]?.title}
                                TitleTag={'h3'}
                            >
                                <div className="booking-entry-collapse-wrapper">

                                    {blocksByProperties[property.propertyId] && blocksByProperties[property.propertyId].length > 0 && (
                                        <div>
                                            <h4>Blocks</h4>

                                            <div className="d-flex gap-2 flex-wrap my-1">
                                                {canAccess('routes', 'date-blocks') && blocksByProperties[property.propertyId].map((block: any, idx: number) => (
                                                    <div key={idx} className={`p-2 border rounded me-1 mb-1 position-relative ${!block.confirmationCode ? 'bg-danger text-light' : ''} ${activeBlockEdit === block._id ? 'bg-warning' : ''}`}>
                                                        {block.firstName} {block.lastName} <br />
                                                        {timeService.createLuxonDate(block.checkIn).toFormat('MM/dd')} - {timeService.createLuxonDate(block.checkOut).toFormat('MM/dd/yyyy')} <br />
                                                        {block.status} / {block.confirmationCode ? block.confirmationCode : 'UNCONFIRMED'} <br />
                                                        {overlapBlocks[block._id] && (
                                                            <div className="text-danger">
                                                                Overlapping
                                                            </div>
                                                        )}
                                                        {canAccess('routes', 'edit-date-block') && (
                                                            <div
                                                                role={'button'}
                                                                className="position-absolute  top-0 end-0"
                                                                onClick={() => {
                                                                    setActiveBlockEdit(block._id)
                                                                }}
                                                            >
                                                                ✏️
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                            {overlapProperty[property.propertyId] && (
                                                <div className="text-danger my-2">
                                                    Overlapping Blocks
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {property.bookings.map((booking: PropertyBookingEntry) => (
                                        <BookingEntry
                                            key={booking._id}
                                            bookingInfo={booking}
                                            propertyName={myProperties[property.propertyId]?.title}
                                            extraButtons={createExtraButtons(booking)}
                                        >
                                            {activeConfirmationForm === booking._id && (
                                                <QuickForm
                                                    initialData={{
                                                        confirmationCode: booking.confirmationCode
                                                    }}
                                                    onSubmit={async (data) => {
                                                        editBookingEntry({
                                                            ...booking,
                                                            confirmationCode: data.confirmationCode,
                                                            bookingStatus: 'confirmed'
                                                        })
                                                    }}
                                                    onCancel={() => { setActiveConfirmationForm('') }}
                                                    fields={[
                                                        {
                                                            fieldName: 'confirmationCode',
                                                            fieldLabel: 'Confirmation Code',
                                                            fieldType: 'text',
                                                            required: true
                                                        }
                                                    ]}
                                                />
                                            )}
                                        </BookingEntry>
                                    ))}
                                </div>

                            </Collapsible>
                            <hr />
                            {/* </Collapse> */}
                        </div>
                    ))}
                </Col>
            </Row>

            {/* Queue and copy data Block */}
            {!releaseMode && Object.keys(bookingQueue).length > 0 && (
                <Row>
                    <Col>
                        <h2>Queue</h2>
                        <hr />
                        <Stack gap={3} className={'mb-3'}>
                            {Object.keys(bookingQueue).map((bookingId, idx) => {
                                const booking: PropertyBookingEntry = bookingRef[bookingId] ? bookingRef[bookingId] : bookingQueue[bookingId]
                                return (
                                    <div key={`${bookingId}-${idx}`}>
                                        <div className="booking-queue-info mb-2">
                                            {myProperties &&
                                                myProperties[booking.propertyId] && (
                                                    <b>
                                                        {myProperties[booking.propertyId].title}
                                                    </b>
                                                )} <br />
                                            {booking.bookingStatus} <br />
                                            {booking?.contactInfo?.firstName} {booking?.contactInfo?.lastName} <br />
                                            {timeService.getFormattedTime(booking.checkIn)} -&nbsp;
                                            {timeService.getFormattedTime(booking.checkOut)}
                                        </div>
                                        <Button
                                            onClick={() => { removeBookingFromQueue(booking) }}
                                            size={'sm'}
                                            variant="secondary">
                                            Remove
                                        </Button>
                                    </div>
                                )
                            })}
                        </Stack>
                        <hr />
                        <pre>
                            {createCopyData()}
                        </pre>
                        <Button onClick={copyContentToClipboard} className="me-2 mb-2">
                            Copy to Clipboard
                        </Button>
                        <Button onClick={markAllAsSubmitted} className="me-2 mb-2">
                            Mark All Submitted
                        </Button>
                        <hr />

                        <pre>
                            {/* LOGIC GOES HERE */}
                            {createBlockShiftLogic(myProperties, bookingQueue, blocksByProperties)}
                        </pre>

                        <hr />
                        <Button onClick={copyShiftBlocks} className="me-2 mb-2">
                            Copy Shift Blocks
                        </Button>

                        <hr />
                    </Col>
                </Row>
            )}


            {/* RELEASE MODE STUFF */}
            {!releaseMode && (
                <Row>
                    <Col>
                        {/* enable release mode button */}
                        <Button
                            variant="danger"
                            onClick={() => {
                                setReleaseMode(true)
                            }}
                            className="me-2 mb-2"
                        >
                            Enable Release Mode
                        </Button>
                    </Col>
                </Row>
            )}

            {releaseMode && Object.keys(blocksByProperties).length > 0 && (
                <Row className="py-2" style={{
                    background: '#eee'
                }}>
                    <Col>
                        <h3>Release Data</h3>
                        <pre>
                            {/* LOGIC GOES HERE */}
                            {createReleaseData()}
                        </pre>
                        <hr />
                        <Button onClick={() => {
                            const releaseData = createReleaseData()
                            copyToClipboard(releaseData)
                        }} variant="warning" className="me-2 mb-2">
                            Copy Release Data
                        </Button>

                        {/* cancel release mode button */}
                        <Button
                            variant="danger"
                            onClick={() => {
                                setReleaseMode(false)
                            }}
                            className="me-2 mb-2"
                        >
                            Cancel Release Mode
                        </Button>
                    </Col>
                </Row>
            )}
            {/* END RELEASE MODE STUFF */}


            {/* Edit Block Modal */}
            <Modal
                size={'lg'}
                show={activeBlockEdit.length > 0 && blockListRef[activeBlockEdit]}
                onHide={() => {
                    setActiveBlockEdit('')
                }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Block</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {activeBlockEdit.length > 0 && blockListRef[activeBlockEdit] && (
                        <>
                            <b>Current Info:</b> <br />
                            {blockListRef[activeBlockEdit].firstName} {blockListRef[activeBlockEdit].lastName} <br />
                            {timeService.createLuxonDate(blockListRef[activeBlockEdit].checkIn).toFormat('MM/dd')} - {timeService.createLuxonDate(blockListRef[activeBlockEdit].checkOut).toFormat('MM/dd/yyyy')} <br />
                            {blockListRef[activeBlockEdit].status} / {blockListRef[activeBlockEdit].confirmationCode ? blockListRef[activeBlockEdit].confirmationCode : '-'} <br />
                            <hr />
                            <DateBlockForm
                                disabled={false}
                                onSubmit={async (v: any) => {
                                    handleEditDateBlock(activeBlockEdit, v)
                                }}
                                initialValues={blockListRef[activeBlockEdit]}
                                propertyData={myProperties}
                                propertyOptionList={propertyOptions}
                                showFields={{
                                    dateRange: true,
                                    checkIn: true,
                                    checkOut: true,
                                    confirmationCode: true,
                                    status: true,
                                }}
                            />
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setActiveBlockEdit('')
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

// Helper stuff
const unconfirmedBookingsFormFields: FieldGeneratorFieldType[] = [
    {
        fieldName: 'checkIn',
        fieldLabel: 'Check In',
        fieldType: 'date',
        required: true
    },
    {
        fieldName: 'checkOut',
        fieldLabel: 'Check Out',
        fieldType: 'date',
        required: true
    },
    {
        fieldName: 'hideSubmitted',
        fieldLabel: 'Hide Submitted',
        fieldType: 'checkbox',
        required: false
    }
]

// const monthsKey = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']