import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import BukinForm from "../../../Components/Forms/BukinForm/BukinForm";
import { addNewBukin, bukinSelector } from "../../../redux/bukin/bukinSlice";
import { clearBukins } from "../../../redux/bukins/bukinsSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import useBukibleQuery from "../../../Hooks/UseBukibleQuery/useBukibleQueryHook";
import { useMemo } from "react";

export default function AddBukinPage() {
  const {
    // loading,
    error,
    message,
  } = useSelector(bukinSelector)
  const dispatch = useAppDispatch()

  const {
    // loading,
    // error,
    // currentRequestId,
    // paginationInfo,
    // currentQuery,
    // message,
    bukiblesList,
  } = useBukibleQuery({
    query: {},
    limit: 500,
    page: 1,
  })

  const bukibleSelectOptions = useMemo(() => {
    if (!bukiblesList) { return [] }
    return bukiblesList.map(bukible => ({
      value: bukible._id,
      text: bukible.title
    }))
  }, [bukiblesList])


  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    const newBukin = await dispatch(addNewBukin({ bukinInfo: values })).unwrap()
    if (newBukin._id) {
      dispatch(navigateTo(`/bukin/${newBukin._id}`))
    }
    dispatch(clearBukins())
    return newBukin
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add Bukin</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          <BukinForm
            initialValues={{}}
            disabled={false}
            onSubmit={handleSubmit}
            bukibleSelectOptions={bukibleSelectOptions}
          />

        </Col>
      </Row>
    </Container>
  )
}
