import { useState } from "react"
import { uploadImage } from "../../redux/generalNetwork/generalNetworkSlice"
import { useAppDispatch } from "../../redux/store"

export default function useUploadImage() {
    
    const [imageUploadProgress, setImageUploadProgress] = useState('')
    const dispatch = useAppDispatch()
    
    const uploadImageFn = async (imageInfo: { imageToUpload: any, filename: string }) => {
        const uploadedImageUrl = await dispatch(uploadImage(imageInfo)).unwrap()
        return uploadedImageUrl
    }

    const uploadImageMultiFn = async (imageInfo: { imageToUpload: any, filename: string }[]) => {
        const uploadedImageUrls = []
        for (let i = 0; i < imageInfo.length; i++) {
            setImageUploadProgress(`Processing ${i + 1} of ${imageInfo.length}`)
            const uploadedImageUrl = await dispatch(uploadImage(imageInfo[i])).unwrap()
            uploadedImageUrls.push(uploadedImageUrl)
        }
        setImageUploadProgress(``)
        return uploadedImageUrls
    }


    return { uploadImageFn, uploadImageMultiFn, imageUploadProgress }

}