import { Button, Col, Container, Row } from "react-bootstrap";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import Collapsible from "../../../Components/Collapsible/Collapsible";
import { useMemo, useState } from "react";
import PropertyMultiSelect from "../../../Components/PropertyMultiSelect/PropertyMultiSelect";
import Papa from "papaparse";
import FileUploadField from "../../../Components/FormFields/FileUpload/FileUploadField";
import { determineFileType, identifyField } from "./bookingExportCombinerDefinitions";
import { bookingMapDefinitions } from "./bookingExportCombinerMapper";
import { PropertyBookingEntry } from "../../../Types/bookingTypes";
import timeService from "../../../services/time/timeService";

export default function BookingExportCombinerPage() {

    const { myProperties, loading: myPropertiesLoading } = useMyProperties();
    const [selectedProperties, setSelectedProperties] = useState<any>({});

    const [masterBookingList, setMasterBookingList] = useState<any>({})
    const [fileUrl, setFileUrl] = useState<string>('');

    const [badNamesList, setBadNamesList] = useState<any>([])

    const [tempFileData, setTempFileData] = useState<any>({
        data: [],
        type: ''
    });

    const validPropertyNames = useMemo(() => {
        const usablePropertyNames: any = {};
        Object.keys(myProperties).forEach((propertyId: string) => {
            const x = myProperties[propertyId];
            if (x.listed) {
                x.listed.forEach((y) => {
                    if (y.internalName) usablePropertyNames[y.internalName.toLowerCase().trim()] = x._id
                    if (y.internalTitle) usablePropertyNames[y.internalTitle.toLowerCase().trim()] = x._id
                })
            }
            if (x.aliases) {
                x.aliases.forEach((y) => {
                    if (y.alias) usablePropertyNames[y.alias.toLowerCase().trim()] = x._id
                })
            }
        })
        return usablePropertyNames;
    }, [myProperties])

    const validPropertyNameObject = () => {
        const propertyIdList: any = {}  
        Object.keys(validPropertyNames).forEach((x) => {
            let propertyId = validPropertyNames[x]
            if(!propertyIdList[propertyId]) propertyIdList[propertyId] = []
            if(propertyId !== x) propertyIdList[propertyId].push(x)
        })  
        return propertyIdList
    }

    const handleFileAdd = (files: FileList) => {
        const fileData: any = files[0]
        Papa.parse(fileData, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                processImportData(results.data)
            }
        })
    }

    const processImportData = (data: any[]) => {
        const fileType = determineFileType(Object.keys(data[0]))
        setTempFileData({
            data,
            type: fileType
        })
    }

    const resetTempFileData = () => {
        setTempFileData({
            data: [],
            type: ''
        })
    }

    const processTempFileData = () => {
        const { processedData, badNames } = checkTitles(tempFileData.data, identifyField[tempFileData.type])
        if (badNames.length > 0) {
            setBadNamesList(badNames)
            return
        }
        const mappedBookingData = processedData.map((entry: any) => {
            return bookingMapDefinitions()[tempFileData.type](entry)
        })
        addBookingsToMasterList(mappedBookingData)
    }

    const addBookingsToMasterList = (bookingsToAdd: PropertyBookingEntry[]) => {
        const bookings: any = { ...masterBookingList }

        const deepFields: any = {
            contactInfo: ['firstName', 'lastName', 'email', 'phone'],
            guestInfo: ['guestTotal', 'adults', 'children', 'infants']
        }
        bookingsToAdd.forEach((x: any) => {
            if (!bookings[x.bookingStatus]) {
                bookings[x.bookingStatus] = {}
            }
            if (!bookings[x.bookingStatus][x.propertyId]) {
                bookings[x.bookingStatus][x.propertyId] = {}
            }
            if (!bookings[x.bookingStatus][x.propertyId][timeService.createDateRangeHash(x.checkIn, x.checkOut)]) {
                bookings[x.bookingStatus][x.propertyId][timeService.createDateRangeHash(x.checkIn, x.checkOut)] = {
                    ...x
                }
            } else {
                Object.keys(x).forEach((y) => {
                    if (!deepFields[y]) {
                        if (x[y] != null) {
                            bookings[x.bookingStatus][x.propertyId][timeService.createDateRangeHash(x.checkIn, x.checkOut)][y] = x[y]
                        }
                    } else {
                        deepFields[y].forEach((z: any) => {
                            if (x[y][z] != null) {
                                bookings[x.bookingStatus][x.propertyId][timeService.createDateRangeHash(x.checkIn, x.checkOut)][y][z] = x[y][z]
                            }
                        })
                    }

                })
            }

        })
        resetTempFileData()
        setMasterBookingList(bookings)
    }

    const checkTitles = (list: any[], field: string) => {
        const badNames: any = {}
        const modifiedList = list.map((entry: any) => {
            const x: any = { ...entry }
            const identifier = x[field].toLowerCase().trim().replace(/ {2}/g, ' ')
            if (validPropertyNames[identifier]) {
                x._propertyID = validPropertyNames[identifier]
            } else {
                badNames[identifier] = identifier
            }
            return x
        })
        return { processedData: modifiedList, badNames: Object.keys(badNames) }
    }

    const keyedEntryTotal = (key: string) => {
        let totalEntries = 0
        Object.keys(masterBookingList[key]).forEach((x) => {
            totalEntries += Object.keys(masterBookingList[key][x]).length
        })
        return totalEntries
    }

    const prepareFile = () => {
        const bookings = { ...masterBookingList }
        const convertedBookings: any[] = []
        const bookingPool: any[] = []
        const propertyIdList: any = validPropertyNameObject()

        Object.keys(bookings).forEach((x) => {
            Object.keys(bookings[x]).forEach((y) => {
                Object.keys(bookings[x][y]).forEach((z) => {
                    bookingPool.push(bookings[x][y][z])
                })
            })
        })

        bookingPool.forEach((x) => {
            const convertedData = {
                _id: x._id,
                alias: propertyIdList[x.propertyId][0],
                title: propertyIdList[x.propertyId].length > 1 ? propertyIdList[x.propertyId][1] : propertyIdList[x.propertyId][0],
                nickName: propertyIdList[x.propertyId].length > 2 ? propertyIdList[x.propertyId][2] : propertyIdList[x.propertyId][0],
                propertyId: x.propertyId,
                checkIn: x.checkIn,
                checkOut: x.checkOut,
                pricePerNight: isFinite(x.pricePerNight) ? x.pricePerNight : null,
                source: x.source,
                sourceConfirmation: x.sourceConfirmation,
                firstName: x.contactInfo.firstName,
                lastName: x.contactInfo.lastName,
                guestTotal: x.guestInfo.guestTotal,
                adults: x.guestInfo.adults,
                children: x.guestInfo.children,
                infants: x.guestInfo.infants,
                email: x.contactInfo.email,
                phone: x.contactInfo.phone,
                dateBooked: x.dateBooked,
                dateInquired: x.dateInquired,
                bookingStatus: x.bookingStatus,
                requiresConfirmation: x.requiresConfirmation,
                confirmationCode: x.confirmationCode,
            }
            convertedBookings.push(convertedData)
        })
        const filteredConvertedBookings = convertedBookings.map((x) => {
            if (selectedProperties[x.propertyId]) {
                x.requiresConfirmation = true
            }
            if (x.phone && x.phone[0] === '+') {
                x.phone = "'" + x.phone
            }
            return x
        }).filter((x) => {
            if (x.source === 'hotel' && x.pricePerNight === null && (x.firstName.toLowerCase() === 'hotel' || x.firstName.toLowerCase() === 'empty')) {
                return false
            }

            if (x.status === 'canceled' && (x.pricePerNight === 0 || x.pricePerNight == null)) {
                return false
            }

            return true
        })
        const downloadData = Papa.unparse(filteredConvertedBookings)
        const blob = new Blob([downloadData], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)
        setFileUrl(url)
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Booking Export Combiner</h1>
                    <hr />

                    Use this tool to combine export from multiple booking systems into a single file.
                    <br /> <br />
                    It currently accepts the following sites:
                    <br />
                    <ul>
                        <li>Airbnb</li>
                        <li>Homeaway</li>
                        <li>Homeaway Financial</li>
                        <li>Fontaine</li>
                        <li>Internal File(bclient exports)</li>
                    </ul>

                    <h3>Instructions</h3>

                    {myPropertiesLoading === 'pending' && <p>Loading...</p>}
                    {myProperties && (
                        <>
                            <ul>
                                <li>Select the properties that require confirmation, you currently have {Object.keys(selectedProperties).length} selected</li>
                            </ul>
                            <Collapsible
                                openText={'Select Properties'}
                                closeText={'Done Selecting'}
                            >
                                <PropertyMultiSelect
                                    selectedProperties={selectedProperties}
                                    setSelectedProperties={setSelectedProperties}
                                    propertyData={myProperties}
                                />
                            </Collapsible>
                        </>
                    )}

                    <ul>
                        <li>Download the booking exports from each site</li>
                        <li>Upload the files to this tool</li>
                        <li>Confirm each file type as you upload</li>
                        <li>Click the 'Combine' button</li>
                        <li>Download the combined file</li>
                    </ul>

                    <hr />

                    <FileUploadField
                        onFileAdd={handleFileAdd}
                        multiple={false}
                        fieldName="file"
                        disabled={false}
                    />

                    {tempFileData.data.length > 0 && (
                        <>
                            You have selected a {tempFileData.type} file with {tempFileData.data.length} entries.
                            <br />
                            {tempFileData.type !== 'custom' && (
                                <Button className={'mb-2 me-2'} onClick={() => processTempFileData()} variant={'success'}>
                                    Confirm
                                </Button>
                            )}
                            <Button className={'mb-2 me-2'} variant={'danger'} onClick={() => resetTempFileData()}>
                                Clear
                            </Button>

                        </>
                    )}

                    {badNamesList.length > 0 && (
                        <div className="bad-names-list">
                            {badNamesList.map((badName: string, i: number) => (
                                <div key={i}>
                                    {badName}
                                </div>
                            ))}
                        </div>
                    )}

                    {Object.keys(masterBookingList).length > 0 && (
                        <div className="master-booking-combo-list">
                            Data Totals: <br />
                            {Object.keys(masterBookingList).map((bookingKey, i) => (
                                <div key={i}>
                                    {bookingKey}: {Object.keys(masterBookingList[bookingKey]).length} properties and {keyedEntryTotal(bookingKey)} entries
                                </div>
                            ))}
                        </div>
                    )}

                    {badNamesList.length === 0 && Object.keys(masterBookingList).length > 0 && (
                        <div>
                            <Button className={'mb-2 me-2'} onClick={() => prepareFile()}>
                                Prepare File
                            </Button>
                            <br />
                            {fileUrl && (
                                <a className="btn btn-success me-2 mb-2" href={fileUrl} download="exported-data.csv">Download File</a>
                            )}

                        </div>
                    )}

                </Col>
            </Row>
        </Container>
    )
}