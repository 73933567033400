import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import PropertyPriceForm from "../../../Components/Forms/PropertyPriceForm/PropertyPriceForm";
import { propertyPriceSelector, editPropertyPrice, getPropertyPriceInfo } from "../../../redux/propertyPrice/propertyPriceSlice";
import { clearPropertyPrices } from "../../../redux/propertyPrices/propertyPricesSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";

export default function EditPropertyPricePage() {
  const { _id, propertyPriceData, message, loading, error } = useSelector(propertyPriceSelector)
  const { propertyPriceId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && propertyPriceId && _id !== propertyPriceId) {
      dispatch(getPropertyPriceInfo(propertyPriceId))
    }
    return () => { }
  }, [propertyPriceId, _id, dispatch, loading, propertyPriceData])


  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (_id) {
      const editedPropertyPrice = await dispatch(editPropertyPrice({ _id, propertyPriceInfo: values })).unwrap()
      if (editedPropertyPrice._id) {
        dispatch(navigateTo(`/property-price/${editedPropertyPrice._id}`))
      }
      dispatch(clearPropertyPrices())
      return editedPropertyPrice
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit PropertyPrice</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          {propertyPriceId && propertyPriceId === _id && propertyPriceData && (
            <PropertyPriceForm
              disabled={false}
              initialValues={propertyPriceData}
              onSubmit={handleSubmit}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
