import { Col, Container, Row } from "react-bootstrap"
import Pagination from "../../Components/Pagination/Pagination"
import useBookingGuestQuery from "../../Hooks/UseBookingGuestQuery/useBookingGuestQueryHook"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import QuickForm from "../../Components/Forms/QuickForm/QuickForm"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers";
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook";
import BookingGuestEntry from "../../Components/BookingGuests/BookingGuestEntry/BookingGuestEntry"
import useMyProperties from "../../Hooks/UseMyProperties/UseMyPropertiesHook"
import useMyPropertiesSelectValues from "../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook"

export default function BookingGuestsPage() {

    const { myProperties } = useMyProperties()
    const propertyOptions = useMyPropertiesSelectValues(myProperties)

    const dispatch = useAppDispatch()

    const { activeQuery } = useActiveQueryHook({
        query: {},
        limit: 10,
    })

    const {
        loading,
        error,
        currentRequestId,
        paginationInfo,
        currentQuery,
        message,
        bookingGuestsList,
    } = useBookingGuestQuery(activeQuery)

    const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
        const destination = `/booking-guests?${convertActiveQueryToUrl(pageParams)}`
        dispatch(navigateTo(destination))
    }


    return (
        <Container>
            <Row>
                <Col>
                    <h1>BookingGuests</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {message && (<>{message}<br /></>)}

                    <QuickForm
                        key={currentRequestId}
                        initialData={{
                            // name: activeQuery.query.name,
                            propertyId: activeQuery.query.propertyId,
                            arrivalTimeStart: activeQuery.query.arrivalTimeStart,
                            arrivalTimeEnd: activeQuery.query.arrivalTimeEnd,
                        }}
                        fields={[
                            {
                                fieldName: 'propertyId',
                                fieldLabel: 'Property',
                                fieldType: 'select',
                                placeholder: '- Select Property -',
                                options: propertyOptions,
                            },
                            {
                                fieldName: 'arrivalTimeStart',
                                fieldLabel: 'Arrival Start',
                                fieldType: 'date',
                                placeholder: 'Arrival Start',
                            },
                            {
                                fieldName: 'arrivalTimeEnd',
                                fieldLabel: 'Arrival End',
                                fieldType: 'date',
                                placeholder: 'Arrival End',
                            },
                        ]}
                        disabled={loading === 'pending'}
                        onSubmit={(values: any) => {
                            loadPage({
                                query: values,
                                page: 1,
                                limit: currentQuery.limit
                            })
                        }}
                    />

                    <div className="bookingGuest-list-container">
                        {bookingGuestsList && bookingGuestsList.length > 0 && bookingGuestsList.map((bookingGuestEntry: any, idx: number) => (
                            <BookingGuestEntry
                                key={idx}
                                bookingGuestInfo={bookingGuestEntry}
                                propertyData={myProperties[bookingGuestEntry.propertyId] ? myProperties[bookingGuestEntry.propertyId] : undefined}
                            />
                        ))
                        }
                    </div>

                    {paginationInfo && (
                        <Pagination
                            page={paginationInfo.page}
                            limit={paginationInfo.limit}
                            nextPage={paginationInfo.nextPage}
                            prevPage={paginationInfo.prevPage}
                            totalPages={paginationInfo.totalPages}
                            loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                                loadPage({
                                    query: currentQuery.query,
                                    limit: currentQuery.limit,
                                    ...pageLoadInfo
                                })
                            }}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    )
}
