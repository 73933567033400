import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { generateStandardNetworkState, standardNetworkErrorCall, standardNetworkPendingCall, StandardNetworkState } from "../reduxHelperFunctions";
import { RootState } from "../store";

interface RegisterUserState  extends StandardNetworkState {}

const generateEmptyRegisterUserState = (): RegisterUserState => {
  return {
    ...generateStandardNetworkState()
  }
}

const initialState: RegisterUserState = generateEmptyRegisterUserState()

export const registerNewUser = createAsyncThunk(
  'registerUser/registerNewUserCall',
  async (newUserInfo: any, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().registerUser
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const registerResponse = await thunkApi.extra.networkRequest.registerNewUser(newUserInfo)
    if (registerResponse.error) {
      return thunkApi.rejectWithValue(registerResponse)
    }
    if (registerResponse) {
      return newUserInfo.email
    }
    return rejectWithValue('Something went wrong')
  }
)


export const registerUserSlice = createSlice({
  name: 'registerUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // REGISTER USER
    builder.addCase(registerNewUser.pending, standardNetworkPendingCall('Attempting to create user'))
    builder.addCase(registerNewUser.rejected, standardNetworkErrorCall())
    builder.addCase(registerNewUser.fulfilled, (state, action: any) => {
      return {
        ...state,
        loading: 'idle',
        message: `User ${action.payload} created successfully`,
        error: null
      }
    })
  }
})


export const registerUserSelector = createSelector((state: RootState) => state, (state) => state.registerUser)


export default registerUserSlice.reducer
