import { Col, Container, Row } from "react-bootstrap";
import DataImporter from "../../../Components/Data/DataImporter/DataImporter";
import { useAppDispatch } from "../../../redux/store";
import timeService from "../../../services/time/timeService";
import { bulkEditPropertyPrices, importPropertyPrices, loosePropertyPricesQuery } from "../../../redux/generalNetwork/generalNetworkSlice";
import { useParams } from "react-router-dom";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";

export default function ImportPropertyPricesPage() {

    const dispatch = useAppDispatch()
    // const propertyId = '5d56d4ee99b6ff0015269a0c' // meridian 6
    // const propertyId = '5d56d37799b6ff00152699c9' // meridian 8
    // const propertyId = '5d10ec01a60d3400154ed34b' // 21008

    const {
        propertyId = '1234567890',
    } = useParams()

    const {
        myProperties = {}
    } = useMyProperties()

    const propertyInfo: any = myProperties[propertyId] || {}

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Import Property Prices</h1>
                    <hr />
                    <h3>{propertyInfo.title}</h3>
                    <DataImporter
                        importFn={async (data: any[]) => {
                            console.log('import', data)
                            const importRef: { [k: string]: any } = {}
                            const x: any[] = data.map((x: any) => {
                                const [year, month, day] = x['Date'].split('-')
                                const dateObject = {
                                    year: +year,
                                    month: +month,
                                    day: +day,
                                    hour: 16,
                                    minute: 0
                                }
                                const dateMillis = timeService.createLuxonDateFromObject(dateObject).toMillis()
                                const jsParsedDate = new Date(dateMillis)
                                const updatedObject = {
                                    ...x,
                                    date: jsParsedDate
                                }
                                if (!importRef[x['Date']] && x['Final Price']) {
                                    importRef[x['Date']] = {
                                        propertyId: propertyId,
                                        price: +x['Final Price'],
                                        date: jsParsedDate,
                                    }
                                }
                                return updatedObject
                            })

                            const startDateString = x[0]['Date']
                            const endDateString = x[x.length - 1]['Date']
                            const startDate = timeService.createLuxonDate(startDateString).startOf('day').toMillis()
                            const endDate = timeService.createLuxonDate(endDateString).endOf('day').toMillis()
                            const pricesQuery = {
                                query: {
                                    propertyId: propertyId,
                                    dateStart: startDate,
                                    dateEnd: endDate
                                },
                                page: 1,
                                limit: 500,
                                all: true
                            }
                            const existingPrices = await dispatch(loosePropertyPricesQuery(pricesQuery)).unwrap()
                            const priceRef: { [k: string]: any } = {}
                            if (existingPrices?.docs) {
                                existingPrices.docs.forEach((x: any) => {
                                    const dateString = timeService.createLuxonDate(x.date).toFormat('yyyy-MM-dd')
                                    priceRef[dateString] = x
                                })
                            }
                            console.log(priceRef)
                            console.log(`prices from ${startDateString} to ${endDateString}`, existingPrices)

                            const newPrices: any[] = []
                            const editPrices: any[] = []

                            Object.keys(importRef).forEach((x: any) => {
                                if (priceRef[x]) {
                                    editPrices.push({
                                        ...priceRef[x],
                                        price: importRef[x].price
                                    })
                                } else {
                                    newPrices.push(importRef[x])
                                }
                            })

                            console.log('new prices', newPrices)
                            console.log('edit prices', editPrices)
                            console.log('===============================================')

                            if (newPrices.length > 0) {
                                await dispatch(importPropertyPrices(newPrices))
                            }

                            if (editPrices.length > 0) {
                                await dispatch(bulkEditPropertyPrices(editPrices))
                            }

                            return {
                                success: true,
                            }

                        }}
                        bulkEditFn={async (x: any) => {
                            // return await dispatch(bulkEditProperties(x))
                            console.log('bulk edit', x)
                        }}
                        complexSplitFields={[]}
                        entryRenderFn={entryRenderer}
                        validationFn={(x: any) => {
                            // const validEmail = email ? email.toLowerCase() : 'nothing'
                            // let foundEmail = false;
                            const errors: any[] = []
                            // if (!foundEmail) {
                            //     errors.push('your email was not found for in this property')
                            // }
                            return errors
                        }}
                        processEntryFn={(x: any) => {
                            const dataEntry: any = {
                                // title: x.title,
                                // description: x.description,
                                ...x
                            }
                            return dataEntry
                        }}
                    />
                </Col>
            </Row>
        </Container>
    )
}

const entryRenderer = (entry: any) => {
    return (
        <pre>
            {JSON.stringify(entry, null, 2)}
        </pre>
    )
}