import { createAsyncThunk, createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardPaginatedBuilder, generateStandardNetworkPaginatedState, StandardPaginatedNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface InquiriesState extends StandardPaginatedNetworkState {
  inquiriesList: any[] | null
}

const generateEmptyInquiriesState = (): InquiriesState => {
  return {
    ...generateStandardNetworkPaginatedState(),
    inquiriesList: null,
  }
}

export const getInquiriesAction = createAsyncThunk(
  'inquiries/getInquiriesActionCall',
  async (queryInfo: {query: any, page: number, limit: number, all?: boolean}, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().inquiries
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const inquiriesList = await thunkApi.extra.networkRequest.getInquiries(queryInfo.query, {page: queryInfo.page, limit: queryInfo.limit, all: queryInfo.all})
    if (inquiriesList.error) {
      return rejectWithValue({
        ...inquiriesList,
        currentQuery: queryInfo
      })
    }
    const {docs, ...paginationInfo} = inquiriesList
    return {
      docs,
      paginationInfo,
      currentQuery: queryInfo
    }
  }
)

const initialState: InquiriesState = generateEmptyInquiriesState()

export const inquiriesSlice = createSlice({
  name: 'inquiries',
  initialState,
  reducers: {
    clearInquiries: (state, action: PayloadAction) => {
      return {
        ...generateEmptyInquiriesState()
      }
    }
  },
  extraReducers: (builder) => {
    generalStandardPaginatedBuilder(builder, getInquiriesAction, 'inquiriesList')
  }
})

export const { clearInquiries } = inquiriesSlice.actions

export const inquiriesSelector = createSelector((state: RootState) => state, (state) => state.inquiries)

export default inquiriesSlice.reducer
