import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import BookingRequestForm from "../../../Components/Forms/BookingRequestForm/BookingRequestForm";
import { addNewBookingRequest, bookingRequestSelector } from "../../../redux/bookingRequest/bookingRequestSlice";
import { clearBookingRequests } from "../../../redux/bookingRequests/bookingRequestsSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";

export default function AddBookingRequestPage() {
  const {
    // loading,
    error,
    message,
  } = useSelector(bookingRequestSelector)
  const dispatch = useAppDispatch()
  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if(!values.created) {
      values.created = Date.now()
    }
    const newBookingRequest = await dispatch(addNewBookingRequest({bookingRequestInfo: values})).unwrap()
    if (newBookingRequest._id) {
      dispatch(navigateTo(`/booking-request/${newBookingRequest._id}`))
    }
    dispatch(clearBookingRequests())
    return newBookingRequest
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add Booking Request</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          <BookingRequestForm
            initialValues={{
                guestName: '',
            }}
            disabled={false}
            onSubmit={handleSubmit}
          />

        </Col>
      </Row>
    </Container>
  )
}
