import { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Stack } from 'react-bootstrap'
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { clearPropertyTask, deletePropertyTask, getPropertyTaskInfo, propertyTaskSelector } from '../../../redux/propertyTask/propertyTaskSlice'
import { clearPropertyTasks } from '../../../redux/propertyTasks/propertyTasksSlice'
import { navigateTo } from '../../../redux/router/routerActions'
import { useAppDispatch } from "../../../redux/store"

export default function DeletePropertyTaskPage() {
    const { _id, propertyTaskData, message, loading, error } = useSelector(propertyTaskSelector)
    const { propertyTaskId } = useParams()
    const dispatch = useAppDispatch()

    const [deleteClicked, setDeleteClicked] = useState(false)

    useEffect(() => {
        if (loading !== 'pending' && propertyTaskId && _id !== propertyTaskId) {
            dispatch(getPropertyTaskInfo(propertyTaskId))
        }
        return () => { }
    }, [propertyTaskId, _id, dispatch, loading])

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Delete Property Task Page</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {message && (<>{message}<br /></>)}

                    {propertyTaskId && propertyTaskId === _id && propertyTaskData && (
                        <Stack gap={3}>
                            <div>Are you sure you want to delete property task: {_id}</div>

                            <pre className="propertyTask-info">
                                {JSON.stringify(propertyTaskData, null, 2)}
                            </pre>

                            <div>
                                <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete Property Task</Button>
                            </div>

                            {deleteClicked && (
                                <div>
                                    Once you delete this property it will be gone forever. <br />
                                    <Button variant={'danger'} disabled={loading === 'pending'} onClick={async () => {
                                        const deleteValue = await dispatch(deletePropertyTask({ _id: _id })).unwrap()
                                        if (deleteValue) {
                                            dispatch(clearPropertyTasks())
                                            dispatch(clearPropertyTask())
                                        }
                                        dispatch(navigateTo(`/property-tasks/`))
                                    }}> Confirm Delete Property </Button>
                                </div>
                            )}
                        </Stack>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
