import { useEffect, useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { IcalEntry } from "../../../Components/Icals/IcalEntry/IcalEntry"
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook"
import { getPropertyIcalData, icalDataSelector } from "../../../redux/icalData/icalDataSlice"
import { useAppDispatch } from "../../../redux/store"
import useBookingQuery from "../../../Hooks/UseBookingQuery/UseBookingQueryHook"
import { UseIcalsDecorated } from "../../../Hooks/UseIcals/UseIcalsDecorated"
import timeService from "../../../services/time/timeService"
import { importBookings } from "../../../redux/generalNetwork/generalNetworkSlice"
import { navigateTo } from "../../../redux/router/routerActions"
import { clearBookings } from "../../../redux/bookings/bookingsSlice"
import { convertIcalToBookings } from "../../../Helpers/Icals/IcalHelpers"
import UseIcalsFilter from "../../../Hooks/UseIcals/UseIcalsFilter"
import IcalFilterForm from "../../../Components/Forms/IcalFilterForm/IcalFilterForm"
import { IcalEntryType } from "../../../Types/icalEntryType"

export default function IcalPage() {
    const { propertyId } = useParams()
    const dispatch: any = useAppDispatch()
    const { icalData, loading, error, message } = useSelector(icalDataSelector)
    const { myProperties } = useMyProperties()
    const startOfYesterday = timeService.getCurrentTime().minus({ days: 1 }).startOf('day').toMillis()
    const activeQuery = {
        query: {
            checkIn: startOfYesterday,
            propertyId,
            bookingStatus: ['confirmed', 'pending', 'new']
        },
        limit: 500,
        page: 1
    }
    const { bookingsList } = useBookingQuery(activeQuery)

    const [icalFilter, setIcalFilter]: any = useState({
        checkIn: new Date(startOfYesterday),
        checkOut: null,
        source: '',
        hideExisting: true,
        hideBlocked: true,
    })

    const decoratedIcalBookings = UseIcalsDecorated({
        icalData,
        bookingsList,
        myProperties,
        propertyId
    })

    useEffect(() => {
        if (loading !== 'pending' && propertyId && !icalData[propertyId]) {
            dispatch(getPropertyIcalData(propertyId))
        }
        return () => { }
    }, [propertyId, icalData, loading, dispatch])


    const filteredIcalData = UseIcalsFilter(decoratedIcalBookings, icalFilter)


    const convertIcalBooking = async (iCalEntry: any) => {
        const bookingsToAdd = convertIcalToBookings(iCalEntry, myProperties)
        const newBookings = await dispatch(importBookings(bookingsToAdd)).unwrap()
        if (newBookings && newBookings.acknowledged) {
            dispatch(navigateTo(`/bookings?query={"firstName":"${bookingsToAdd[0].contactInfo?.firstName}","lastName":"${bookingsToAdd[0].contactInfo?.lastName}"}`))
        }
        dispatch(clearBookings())
    }

    const updateIcalFilter = (values: any) => {
        setIcalFilter({
            ...values,
        })
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Ical Page</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}

                    {myProperties && propertyId !== undefined && myProperties[propertyId] && (<>
                        <h3>{myProperties[propertyId].title}</h3>
                    </>)}

                    {loading === 'pending' ? (
                        <>
                            {message} <br />
                        </>
                    ) : (
                        <Button
                            size={'sm'}
                            variant={'warning'}
                            onClick={() => {
                                if (propertyId) {
                                    dispatch(getPropertyIcalData(propertyId))
                                }
                            }}>
                            Hard Refresh
                        </Button>
                    )}

                    {bookingsList && bookingsList.length > 0 && (
                        <div>{bookingsList.length} booking</div>
                    )}

                    <h3>Ical Bookings</h3>
                    <IcalFilterForm
                        checkIn={icalFilter.checkIn}
                        checkOut={icalFilter.checkOut ? icalFilter.checkOut : null}
                        hideExisting={icalFilter.hideExisting}
                        hideBlocked={icalFilter.hideBlocked}
                        source={icalFilter.source}
                        onSubmit={updateIcalFilter}
                    />

                    {filteredIcalData && filteredIcalData.length > 0 && (
                        <>
                            <Stack gap={3}>
                                {filteredIcalData.map((icalInfo: IcalEntryType, idx: number) => (
                                    <IcalEntry
                                        key={idx}
                                        icalData={icalInfo}
                                        convertIcalBooking={convertIcalBooking}
                                    />
                                ))}
                            </Stack>
                        </>
                    )}

                    {/* {propertyId && icalData[propertyId] && icalData[propertyId].length > 0 && (
                        <>
                            <h3>All Ical Bookings</h3>
                            <Stack gap={3}>
                                {icalData[propertyId].map((icalInfo: any, idx: number) => (
                                    <IcalEntry key={idx} icalData={icalInfo} />
                                ))}
                            </Stack>
                        </>
                    )} */}
                </Col>
            </Row>
        </Container>
    )
}