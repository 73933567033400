import { Col, Container, Row } from "react-bootstrap";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import { useAppDispatch } from "../../../redux/store";
import timeService, { DATE_FORMAT } from "../../../services/time/timeService";
import { useMemo, useState } from "react";
import useBookingQuery from "../../../Hooks/UseBookingQuery/UseBookingQueryHook";
import { BookingEntry } from "../../../Components/Bookings/BookingEntry/BookingEntry";
import useBookingGuestQuery from "../../../Hooks/UseBookingGuestQuery/useBookingGuestQueryHook";
import BookingGuestEntry from "../../../Components/BookingGuests/BookingGuestEntry/BookingGuestEntry";
import { clearBooking, editBooking } from "../../../redux/booking/bookingSlice";
import { clearBookings } from "../../../redux/bookings/bookingsSlice";
import { clearBookingGuests } from "../../../redux/bookingGuests/bookingGuestsSlice";
import { generalNetworkSelector, sendBookingGuestEmail } from "../../../redux/generalNetwork/generalNetworkSlice";
import { useSelector } from "react-redux";
import QuickForm from "../../../Components/Forms/QuickForm/QuickForm";
import useCheckRole from "../../../Hooks/UseCheckRole/UseCheckRoleHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import canAccess from "../../../services/accessService/accessService";
import AccessClickButton from "../../../Components/Buttons/AccessClickButton";
import { editBookingGuest } from "../../../redux/bookingGuest/bookingGuestSlice";
import { PropertyBookingEntry } from "../../../Types/bookingTypes";
import { BookingGuestEntryType } from "../../../Types/bookingGuestTypes";
import { confirmDialog } from "../../../Helpers/Dialogs/confirmDialog";
import { FieldGeneratorFieldType } from "../../../Components/FormFields/FieldGenerator/FieldGenerator";

export default function BookingGuestConfirmationPage() {

    const isAdminRole = useCheckRole('admin')

    const todayString = timeService.getCurrentTime().toFormat(DATE_FORMAT)

    const [mode, setMode] = useState<'guest' | 'booking'>(canAccess('bookingGuest', 'mode.booking') ? 'booking' : 'guest')

    const generalNetworkState = useSelector(generalNetworkSelector)

    const {
        sendBookingGuestEmail: sendBookingGuestEmailState
    } = generalNetworkState

    const { myProperties } = useMyProperties()
    const propertyOptions = useMyPropertiesSelectValues(myProperties)


    const dispatch = useAppDispatch()

    // START BOOKING QUERY STUFF
    const startOfToday = timeService.getCurrentTime().startOf('day').toMillis()
    const queryEndDate = timeService.getCurrentTime().plus({ weeks: 2 }).startOf('day').toMillis()
    const [
        activeBookingQuery,
        setActiveBookingQuery
    ] = useState<any>({
        query: {
            checkIn: startOfToday,
            checkOut: queryEndDate,
            bookingStatus: ['new', 'pending', 'confirmed'],
            requiresBookingGuestInfo: true,
            bookingGuestInfoId: ['', null],
            ...(isAdminRole ? {} : { propertyId: Object.keys(myProperties || {}) }),
        },
        limit: 500,
        page: 1
    })
    const {
        loading: bookingsLoading,
        // error,
        // currentQuery,
        currentRequestId,
        // message,
        bookingsList,
    } = useBookingQuery(activeBookingQuery)
    // END BOOKING QUERY STUFF

    // START BOOKING GUEST INFO QUERY STUFF
    const [
        activeBookingGuestInfoQuery,
        setActiveBookingGuestInfoQuery
    ] = useState<any>({
        query: {
            // status: ['new', 'pending'],
            status: 'confirmed',
            arrivalTimeStart: startOfToday,
            ...(isAdminRole ? {} : { propertyId: Object.keys(myProperties || {}) }),
        },
        limit: 500,
        page: 1
    })
    const {
        loading: bookingGuestsListLoading,
        // error,
        // currentQuery,
        // message,
        bookingGuestsList,
    } = useBookingGuestQuery(activeBookingGuestInfoQuery)
    // END BOOKING GUEST INFO QUERY STUFF

    const bookingGuestsByBookingId: any = {}
    if (bookingGuestsList) {
        bookingGuestsList.forEach((bookingGuestInfo: any) => {
            if (bookingGuestInfo.bookingId) {
                bookingGuestsByBookingId[bookingGuestInfo.bookingId] = bookingGuestInfo
            }
        })
    }

    const changeMode = (newMode: 'guest' | 'booking') => {
        if (newMode === mode) {
            return
        }
        setMode(newMode)
    }

    const updateActiveBookingQuery = (queryInfo: any) => {
        setActiveBookingQuery({
            ...activeBookingQuery,
            query: {
                ...activeBookingQuery.query,
                ...queryInfo
            }
        })
    }

    const updateActiveBookingGuestInfoQuery = (queryInfo: any) => {
        setActiveBookingGuestInfoQuery({
            ...activeBookingGuestInfoQuery,
            query: {
                ...activeBookingGuestInfoQuery.query,
                ...queryInfo
            }
        })
    }

    const handleFormSubmit = (values: any) => {
        const newBookingQuery: any = {}
        const newGuestInfoQuery: any = {}

        newBookingQuery.propertyId = values.propertyId
        newGuestInfoQuery.propertyId = values.propertyId

        // handle checkIn and checkOut
        newBookingQuery.checkIn = timeService.dateToMillis(values.checkIn)
        newBookingQuery.checkOut = timeService.dateToMillis(values.checkOut)

        newGuestInfoQuery.arrivalTimeStart = timeService.dateToMillis(values.checkIn)

        newBookingQuery.firstName = values.firstName
        newBookingQuery.lastName = values.lastName

        newGuestInfoQuery.firstName = values.firstName
        newGuestInfoQuery.lastName = values.lastName

        updateActiveBookingQuery(newBookingQuery)
        updateActiveBookingGuestInfoQuery(newGuestInfoQuery)
    }

    const dataByDay = useMemo(() => {
        const dataByDayList: any[] = []

        const dateRangeHash = timeService.createDateHashObject(
            activeBookingQuery.query.checkIn,
            activeBookingQuery.query.checkOut
        )

        if (bookingsLoading !== 'pending' && bookingsList) {
            bookingsList.forEach((bookingInfo: PropertyBookingEntry) => {
                const formattedCheckIn = timeService.getFormattedTime(bookingInfo.checkIn, DATE_FORMAT)
                if (!dateRangeHash[formattedCheckIn]) {
                    return
                }
                if (!dateRangeHash[formattedCheckIn].bookings) {
                    dateRangeHash[formattedCheckIn].bookings = []
                }
                dateRangeHash[formattedCheckIn].bookings.push(bookingInfo)
            })
        }

        if (bookingGuestsListLoading !== 'pending' && bookingGuestsList) {
            bookingGuestsList.forEach((bookingGuestInfo: BookingGuestEntryType) => {
                const formattedCheckIn = timeService.getFormattedTime(bookingGuestInfo.arrivalTime, DATE_FORMAT)
                // remove if property does not require bookingGuestInfo
                if(myProperties[bookingGuestInfo.propertyId] && !myProperties[bookingGuestInfo.propertyId].services?.bookingGuestInfo) {
                    return
                }
                if (!dateRangeHash[formattedCheckIn]) {
                    return
                }
                if (!dateRangeHash[formattedCheckIn].bookingGuests) {
                    dateRangeHash[formattedCheckIn].bookingGuests = []
                }
                dateRangeHash[formattedCheckIn].bookingGuests.push(bookingGuestInfo)
            })
        }

        Object.keys(dateRangeHash).forEach((dateKey: string) => {
            dataByDayList.push(dateRangeHash[dateKey])
        })

        return dataByDayList
    }, [bookingGuestsList, bookingGuestsListLoading, bookingsList, bookingsLoading, activeBookingQuery.query.checkIn, activeBookingQuery.query.checkOut])

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Booking Guest Confirmation</h1>
                    <hr />

                    <QuickForm
                        key={currentRequestId}
                        initialData={{
                            // name: activeQuery.query.name,
                            propertyId: activeBookingQuery.query.propertyId,
                            dateRange: null,
                            checkIn: activeBookingQuery.query.checkIn,
                            checkOut: activeBookingQuery.query.checkOut,
                            firstName: activeBookingQuery.query.firstName,
                            lastName: activeBookingQuery.query.lastName,
                        }}
                        fields={bookingGuestSearchFields(propertyOptions)}
                        disabled={bookingsLoading === 'pending' || bookingGuestsListLoading === 'pending'}
                        onSubmit={handleFormSubmit}
                    />

                    <hr />

                    <div>
                        <AccessClickButton
                            variant={mode === 'booking' ? 'primary' : 'secondary'}
                            onClick={() => changeMode('booking')}
                            className="mb-2 me-2"
                            text={"Needs Info"}
                            action={'needsInfo'}
                            resource={'bookingGuest'}
                        />


                        <AccessClickButton
                            variant={mode === 'guest' && activeBookingGuestInfoQuery.query.status === 'new' ? 'primary' : 'secondary'}
                            onClick={() => {
                                changeMode('guest')
                                updateActiveBookingGuestInfoQuery({
                                    status: 'new'
                                })
                            }}
                            className="mb-2 me-2"
                            text={"Pending Send"}
                            action={'pendingSend'}
                            resource={'bookingGuest'}
                        />

                        <AccessClickButton
                            variant={mode === 'guest' && activeBookingGuestInfoQuery.query.status === 'pending' ? 'primary' : 'secondary'}
                            onClick={() => {
                                changeMode('guest')
                                updateActiveBookingGuestInfoQuery({
                                    status: 'pending'
                                })
                            }}
                            className="mb-2 me-2"
                            text={"Pending Confirm"}
                            action={'pendingConfirm'}
                            resource={'bookingGuest'}
                        />

                        <AccessClickButton
                            variant={mode === 'guest' && activeBookingGuestInfoQuery.query.status === 'confirmed' ? 'primary' : 'secondary'}
                            onClick={() => {
                                changeMode('guest')
                                updateActiveBookingGuestInfoQuery({
                                    status: 'confirmed'
                                })
                            }}
                            className="mb-2 me-2"
                            text={"Confirmed"}
                            action={'confirmed'}
                            resource={'bookingGuest'}
                        />
                    </div>
                    <hr />


                    {canAccess('bookingGuest', 'mode.booking') && mode === 'booking' && bookingsLoading !== 'pending' && bookingsList && bookingsList.length > 0 && (
                        <div>
                            {dataByDay.map((dayData: any, idx: number) => {
                                if (!dayData.bookings) return null
                                return (
                                    <div className="" key={`booking-day-${idx}-${dayData.timeStamp}`}>
                                        <h3 style={stickyStyle}>
                                            <b>{dayData.date === todayString ? 'TODAY' : ''}</b> {timeService.getFormattedTime(dayData.timeStamp, 'LL/dd/yyyy cccc')}
                                        </h3>
                                        <hr />
                                        {dayData.bookings && dayData.bookings.map((bookingEntry: any, idx: number) => (
                                            <BookingEntry
                                                key={`${bookingEntry._id}-${idx}`}
                                                bookingInfo={bookingEntry}
                                                propertyName={myProperties[bookingEntry.propertyId]?.title}
                                                extraButtons={bookingGuestsByBookingId[bookingEntry._id] ? [
                                                    {
                                                        label: 'Link Booking Guest',
                                                        onClick: async () => {
                                                            await dispatch(editBooking({
                                                                _id: bookingEntry._id,
                                                                bookingInfo: {
                                                                    bookingGuestInfoId: bookingGuestsByBookingId[bookingEntry._id]._id
                                                                }
                                                            })).unwrap()
                                                            dispatch(clearBookings())
                                                            dispatch(clearBooking())
                                                            dispatch(clearBookingGuests())
                                                        }
                                                    }
                                                ] : []}
                                            />
                                        ))}
                                    </div>
                                )
                            })}
                        </div>
                    )}

                    {mode === 'guest' && bookingGuestsListLoading !== 'pending' && bookingGuestsList && bookingGuestsList.length > 0 && (
                        <div>
                            {dataByDay.map((dayData: any, idx: number) => {
                                if (!dayData.bookingGuests) return null
                                return (
                                    <div key={`booking-guest-day-${idx}-${dayData.timeStamp}`}>
                                        <h3 style={stickyStyle}>
                                            <b>{dayData.date === todayString ? 'TODAY' : ''}</b> {timeService.getFormattedTime(dayData.timeStamp, 'LL/dd/yyyy cccc')}
                                        </h3>
                                        <hr />
                                        {dayData.bookingGuests && dayData.bookingGuests.map((bookingGuestEntry: any, idx: number) => {
                                            const extraButtons = []

                                            if (bookingGuestEntry.status === 'new' && canAccess('bookingGuest', 'markPending')) {
                                                extraButtons.push({
                                                    label: 'Mark Pending',
                                                    disabled: sendBookingGuestEmailState.loading === 'pending' ? true : false,
                                                    variant: 'secondary',
                                                    onClick: async () => {
                                                        const sendConfirm = await confirmDialog('Are you sure you sure all of this guest information is correctr?')
                                                        if (!sendConfirm) return
                                                        console.log('attempting to pending')
                                                        const updatedBookingGuest = await dispatch(editBookingGuest({
                                                            _id: bookingGuestEntry._id,
                                                            bookingGuestInfo: {
                                                                status: 'pending'
                                                            }
                                                        })).unwrap()
                                                        console.log('updatedBookingGuest', updatedBookingGuest)
                                                        dispatch(clearBookings())
                                                        dispatch(clearBooking())
                                                        dispatch(clearBookingGuests())
                                                    }
                                                })
                                            }

                                            if (bookingGuestEntry.status === 'new' && canAccess('bookingGuest', 'sendConfirm')) {
                                                extraButtons.push({
                                                    label: '✉️ the 🦃',
                                                    disabled: sendBookingGuestEmailState.loading === 'pending' ? true : false,
                                                    variant: 'primary',
                                                    onClick: async () => {
                                                        const sendConfirm = await confirmDialog('Are you sure you want to send the confirmation email?')
                                                        if (!sendConfirm) return
                                                        console.log('attempting to send confirmation email')
                                                        const mailInfo = await dispatch(sendBookingGuestEmail({
                                                            bookingGuestId: bookingGuestEntry._id
                                                        })).unwrap()
                                                        console.log('mailInfo', mailInfo)
                                                        dispatch(clearBookings())
                                                        dispatch(clearBooking())
                                                        dispatch(clearBookingGuests())
                                                    }
                                                })
                                            }

                                            if (bookingGuestEntry.status === 'pending' && canAccess('bookingGuest', 'markConfirm')) {
                                                extraButtons.push({
                                                    label: 'Confirm Guest',
                                                    disabled: sendBookingGuestEmailState.loading === 'pending' ? true : false,
                                                    variant: 'success',
                                                    onClick: async () => {
                                                        const sendConfirm = await confirmDialog('Are you sure you want to confirm this guest?')
                                                        if (!sendConfirm) return
                                                        console.log('attempting to confirm')
                                                        const updatedBookingGuest = await dispatch(editBookingGuest({
                                                            _id: bookingGuestEntry._id,
                                                            bookingGuestInfo: {
                                                                status: 'confirmed'
                                                            }
                                                        })).unwrap()
                                                        console.log('updatedBookingGuest', updatedBookingGuest)
                                                        dispatch(clearBookings())
                                                        dispatch(clearBooking())
                                                        dispatch(clearBookingGuests())
                                                    }
                                                })
                                            }

                                            if (bookingGuestEntry.status === 'pending' && canAccess('bookingGuest', 'markDenied')) {
                                                extraButtons.push({
                                                    label: 'Deny Guest',
                                                    disabled: sendBookingGuestEmailState.loading === 'pending' ? true : false,
                                                    variant: 'danger',
                                                    onClick: async () => {
                                                        const sendConfirm = await confirmDialog('Are you sure you want to deny this guest?')
                                                        if (!sendConfirm) return
                                                        console.log('attempting to deny')
                                                        const updatedBookingGuest = await dispatch(editBookingGuest({
                                                            _id: bookingGuestEntry._id,
                                                            bookingGuestInfo: {
                                                                status: 'new'
                                                            }
                                                        })).unwrap()
                                                        console.log('updatedBookingGuest', updatedBookingGuest)
                                                        dispatch(clearBookings())
                                                        dispatch(clearBooking())
                                                        dispatch(clearBookingGuests())
                                                    }
                                                })
                                            }

                                            return (
                                                <BookingGuestEntry
                                                    key={idx}
                                                    bookingGuestInfo={bookingGuestEntry}
                                                    propertyData={myProperties[bookingGuestEntry.propertyId] ? myProperties[bookingGuestEntry.propertyId] : undefined}
                                                    // options={{}}
                                                    extraButtons={extraButtons}
                                                />
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}

const stickyStyle: any = {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 10,
    paddingTop: '10px',
    paddingBottom: '10px',
}

const bookingGuestSearchFields = (propertyOptions: { value: string | number, text?: string }[]): FieldGeneratorFieldType[] => ([
    {
        fieldName: 'propertyId',
        fieldLabel: 'Property',
        fieldType: 'select',
        placeholder: '- All Properties -',
        options: propertyOptions,
    },
    {
        fieldName: 'dateRange',
        fieldLabel: 'Date Range',
        fieldType: 'dateRange',
        placeholder: 'mm/dd/yyyy - mm/dd/yyyy',
        extra: {
            startDate: 'checkIn',
            endDate: 'checkOut',
        },
    },
    {
        fieldName: 'checkIn',
        fieldLabel: 'Check In',
        fieldType: 'date',
        placeholder: 'Check In',
        required: true,
        hidden: true,
    },
    {
        fieldName: 'checkOut',
        fieldLabel: 'Check In',
        fieldType: 'date',
        placeholder: 'Check In',
        required: true,
        hidden: true,
    },
    {
        fieldName: 'firstName',
        fieldLabel: 'First Name',
        fieldType: 'text',
        placeholder: 'First Name',
    },
    {
        fieldName: 'lastName',
        fieldLabel: 'Last Name',
        fieldType: 'text',
        placeholder: 'Last Name',
    }
])