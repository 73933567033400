import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import MediaFileForm from "../../../Components/Forms/MediaFileForm/MediaFileForm";
import { mediaFileSelector, editMediaFile, getMediaFileInfo } from "../../../redux/mediaFile/mediaFileSlice";
import { clearMediaFiles } from "../../../redux/mediaFiles/mediaFilesSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";

export default function EditMediaFilePage() {

  const {
    uploadImageFn,
  } = useUploadImage()

  const { _id, mediaFileData, message, loading, error } = useSelector(mediaFileSelector)
  const { mediaFileId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && mediaFileId && _id !== mediaFileId) {
      dispatch(getMediaFileInfo(mediaFileId))
    }
    return () => { }
  }, [mediaFileId, _id, dispatch, loading, mediaFileData])


  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (_id) {
      const editedMediaFile = await dispatch(editMediaFile({ _id, mediaFileInfo: values })).unwrap()
      if (editedMediaFile._id) {
        dispatch(navigateTo(`/mediaFile/${editedMediaFile._id}`))
      }
      dispatch(clearMediaFiles())
      return editedMediaFile
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit MediaFile</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          {mediaFileId && mediaFileId === _id && mediaFileData && (
            <MediaFileForm
              disabled={false}
              initialValues={mediaFileData}
              onSubmit={handleSubmit}
              uploadImageFn={uploadImageFn}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
