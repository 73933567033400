import { Col, Container, Row } from "react-bootstrap"
import { BookingEntry } from "../../Components/Bookings/BookingEntry/BookingEntry"
import BookingSearchForm from "../../Components/Forms/BookingSearchForm/BookingSearchForm"
import Pagination from "../../Components/Pagination/Pagination"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers"
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook"
import useBookingQuery from "../../Hooks/UseBookingQuery/UseBookingQueryHook"
import useMyProperties from "../../Hooks/UseMyProperties/UseMyPropertiesHook"
import useMyPropertiesSelectValues from "../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import './bookingsPage.scss'

export default function BookingsPage() {

  const dispatch = useAppDispatch()
  const { myProperties } = useMyProperties()
  const propertyOptions = useMyPropertiesSelectValues(myProperties)

  const { activeQuery } = useActiveQueryHook({
    limit: 10
  })

  const {
    loading,
    error,
    currentRequestId,
    paginationInfo,
    currentQuery,
    message,
    bookingsList,
  } = useBookingQuery(activeQuery)

  const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
    const destination = `/bookings?${convertActiveQueryToUrl(pageParams)}`
    dispatch(navigateTo(destination))
  }


  return (
    <Container>
      <Row>
        <Col>
          <h1>Bookings</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}

          <div className="w-full my-2 small fst-italic">
            NOTE: Bot runs every 5 minutes. If you don't see your booking, it may not have been processed yet.
          </div>

          <BookingSearchForm
            key={currentRequestId}
            disabled={loading === 'pending'}
            onSubmit={(values: any) => {
              loadPage({
                query: values,
                page: 1,
                limit: currentQuery.limit
              })
            }}
            checkIn={activeQuery.query.checkIn}
            checkOut={activeQuery.query.checkOut}
            firstName={activeQuery.query.firstName}
            lastName={activeQuery.query.lastName}
            propertyId={activeQuery.query.propertyId}
            propertyOptionList={propertyOptions}
            sourceConfirmation={activeQuery.query.sourceConfirmation}
            confirmationCode={activeQuery.query.confirmationCode}
            source={activeQuery.query.source}
            bookingStatus={activeQuery.query.bookingStatus}
            dateBookedStart={activeQuery.query.dateBookedStart}
            dateBookedEnd={activeQuery.query.dateBookedEnd}
          />

          {paginationInfo && (
            <div className="p-2">
              {paginationInfo.totalDocs} entries
            </div>
          )}

          <div className="booking-list-container">
            {bookingsList && bookingsList.length > 0 && bookingsList.map((bookingEntry, idx) => (
              <BookingEntry
                key={`${bookingEntry._id}-${idx}`}
                bookingInfo={bookingEntry}
                propertyName={myProperties[bookingEntry.propertyId]?.title}
              />
            ))
            }
          </div>

          {paginationInfo && (
            <Pagination
              totalDocs={paginationInfo.totalDocs}
              page={paginationInfo.page}
              limit={paginationInfo.limit}
              nextPage={paginationInfo.nextPage}
              prevPage={paginationInfo.prevPage}
              totalPages={paginationInfo.totalPages}
              loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                loadPage({
                  query: currentQuery.query,
                  limit: currentQuery.limit,
                  ...pageLoadInfo
                })
              }}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}
