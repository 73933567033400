import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardCrudBuilder, generateStandardNetworkState, StandardNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface BuildingState extends StandardNetworkState {
  _id: string | null
  buildingData: any
}

const generateEmptyBuildingState = (): BuildingState => {
  return {
    _id: '',
    buildingData: {},
    ...generateStandardNetworkState()
  }
}

const initialState = generateEmptyBuildingState()

export const getBuildingInfo = createAsyncThunk(
  'building/getBuildingInfoCall',
  async (buildingId: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().building
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const buildingData = await thunkApi.extra.networkRequest.getBuildingById(buildingId)
    if (buildingData.error) {
      return rejectWithValue({
        ...buildingData,
        _id: buildingId
      })
    }
    return {
      _id: buildingId,
      buildingData
    }
  }
)

export const addNewBuilding = createAsyncThunk(
  'building/addNewBuildingCall',
  async (buildingPayload: { buildingInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().building
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { buildingInfo } = buildingPayload
    const buildingData = await thunkApi.extra.networkRequest.addNewBuilding(buildingInfo)
    if (buildingData.error) {
      return rejectWithValue({
        ...buildingData,
        _id: 999
      })
    }
    return {
      _id: buildingData._id,
      buildingData: {
        ...buildingData
      }
    }
  }
)

export const editBuilding = createAsyncThunk(
  'building/editBuildingCall',
  async (buildingPayload: { _id: string, buildingInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().building
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id, buildingInfo } = buildingPayload
    const buildingData = await thunkApi.extra.networkRequest.editBuilding(_id, buildingInfo)
    if (buildingData.error) {
      return rejectWithValue({
        ...buildingData,
        _id: 999
      })
    }
    return {
      _id: buildingData._id,
      buildingData: {
        ...buildingData
      }
    }
  }
)

export const deleteBuilding = createAsyncThunk(
  'building/deleteBuildingCall',
  async (buildingPayload: { _id: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().building
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id } = buildingPayload
    const buildingData = await thunkApi.extra.networkRequest.deleteBuilding(_id)
    if (!buildingData) {
      return rejectWithValue({
        error: 'Deletion failed',
        _id: 999
      })
    }
    if (buildingData.error) {
      return rejectWithValue({
        ...buildingData,
        _id: 999
      })
    }
    return {
      _id: buildingData._id,
      buildingData: {
        title: 'Building Deleted'
      }
    }
  }
)

export const buildingSlice = createSlice({
  name: 'building',
  initialState,
  reducers: {
    clearBuilding: (state, action: PayloadAction) => {
      return {
        ...generateEmptyBuildingState()
      }
    }
  },
  extraReducers: (builder) => {
    ([
      [getBuildingInfo, 'getBuildingInfo'],
      [addNewBuilding, 'addNewBuilding'],
      [editBuilding, 'editBuilding'],
      [deleteBuilding, 'deleteBuilding']
    ]).forEach(([action, actionName]: any) => {
      generalStandardCrudBuilder(builder, action, actionName)
    })
  }
})

export const { clearBuilding } = buildingSlice.actions

export const buildingSelector = createSelector((state: RootState) => state, (state) => state.building)

export default buildingSlice.reducer
