import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import PurchaseForm from "../../../Components/Forms/PurchaseForm/PurchaseForm";
import { addNewPurchase, purchaseSelector } from "../../../redux/purchase/purchaseSlice";
import { clearPurchases } from "../../../redux/purchases/purchasesSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";

export default function AddPurchasePage() {
  const {
    // loading,
    error,
    message,
  } = useSelector(purchaseSelector)
  const dispatch = useAppDispatch()
  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    const newPurchase = await dispatch(addNewPurchase({purchaseInfo: values})).unwrap()
    if (newPurchase._id) {
      dispatch(navigateTo(`/purchase/${newPurchase._id}`))
    }
    dispatch(clearPurchases())
    return newPurchase
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add Purchase</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          <PurchaseForm
            initialValues={{
                firstName: '',
            }}
            disabled={false}
            onSubmit={handleSubmit}
          />

        </Col>
      </Row>
    </Container>
  )
}
