import { createSlice, createSelector, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit"
import { generateStandardNetworkState, StandardNetworkState, standardNetworkPendingCall, } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface IcalDataState extends StandardNetworkState {
    icalData: {
        [v: string]: any
    },
    progress: { count: number, total: number}
}

const generateEmptIcalDataState = (): IcalDataState => {
    return {
        icalData: {},
        progress: {count: 0, total: 0},
        ...generateStandardNetworkState()
    }
}

export const getPropertyIcalData = createAsyncThunk(
    "icalData/getPropertyIcalData",
    async (propertyId: string, thunkApi: any) => {
        const { loading, currentRequestId } = thunkApi.getState().icalData
        const { requestId, rejectWithValue } = thunkApi
        if (loading !== "pending" || requestId !== currentRequestId) { return }
        const icalData = await thunkApi.extra.networkRequest.getPropertyIcalData(propertyId)
        if (icalData.error) {
            // do something clever to let the api know either no data or something went wrong
            return rejectWithValue({
                [propertyId]: {
                    error: icalData.error
                }
            })
        }
        // loop through ical data
        // for each entry set check in to 4pm and checkout to 11am
        // check for the sourceConfirmation number
        // add view info links for airbnb and vrbo
        return {
            [propertyId]: icalData
        }
    }
)

export const getAllPropertyIcalData = createAsyncThunk(
    "icalData/getAllPropertyIcalData",
    async (_, thunkApi: any) => {
        const { loading, currentRequestId } = thunkApi.getState().icalData
        const { requestId, rejectWithValue, dispatch } = thunkApi
        if (loading !== "pending" || requestId !== currentRequestId) { return }
        const { myProperties } = thunkApi.getState().accountData
        const propertyIds = Object.keys(myProperties).filter(x => myProperties[x].public)
        const icalData: any = {}
        for( const propertyId of propertyIds ) {
            // dispatch(updateIcalLoadingMessage(`Checking ${propertyId}`))
            dispatch(updateIcalLoadingProgress({
                message: `Checking ${propertyId}`,
                progress: {
                    count: Object.keys(icalData).length,
                    total: Object.keys(propertyIds).length
                }
            }))
            const propertyIcalData = await thunkApi.extra.networkRequest.getPropertyIcalData(propertyId)
            if (propertyIcalData.error) {
                icalData[propertyId] = []
                continue
            }
            icalData[propertyId] = propertyIcalData
        }
        // const icalData = await thunkApi.extra.networkRequest.getAllPropertyIcalData(propertyIds)

        if (icalData.error) {
            // do something clever to let the api know either no data or something went wrong
            return rejectWithValue({
                error: icalData.error
            })
        }
        return icalData
    }
)

const initialState = generateEmptIcalDataState()

export const icalDataSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setIcalDataEntry: (state, action: PayloadAction<{ [propertyId: string]: any }>) => {
            return {
                ...state,
                icalData: {
                    ...state.icalData,
                    ...action.payload
                },
                loading: 'idle',
                message: ''
            }
        },
        updateIcalLoadingMessage: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                message: action.payload
            }
        },
        updateIcalLoadingProgress: (state, action: PayloadAction<{message: string, progress: { count: number, total: number}}>) => {
            return {
                ...state,
                ...action.payload
            }
        },
        removeIcalDataEntry: (state, action: PayloadAction<string>) => {
            delete state.icalData[action.payload]
        }
    },
    extraReducers: (builder) => {

        // Get Single Property IcalData
        builder.addCase(getPropertyIcalData.pending, standardNetworkPendingCall('Attempting to load ical data'))
        builder.addCase(getPropertyIcalData.rejected, (state, action: any) => {
            return {
                ...state,
                icalData: {
                    ...state.icalData,
                    ...action.payload
                },
                loading: 'idle',
                message: '',
                error: null,
            }
        })
        builder.addCase(getPropertyIcalData.fulfilled, (state, action: any) => {
            return {
                ...state,
                icalData: {
                    ...state.icalData,
                    ...action.payload
                },
                loading: 'idle',
                message: '',
                error: null,
            }
        })
        // Get All Property IcalData
        builder.addCase(getAllPropertyIcalData.pending, standardNetworkPendingCall('Attempting to load ical data'))
        builder.addCase(getAllPropertyIcalData.rejected, (state, action: any) => {
            return {
                ...state,
                icalData: {
                    ...state.icalData,
                    ...action.payload
                },
                loading: 'idle',
                message: '',
                error: null,
            }
        })
        builder.addCase(getAllPropertyIcalData.fulfilled, (state, action: any) => {
            return {
                ...state,
                icalData: {
                    ...state.icalData,
                    ...action.payload
                },
                loading: 'idle',
                message: '',
                error: null,
            }
        })
        // END BUILDER
    }
})

export const { setIcalDataEntry, removeIcalDataEntry, updateIcalLoadingMessage, updateIcalLoadingProgress } = icalDataSlice.actions

export const icalDataSelector = createSelector((state: RootState) => state, (state) => state.icalData)

export default icalDataSlice.reducer