import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardCrudBuilder, generateStandardNetworkState, StandardNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface InquiryState extends StandardNetworkState {
  _id: string | null
  inquiryData: any
}

const generateEmptyInquiryState = (): InquiryState => {
  return {
    _id: '',
    inquiryData: {},
    ...generateStandardNetworkState()
  }
}

const initialState = generateEmptyInquiryState()

export const getInquiryInfo = createAsyncThunk(
  'inquiry/getInquiryInfoCall',
  async (inquiryId: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().inquiry
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const inquiryData = await thunkApi.extra.networkRequest.getInquiryById(inquiryId)
    if (inquiryData.error) {
      return rejectWithValue({
        ...inquiryData,
        _id: inquiryId
      })
    }
    return {
      _id: inquiryId,
      inquiryData
    }
  }
)

export const addNewInquiry = createAsyncThunk(
  'inquiry/addNewInquiryCall',
  async (inquiryPayload: { inquiryInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().inquiry
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { inquiryInfo } = inquiryPayload
    const inquiryData = await thunkApi.extra.networkRequest.addNewInquiry(inquiryInfo)
    if (inquiryData.error) {
      return rejectWithValue({
        ...inquiryData,
        _id: 999
      })
    }
    return {
      _id: inquiryData._id,
      inquiryData: {
        ...inquiryData
      }
    }
  }
)

export const editInquiry = createAsyncThunk(
  'inquiry/editInquiryCall',
  async (inquiryPayload: { _id: string, inquiryInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().inquiry
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id, inquiryInfo } = inquiryPayload
    const inquiryData = await thunkApi.extra.networkRequest.editInquiry(_id, inquiryInfo)
    if (inquiryData.error) {
      return rejectWithValue({
        ...inquiryData,
        _id: 999
      })
    }
    return {
      _id: inquiryData._id,
      inquiryData: {
        ...inquiryData
      }
    }
  }
)

export const deleteInquiry = createAsyncThunk(
  'inquiry/deleteInquiryCall',
  async (inquiryPayload: { _id: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().inquiry
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id } = inquiryPayload
    const inquiryData = await thunkApi.extra.networkRequest.deleteInquiry(_id)
    if (!inquiryData) {
      return rejectWithValue({
        error: 'Deletion failed',
        _id: 999
      })
    }
    if (inquiryData.error) {
      return rejectWithValue({
        ...inquiryData,
        _id: 999
      })
    }
    return {
      _id: inquiryData._id,
      inquiryData: {
        title: 'Inquiry Deleted'
      }
    }
  }
)

export const inquirySlice = createSlice({
  name: 'inquiry',
  initialState,
  reducers: {
    clearInquiry: (state, action: PayloadAction) => {
      return {
        ...generateEmptyInquiryState()
      }
    }
  },
  extraReducers: (builder) => {
    ([
      [getInquiryInfo, 'getInquiryInfo'],
      [addNewInquiry, 'addNewInquiry'],
      [editInquiry, 'editInquiry'],
      [deleteInquiry, 'deleteInquiry']
    ]).forEach(([action, actionName]: any) => {
      generalStandardCrudBuilder(builder, action, actionName)
    })
  }
})

export const { clearInquiry } = inquirySlice.actions

export const inquirySelector = createSelector((state: RootState) => state, (state) => state.inquiry)

export default inquirySlice.reducer
