import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { navigateTo } from '../../../redux/router/routerActions';
import { Link, useLocation } from 'react-router-dom';
import './loginPage.scss'
import { useAppDispatch } from '../../../redux/store';
import { accountSelector, loginUser, logoutUser } from '../../../redux/account/accountSlice';
import LoginForm from '../../../Components/Forms/LoginForm/LoginForm';
import { Col, Container, Row } from 'react-bootstrap';

export default function LoginPage() {

  const {loading, error, username, token} = useSelector(accountSelector)
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    return () => {}
  }, [username, token])

  return (
    <Container>
      <Row>
        <Col>
          <h1>Login Page</h1>
          <hr />
          {error && (
            <>
              {JSON.stringify(error)}
              <br />
              <Link to="forgot-password">Forgot Password?</Link>
            </>
          )}

          {username && token ? (
            <>
              You are already logged in.
              <br /><br />
              <div onClick={() => {
                dispatch(logoutUser())
              }} className="logout-button">click here to logout</div>
            </>
          ) : (
            <LoginForm
              disabled={loading === 'pending'}
              onSubmit={async(loginValues: any) => {
                try {
                  await dispatch(loginUser({
                    username: loginValues.email,
                    password: loginValues.password,
                    rememberMe: loginValues.rememberMe
                  })).unwrap()
                  if(location.pathname === '/login') {
                    dispatch(navigateTo('/'))
                  }
                  return true
                } catch(err) {
                  // custom error handler
                }
                return false
              }}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}
