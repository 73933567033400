import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { generateStandardNetworkState, standardNetworkErrorCall, standardNetworkPendingCall, StandardNetworkState } from "../reduxHelperFunctions";
import { RootState } from "../store";

interface ForgotPasswordState extends StandardNetworkState {}

const generateForgotPasswordState = (): ForgotPasswordState => {
  return {
    ...generateStandardNetworkState()
  }
}

const initialState: ForgotPasswordState = generateForgotPasswordState()

export const forgotPasswordAction = createAsyncThunk(
  'forgotPassword/forgotPasswordCall',
  async (username: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().forgotPassword
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }
    const forgotResponse = await thunkApi.extra.networkRequest.forgotPassword(username)
    if (forgotResponse.error) {
      return rejectWithValue(forgotResponse)
    }
    if (forgotResponse) {
      return forgotResponse.message
    }
    return rejectWithValue('Something went wrong')
  }
)


export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // FORGOT PASSWORD
    builder.addCase(forgotPasswordAction.pending, standardNetworkPendingCall('Sending forgot password request'))
    builder.addCase(forgotPasswordAction.rejected, standardNetworkErrorCall())
    builder.addCase(forgotPasswordAction.fulfilled, (state, action: any) => {
      return {
        ...state,
        loading: 'idle',
        message: `${action.payload}`,
        error: null
      }
    })
  }
})

export const forgotPasswordSelector = createSelector((state: RootState) => state, (state) => state.forgotPassword)

export default forgotPasswordSlice.reducer
