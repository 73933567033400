import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/store"
import { getUsersAction, usersSelector } from "../../redux/users/usersSlice"

export default function useUsersQuery(activeQuery: {
    query: any,
    page: number
    limit: number,
}) {
    const {
        loading,
        error,
        currentRequestId,
        paginationInfo,
        currentQuery,
        message,
        userList,
    } = useSelector(usersSelector)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (loading !== 'pending' && JSON.stringify(activeQuery) !== JSON.stringify(currentQuery)) {
            dispatch(getUsersAction(activeQuery))
        }
        return () => { }
    }, [activeQuery, currentQuery, dispatch, loading])

    return { loading, error, message, userList, paginationInfo, currentRequestId, currentQuery }
    
}