import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { propertyPriceSelector, getPropertyPriceInfo } from "../../../redux/propertyPrice/propertyPriceSlice"
import { useAppDispatch } from "../../../redux/store"

export default function PropertyPricePage() {
  const { _id, propertyPriceData, message, loading, error} = useSelector(propertyPriceSelector)
  const { propertyPriceId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && propertyPriceId && _id !== propertyPriceId) {
      dispatch(getPropertyPriceInfo(propertyPriceId))
    }
    return () => {}
  }, [propertyPriceId, _id, dispatch, loading])

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single PropertyPrice Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}

          {propertyPriceId && propertyPriceId === _id && propertyPriceData && (
            <div className="property-priceinfo">
              <pre>
                {JSON.stringify(propertyPriceData, null, 2)}            
              </pre>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
