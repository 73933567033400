import { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useMyProperties from "../../Hooks/UseMyProperties/UseMyPropertiesHook";
import { createGenericInventory, InventoryEntry } from "./InventoryData";
import './inventoryCheckerPage.scss';
import { copyToClipboard } from "../../Helpers/ValueHandlers/copyText";

export default function InventoryCheckerPage() {
    const {
        myProperties,
        // loading, 
        error,
        message
    } = useMyProperties()
    const { propertyId } = useParams()

    const [showReport, setShowReport] = useState<boolean>(false)

    const [inventoryData, setInventoryData] = useState<any>({})

    const propertyData = myProperties && propertyId && myProperties[propertyId] ? myProperties[propertyId] : null

    useEffect(() => {
        if (propertyData && propertyData !== null && !inventoryData.places) {
            setInventoryData(setupInventory(propertyData))
        }
        return () => { }
    }, [propertyData, inventoryData])

    const addCount = (entry: InventoryEntry) => {
        if (entry.actualCount < entry.count) {
            entry.actualCount++
        }
        entry.checked = true
        setInventoryData({ ...inventoryData })
        saveInventoryData(propertyData, inventoryData)
    }

    const lowerCount = (entry: InventoryEntry) => {
        if (entry.actualCount > 0) {
            entry.actualCount--
        }
        entry.checked = true
        setInventoryData({ ...inventoryData })
        saveInventoryData(propertyData, inventoryData)
    }


    return (
        <Container>
            <Row>
                <Col>
                    <h1>Inventory Checker</h1>
                    <hr />

                    {propertyData && (
                        <div className="property-details mb-3">
                            <h3>{propertyData.title}</h3>
                            <b>Details:</b>
                            <ul>
                                <li><b>Bedrooms: </b>{propertyData.about.bedrooms}</li>
                                <li><b>Beds: </b>{propertyData.about.beds}</li>
                                <li><b>Bathrooms: </b>{propertyData.about.bathrooms}</li>
                                <li><b>Baths: </b>{propertyData.about.baths}</li>
                            </ul>
                            <Button variant="primary" onClick={() => {
                                setInventoryData(setupInventory(propertyData, true))
                            }}>Reset Counts</Button>
                        </div>
                    )}

                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {message && (<>{message}<br /></>)}

                </Col>
            </Row>

            <hr />

            {!showReport && inventoryData && inventoryData.places && inventoryData.places.map((place: any, idx: number) => (
                <Row key={idx} className="mb-2">
                    <Col className="inventory-place mb-3">
                        <h3>
                            <b>
                                {place.name}
                            </b>
                        </h3>
                        <hr />
                        {place.inventoryGroups && place.inventoryGroups.map((group: any, idx: number) => (
                            <div key={idx} className="inventory-group mb-4">
                                <h4 className="mb-3">
                                    <b>
                                        {group.name}
                                    </b>
                                </h4>
                                <div className="inventory-entry-container">
                                    {group.inventory && group.inventory.map((item: any, idx: number) => (
                                        <div key={idx} className="inventory-entry text-left">
                                            <img
                                                className={`${item.checked ? 'checked' : ''} ${item.count !== item.actualCount ? 'bad' : ''}`}
                                                src={`/inventory/${item.img}`}
                                                alt={item.name}
                                                onClick={() => { addCount(item) }} />

                                            <div className="item-name">
                                                <b>{item.name}</b>
                                                {/* <span>{item.checked ? (<>&#10003;</>) : '-'}</span> */}
                                            </div>

                                            <div className="inventory-entry-controls">
                                                <div className={`inventory-entry-counts small me-2 ${item.count === item.actualCount && item.checked ? 'good' : ''}`}>
                                                    {item.actualCount} / {item.count}
                                                </div>

                                                <Button
                                                    className="me-2"
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => { lowerCount(item) }}
                                                    disabled={item.actualCount === 0 && item.checked}>
                                                    -
                                                </Button>
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    onClick={() => { addCount(item) }}
                                                    disabled={item.count === item.actualCount && item.checked}>
                                                    +
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </Col>
                </Row>
            ))}

            {showReport ? (
                <Button variant="primary" onClick={() => { setShowReport(false) }}>Back to Inventory</Button>
            ) : (
                <Button variant="primary" onClick={() => { setShowReport(true) }}>Show Report</Button>
            )}
            <hr />

            {showReport && (
                <InventoryReport inventoryData={inventoryData} propertyData={propertyData} />
            )}


        </Container>
    );
}


const InventoryReport = ({ inventoryData, propertyData }: any) => {

    const inventoryReport = useMemo(() => {
        const reportData: any[] = []
        inventoryData.places.forEach((place: any) => {
            const includedGroups: any[] = []
            place.inventoryGroups.forEach((group: any) => {
                const inventoryList = group.inventory.filter((item: any) => {
                    return item.count - item.actualCount > 0 || !item.checked
                })
                if (inventoryList.length) {
                    includedGroups.push({
                        ...group,
                        inventory: [...inventoryList]
                    })
                }
            })
            if (includedGroups.length > 0) {
                reportData.push({
                    ...place,
                    inventoryGroups: [...includedGroups]
                })
            }
        })
        return reportData
    }, [inventoryData])

    const copyReportDataToClipboard = () => {
        // const reportData = JSON.stringify(inventoryReport)
        // navigator.clipboard.writeText(reportData)
        let text = ''
        if (propertyData && propertyData._id && propertyData.title) {
            text += `Property: ${propertyData.title.split(' - ')[0]}\n\n`
        }
        inventoryReport.forEach((place: any) => {
            text += `${place.name}\n\n`
            place.inventoryGroups.forEach((group: any) => {
                text += `${group.name}\n\n`
                group.inventory.forEach((item: any) => {
                    text += `- ${item.name}${!item.checked ? ' NOT CHECKED' : ''}: ${item.count - item.actualCount}\n`
                })
                text += '\n'
            })
            text += '\n'
        })

        // navigator.clipboard.writeText(text)
        copyToClipboard(text)

    }

    return (
        <Row>
            <Col className="inventory-report-container">
                <h3>Inventory Report - Missing Items</h3>
                {propertyData && propertyData.title && (
                    <div className="mb-3">
                        <b>Property:</b> {propertyData.title} <br />
                    </div>
                )}
                {inventoryReport.length === 0 && (
                    <div className="inventory-place">
                        ヽ༼ ຈل͜ຈ༼ ▀̿̿Ĺ̯̿̿▀̿ ̿༽Ɵ͆ل͜Ɵ͆ ༽ﾉ <br />
                        Everything is gravy baby! <br />
                    </div>
                )}

                {inventoryReport.map((place: any, idx: number) => (
                    <div key={idx} className="inventory-place mb-3">
                        <b>{place.name}</b>
                        {place.inventoryGroups.map((group: any, idx: number) => (
                            <div key={idx} className="inventory-group mb-3">
                                <i><u>{group.name}</u></i>
                                {group.inventory.map((entry: any, idx: number) => (
                                    <div key={idx} className="inventory-entry">
                                        {entry.name} {!entry.checked && (<span className="not-checked">(NOT CHECKED)</span>)}: {entry.count - entry.actualCount}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ))}

                <Button onClick={() => { copyReportDataToClipboard() }}>Copy to Clipboard</Button>

            </Col>
        </Row>
    )
}


const saveInventoryData = (propertyData: any, inventoryData: any) => {
    try {
        const inventoryEntry = JSON.stringify({
            propertyId: propertyData._id,
            version: '1a',
            date: Date.now(),
            inventoryData: inventoryData
        })
        // save to local storage as propertyId-inventory for the key
        localStorage.setItem(`${propertyData._id}-inventory`, inventoryEntry)
    } catch(err) {
        console.log(err)
    }
}

const readInventoryData = (propertyData: any) => {
    try {
        const inventoryEntry = localStorage.getItem(`${propertyData._id}-inventory`)
        if (!inventoryEntry) { return false }
        const inventoryData = JSON.parse(inventoryEntry)
        return inventoryData
    } catch (err) {
        console.log(err)
    }
    return false
}

const deleteInventoryData = (propertyData: any) => {
    try {
        localStorage.removeItem(`${propertyData._id}-inventory`)
    } catch (err) {
        console.log(err)
    }
}


const setupInventory = (propertyData: any, reset: boolean = false) => {
    if (!propertyData) { return false }
    const inventoryData = readInventoryData(propertyData)
    if (!reset && inventoryData && inventoryData.inventoryData && inventoryData.version === '1a') {
        return inventoryData.inventoryData
    }
    const inventory = createGenericInventory({
        bedrooms: propertyData.about.bedrooms,
        beds: propertyData.about.beds,
        bathrooms: Math.ceil(propertyData.about.bathrooms),
        baths: propertyData.about.baths > 0 ? propertyData.about.baths : Math.floor(propertyData.about.bathrooms)
    })

    if(reset) {
        deleteInventoryData(propertyData)
    }
    
    return inventory
}