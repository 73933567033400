import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import InquiryForm from "../../../Components/Forms/InquiryForm/InquiryForm";
import { addNewInquiry, inquirySelector } from "../../../redux/inquiry/inquirySlice";
import { clearInquiries } from "../../../redux/inquiries/inquiriesSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";

export default function AddInquiryPage() {
  const {
    // loading,
    error,
    message,
  } = useSelector(inquirySelector)
  const dispatch = useAppDispatch()
  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    const newInquiry = await dispatch(addNewInquiry({inquiryInfo: values})).unwrap()
    if (newInquiry._id) {
      dispatch(navigateTo(`/inquiry/${newInquiry._id}`))
    }
    dispatch(clearInquiries())
    return newInquiry
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add Inquiry</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          <InquiryForm
            initialValues={{
                name: '',
            }}
            disabled={false}
            onSubmit={handleSubmit}
          />

        </Col>
      </Row>
    </Container>
  )
}
