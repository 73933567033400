import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import BuildingForm from "../../../Components/Forms/BuildingForm/BuildingForm";
import { buildingSelector, editBuilding, getBuildingInfo } from "../../../redux/building/buildingSlice";
import { clearBuildings } from "../../../redux/buildings/buildingsSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { uploadImage } from "../../../redux/generalNetwork/generalNetworkSlice";

export default function EditBuildingPage() {
  const [imageUploadProgress, setImageUploadProgress] = useState('')
  const { _id, buildingData, message, loading, error } = useSelector(buildingSelector)
  const { buildingId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && buildingId && _id !== buildingId) {
      dispatch(getBuildingInfo(buildingId))
    }
    return () => { }
  }, [buildingId, _id, dispatch, loading, buildingData])


  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (_id) {
      const editedBuilding = await dispatch(editBuilding({ _id, buildingInfo: values })).unwrap()
      if (editedBuilding._id) {
        dispatch(navigateTo(`/building/${editedBuilding._id}`))
      }
      dispatch(clearBuildings())
      return editedBuilding
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Building</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          {buildingId && buildingId === _id && buildingData && (
            <BuildingForm
              disabled={loading === 'pending'}
              initialValues={buildingData}
              onSubmit={handleSubmit}
              uploadImageFn={async (imageInfo: { imageToUpload: any, filename: string }) => {
                const uploadedImageUrl = await dispatch(uploadImage(imageInfo)).unwrap()
                return uploadedImageUrl
              }}
              imageUploadProgress={imageUploadProgress}
              uploadImageMultiFn={async (imageInfo: { imageToUpload: any, filename: string }[]) => {
                const uploadedImageUrls = []
                for (let i = 0; i < imageInfo.length; i++) {
                  setImageUploadProgress(`Processing ${i + 1} of ${imageInfo.length}`)
                  const uploadedImageUrl = await dispatch(uploadImage(imageInfo[i])).unwrap()
                  uploadedImageUrls.push(uploadedImageUrl)
                }
                setImageUploadProgress(``)
                return uploadedImageUrls
              }}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
