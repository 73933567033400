import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import ForgotPasswordForm from "../../../Components/Forms/ForgotPasswordForm/ForgotPasswordForm";
import { forgotPasswordAction, forgotPasswordSelector } from "../../../redux/forgotPassword/forgotPasswordSlice";
import { useAppDispatch } from "../../../redux/store";

export default function ForgotPasswordPage() {

  const {error, message} = useSelector(forgotPasswordSelector)
  const dispatch = useAppDispatch()

  return (
    <Container>
      <Row>
        <Col>
          <h1>Forgot Password</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}
          <ForgotPasswordForm
            onSubmit={(emailAddress: string) => {
              dispatch(forgotPasswordAction(emailAddress))
            }}
          />
        </Col>
      </Row>
    </Container>
  )
}
