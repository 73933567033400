import { bukinsSelector, getBukinsAction } from "../../redux/bukins/bukinsSlice"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/store"
import { useEffect } from "react"
import { shouldLoadNetwork } from "../../Helpers/ValueHandlers/shouldLoad"


export default function useBukinQuery(activeQuery: {
    query: any,
    page: number
    limit: number,
    all?: boolean
}) {
    const {
        loading,
        error,
        currentRequestId,
        paginationInfo,
        currentQuery,
        message,
        bukinsList,
        lastLoad
    } = useSelector(bukinsSelector)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (shouldLoadNetwork(loading, currentQuery, activeQuery, lastLoad)) {
            dispatch(getBukinsAction(activeQuery))
        }
        return () => { }
    }, [activeQuery, currentQuery, dispatch, loading, lastLoad])

    return { loading, error, message, bukinsList, paginationInfo, currentRequestId, currentQuery }

}