import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { mediaFileSelector, getMediaFileInfo } from "../../../redux/mediaFile/mediaFileSlice"
import { useAppDispatch } from "../../../redux/store"

export default function MediaFilePage() {
  const { _id, mediaFileData, message, loading, error} = useSelector(mediaFileSelector)
  const { mediaFileId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && mediaFileId && _id !== mediaFileId) {
      dispatch(getMediaFileInfo(mediaFileId))
    }
    return () => {}
  }, [mediaFileId, _id, dispatch, loading])

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single MediaFile Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message}<br /></>)}

          {mediaFileId && mediaFileId === _id && mediaFileData && (
            <div className="mediaFileinfo">
              <pre>
                {JSON.stringify(mediaFileData, null, 2)}            
              </pre>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
