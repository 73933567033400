import { createAsyncThunk, createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardPaginatedBuilder, generateStandardNetworkPaginatedState, StandardPaginatedNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface BuildingsState extends StandardPaginatedNetworkState {
  buildingsList: any[] | null
}

const generateEmptyBuildingsState = (): BuildingsState => {
  return {
    ...generateStandardNetworkPaginatedState(),
    buildingsList: null,
  }
}

export const getBuildingsAction = createAsyncThunk(
  'buildings/getBuildingsActionCall',
  async (queryInfo: {query: any, page: number, limit: number, all?: boolean}, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().buildings
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const buildingsList = await thunkApi.extra.networkRequest.getBuildings(queryInfo.query, {page: queryInfo.page, limit: queryInfo.limit, all: queryInfo.all})
    if (buildingsList.error) {
      return rejectWithValue({
        ...buildingsList,
        currentQuery: queryInfo
      })
    }
    const {docs, ...paginationInfo} = buildingsList
    return {
      docs,
      paginationInfo,
      currentQuery: queryInfo
    }
  }
)

const initialState: BuildingsState = generateEmptyBuildingsState()

export const buildingsSlice = createSlice({
  name: 'buildings',
  initialState,
  reducers: {
    clearBuildings: (state, action: PayloadAction) => {
      return {
        ...generateEmptyBuildingsState()
      }
    }
  },
  extraReducers: (builder) => {
    generalStandardPaginatedBuilder(builder, getBuildingsAction, 'buildingsList')
  }
})

export const { clearBuildings } = buildingsSlice.actions

export const buildingsSelector = createSelector((state: RootState) => state, (state) => state.buildings)

export default buildingsSlice.reducer
