import { bookingRequestsSelector, getBookingRequestsAction } from "../../redux/bookingRequests/bookingRequestsSlice"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../redux/store"
import { useEffect } from "react"


export default function useBookingRequestQuery(activeQuery: {
    query: any,
    page: number
    limit: number,
    all?: boolean
}) {
    const {
        loading,
        error,
        currentRequestId,
        paginationInfo,
        currentQuery,
        message,
        bookingRequestsList,
    } = useSelector(bookingRequestsSelector)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (loading !== 'pending' && JSON.stringify(activeQuery) !== JSON.stringify(currentQuery)) {
            dispatch(getBookingRequestsAction(activeQuery))
        }
        return () => { }
    }, [activeQuery, currentQuery, dispatch, loading])

    return { loading, error, message, bookingRequestsList, paginationInfo, currentRequestId, currentQuery }

}