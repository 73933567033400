import QuickForm from "../QuickForm/QuickForm"
import { BukibleEntryType } from "../../../Types/bukibleTypes"
import * as yup from 'yup'


const generateBlankBukible = () => ({
  name: '',
  description: '',
  featuredImage: '',
  public: false,

  bukibleType: '',
  quantity: 1,
  address: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: null,
    country: '',
  },
  tags: [],
  groups: [],
  payload: '',

  // temp stuff
  tempTag: '',
  tempGroup: '',

})

export default function BukibleForm(props: {
  disabled: boolean,
  onSubmit: (v: any) => Promise<any>,
  initialValues?: Partial<BukibleEntryType>,
  uploadImageFn?: (v: any) => Promise<string>,
}) {
  const {
    disabled,
    onSubmit,
    initialValues,
    uploadImageFn,
  } = props

  return (
    <div className="bukible-form">
      <QuickForm
        initialData={{
          ...generateBlankBukible(),
          ...(initialValues ? initialValues : {})
        }}
        disabled={disabled}
        validationSchema={bukibleValidationSchema}
        onSubmit={(v: any) => {
          const newValues = { ...v }

          delete newValues.tempTag
          delete newValues.tempGroup

          console.log('newValues', newValues)
          onSubmit(newValues)
        }}
        uploadImageFn={uploadImageFn}
        fields={bukibleFormFields}
      />
    </div>
  )
}

const bukibleFormFields = (v: any): any => {
  return [
    {
      fieldName: 'title',
      fieldLabel: 'Title',
      fieldType: 'text',
      placeholder: 'Title',
      required: true,
    },
    {
      fieldName: 'description',
      fieldLabel: 'Description',
      fieldType: 'textarea',
      placeholder: 'Description',
    },
    {
      fieldName: 'bukibleType',
      fieldLabel: 'Bukible Type',
      fieldType: 'select',
      placeholder: 'Bukible Type',
      options: [
        { value: 'parking-qr', text: 'Parking QR' },
        { value: 'general', text: 'General' },
      ],
      required: true,
    },
    {
      fieldName: 'quantity',
      fieldLabel: 'Quantity',
      fieldType: 'number',
      placeholder: 'Quantity',
    },

    {
      fieldName: 'qrCode',
      fieldLabel: 'QR Code',
      fieldType: 'text',
      placeholder: 'QR Code',
      condition: (values: any) => values.bukibleType === 'parking-qr',
      extra: {
        title: 'QR Code',
        imagePreview: true,
      }
    },
    {
      fieldName: 'qrCode',
      fieldLabel: 'QR Code',
      fieldType: 'imageUpload',
      placeholder: 'QR Code',
      extra: {
        breakAfter: true,
      }
    },

    {
      fieldName: 'featuredImage',
      fieldLabel: 'Featured Image',
      fieldType: 'text',
      placeholder: 'Featured Image',
      extra: {
        title: 'Featured Image',
        imagePreview: true,
      }
    },
    {
      fieldName: 'featuredImage',
      fieldLabel: 'Featured Image',
      fieldType: 'imageUpload',
      placeholder: 'Featured Image',
      extra: {
        breakAfter: true,
      }
    },
    {
      fieldName: 'address',
      fieldType: 'address',
      fieldLabel: 'Address',
      condition: (values: any) => values.bukibleType === 'parking-qr',
      required: v.bukibleType === 'parking-qr',
      extra: {
        title: 'Address',
        breakAfter: true,
      }
    },
    {
      fieldName: 'groups',
      fieldLabel: 'Groups',
      fieldType: 'quickTag',
      placeholder: 'Groups',
      extra: {
        title: 'Groups',
        breakAfter: true,
        options: {
          // hideInput: true,
          // hideBadges: true,
        },
        tempFieldName: 'tempGroup',
        tagEntryName: 'group',
        buttons: [
          { tag: '1212-Lincoln-Garage', text: '1212-Lincoln-Garage' },
          // { tag: 'maintenance', text: 'Maintenance' },
          // { tag: 'ownerStay', text: 'Owner Stay' },
        ]
      }
    },
    {
      fieldName: 'tags',
      fieldLabel: 'Tags',
      fieldType: 'quickTag',
      placeholder: 'Tags',
      extra: {
        title: 'Tags',
        breakAfter: true,
        options: {},
        tempFieldName: 'tempTag',
        tagEntryName: 'tag',
        buttons: [
          { tag: 'parking', text: 'parking' },
          // { tag: 'maintenance', text: 'Maintenance' },
          // { tag: 'ownerStay', text: 'Owner Stay' },
        ]
      }
    },
    {
      fieldName: 'payload',
      fieldLabel: 'Payload',
      fieldType: 'textarea',
      placeholder: 'Payload',
      hidden: true,
    },
    {
      fieldName: 'public',
      fieldLabel: 'Public',
      fieldType: 'checkbox',
      placeholder: 'Public',
    },

  ]
}

const bukibleValidationSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  bukibleType: yup.string().required('Bukible Type is required'),
  // quantity: yup.number().required('Quantity is required'),
  address: yup.object().shape({
    address1: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zip: yup.number(),
    country: yup.string(),
  }).when('bukibleType', {
    is: (v: any) => v === 'parking-qr',
    then: yup.object().shape({
      address1: yup.string().required('Address 1 is required'),
      city: yup.string().required('City is required'),
      state: yup.string().required('State is required'),
      zip: yup.number().required('Zip is required'),
      country: yup.string().required('Country is required'),
    }),
  }),
    // tags: yup.array().of(
    //   yup.object().shape({
    //     tag: yup.string().required('Tag is required'),
    //   })
    // ),
    // groups: yup.array().of(
    //   yup.object().shape({
    //     group: yup.string().required('Group is required'),
    //   })
    // ),
    // payload: yup.string().required('Payload is required'),
    qrCode: yup.string().when('bukibleType', {
      is: (v: any) => v === 'parking-qr',
      then: yup.string().required('QR Code is required'),
    }),
  })
