import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropertyTaskForm from "../../../Components/Forms/PropertyTaskForm/PropertyTaskForm";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import useUserRefs from "../../../Hooks/UseUsersQuery/UseUserRefs";
import useUsersSelectValues from "../../../Hooks/UseUsersQuery/UseUsersSelectValues";
import { accountSelector } from "../../../redux/account/accountSlice";
import { uploadImage } from "../../../redux/generalNetwork/generalNetworkSlice";
import { clearPropertyTask, editPropertyTask, getPropertyTaskInfo, propertyTaskSelector } from "../../../redux/propertyTask/propertyTaskSlice";
import { clearPropertyTasks } from "../../../redux/propertyTasks/propertyTasksSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";

export default function EditPropertyTaskPage() {

    const userRef = useUserRefs()
    const userSelectOptions = useUsersSelectValues(userRef)

    const { 
        _id, 
        propertyTaskData, 
        // message, 
        loading, 
        // error 
    } = useSelector(propertyTaskSelector)
    const { propertyTaskId } = useParams()

    const { myProperties } = useMyProperties()
    const myPropertySelectOptions = useMyPropertiesSelectValues(myProperties)
    const { email, role } = useSelector(accountSelector)
    const [imageUploadProgress, setImageUploadProgress] = useState('')

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (loading !== 'pending' && propertyTaskId && _id !== propertyTaskId) {
            dispatch(getPropertyTaskInfo(propertyTaskId))
        }
        return () => { }
    }, [propertyTaskId, _id, dispatch, loading])

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Edit Property Task</h1>
                    <hr />
                    {email && role && propertyTaskId === _id && propertyTaskData && (
                        <PropertyTaskForm
                            initialValues={propertyTaskData}
                            disabled={loading === 'pending'}
                            userSelectOptions={userSelectOptions}
                            onSubmit={async (values) => {
                                const editedTask = await dispatch(editPropertyTask({ propertyTaskInfo: values, _id })).unwrap()
                                if (editedTask._id) {
                                    dispatch(navigateTo(`/property-task/${editedTask._id}`))
                                }
                                dispatch(clearPropertyTasks())
                                dispatch(clearPropertyTask())
                                return editedTask

                            }}
                            propertyOptionList={myPropertySelectOptions}
                            uploadImageFn={async (imageInfo: { imageToUpload: any, filename: string }) => {
                                const uploadedImageUrl = await dispatch(uploadImage(imageInfo)).unwrap()
                                return uploadedImageUrl
                            }}
                            imageUploadProgress={imageUploadProgress}
                            uploadImageMultiFn={async (imageInfo: { imageToUpload: any, filename: string }[]) => {
                                const uploadedImageUrls = []
                                for (let i = 0; i < imageInfo.length; i++) {
                                    setImageUploadProgress(`Processing ${i + 1} of ${imageInfo.length}`)
                                    const uploadedImageUrl = await dispatch(uploadImage(imageInfo[i])).unwrap()
                                    uploadedImageUrls.push(uploadedImageUrl)
                                }
                                setImageUploadProgress(``)
                                return uploadedImageUrls
                            }}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    )
}