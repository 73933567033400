import { useEffect } from "react"
import { useSelector } from "react-redux"
import { getPropertyTasksAction, propertyTasksSelector } from "../../redux/propertyTasks/propertyTasksSlice"
import { useAppDispatch } from "../../redux/store"
import { shouldLoadNetwork } from "../../Helpers/ValueHandlers/shouldLoad"

export default function usePropertyTaskQuery(activeQuery: {
    query: any,
    page: number
    limit: number,
}) {
    const {
        loading,
        error,
        currentRequestId,
        paginationInfo,
        currentQuery,
        message,
        propertyTasksList,
        lastLoad
    } = useSelector(propertyTasksSelector)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (shouldLoadNetwork(loading, currentQuery, activeQuery, lastLoad)) {
            dispatch(getPropertyTasksAction(activeQuery))
        }
        return () => {}
    })

    return { loading, error, message, propertyTasksList, paginationInfo, currentRequestId, currentQuery, lastLoad }
}