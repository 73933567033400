import { PropertyAuthorizedUserType, PropertyType } from "../../Types/propertyTypes"

export default function getPropertyRole(property: PropertyType, username: string) {
    let propertyRole = 'casual'

    if (property.authorizedUsers) {
        property.authorizedUsers.forEach((au: PropertyAuthorizedUserType) => {
            if (au.email === username) {
                propertyRole = au.role
            }
        })
    }

    return propertyRole
}