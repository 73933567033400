import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardCrudBuilder, generateStandardNetworkState, StandardNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface BookingRequestState extends StandardNetworkState {
  _id: string | null
  bookingRequestData: any
}

const generateEmptyBookingRequestState = (): BookingRequestState => {
  return {
    _id: '',
    bookingRequestData: {},
    ...generateStandardNetworkState()
  }
}

const initialState = generateEmptyBookingRequestState()

export const getBookingRequestInfo = createAsyncThunk(
  'bookingRequest/getBookingRequestInfoCall',
  async (bookingRequestId: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bookingRequest
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const bookingRequestData = await thunkApi.extra.networkRequest.getBookingRequestById(bookingRequestId)
    if (bookingRequestData.error) {
      return rejectWithValue({
        ...bookingRequestData,
        _id: bookingRequestId
      })
    }
    return {
      _id: bookingRequestId,
      bookingRequestData
    }
  }
)

export const addNewBookingRequest = createAsyncThunk(
  'bookingRequest/addNewBookingRequestCall',
  async (bookingRequestPayload: { bookingRequestInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bookingRequest
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { bookingRequestInfo } = bookingRequestPayload
    const bookingRequestData = await thunkApi.extra.networkRequest.addNewBookingRequest(bookingRequestInfo)
    if (bookingRequestData.error) {
      return rejectWithValue({
        ...bookingRequestData,
        _id: 999
      })
    }
    return {
      _id: bookingRequestData._id,
      bookingRequestData: {
        ...bookingRequestData
      }
    }
  }
)

export const editBookingRequest = createAsyncThunk(
  'bookingRequest/editBookingRequestCall',
  async (bookingRequestPayload: { _id: string, bookingRequestInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bookingRequest
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id, bookingRequestInfo } = bookingRequestPayload
    const bookingRequestData = await thunkApi.extra.networkRequest.editBookingRequest(_id, bookingRequestInfo)
    if (bookingRequestData.error) {
      return rejectWithValue({
        ...bookingRequestData,
        _id: 999
      })
    }
    return {
      _id: bookingRequestData._id,
      bookingRequestData: {
        ...bookingRequestData
      }
    }
  }
)

export const deleteBookingRequest = createAsyncThunk(
  'bookingRequest/deleteBookingRequestCall',
  async (bookingRequestPayload: { _id: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bookingRequest
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id } = bookingRequestPayload
    const bookingRequestData = await thunkApi.extra.networkRequest.deleteBookingRequest(_id)
    if (!bookingRequestData) {
      return rejectWithValue({
        error: 'Deletion failed',
        _id: 999
      })
    }
    if (bookingRequestData.error) {
      return rejectWithValue({
        ...bookingRequestData,
        _id: 999
      })
    }
    return {
      _id: bookingRequestData._id,
      bookingRequestData: {
        title: 'BookingRequest Deleted'
      }
    }
  }
)

export const bookingRequestSlice = createSlice({
  name: 'bookingRequest',
  initialState,
  reducers: {
    clearBookingRequest: (state, action: PayloadAction) => {
      return {
        ...generateEmptyBookingRequestState()
      }
    }
  },
  extraReducers: (builder) => {
    ([
      [getBookingRequestInfo, 'getBookingRequestInfo'],
      [addNewBookingRequest, 'addNewBookingRequest'],
      [editBookingRequest, 'editBookingRequest'],
      [deleteBookingRequest, 'deleteBookingRequest']
    ]).forEach(([action, actionName]: any) => {
      generalStandardCrudBuilder(builder, action, actionName)
    })
  }
})

export const { clearBookingRequest } = bookingRequestSlice.actions

export const bookingRequestSelector = createSelector((state: RootState) => state, (state) => state.bookingRequest)

export default bookingRequestSlice.reducer
