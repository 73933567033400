import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import DateBlockForm from "../../../Components/Forms/DateBlockForm/DateBlockForm";
import { dateBlockSelector, editDateBlock, getDateBlockInfo } from "../../../redux/dateBlock/dateBlockSlice";
import { clearDateBlocks } from "../../../redux/dateBlocks/dateBlocksSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import { checkBlockDates } from "../../../redux/generalNetwork/generalNetworkSlice";
import timeService from "../../../services/time/timeService";

export default function EditDateBlockPage() {

  const {
    myProperties,
  } = useMyProperties()
  const propertyOptions = useMyPropertiesSelectValues(myProperties)

  const { _id, dateBlockData, message, loading, error } = useSelector(dateBlockSelector)
  const { dateBlockId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading !== 'pending' && dateBlockId && _id !== dateBlockId) {
      dispatch(getDateBlockInfo(dateBlockId))
    }
    return () => { }
  }, [dateBlockId, _id, dispatch, loading, dateBlockData])


  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (_id) {
      const editedDateBlock = await dispatch(editDateBlock({ _id, dateBlockInfo: values })).unwrap()
      if (editedDateBlock._id && editedDateBlock.dateBlockData?.propertyId) {
        const editedBlockCheckIn = timeService.dateToMillis(editedDateBlock.dateBlockData.checkIn)
        dispatch(navigateTo(`/date-blocks?query={"status":["new","pending","confirmed"], "propertyId": "${editedDateBlock.dateBlockData.propertyId}", "checkIn": ${editedBlockCheckIn}}`))
        // dispatch(navigateTo(`/date-block/${editedDateBlock._id}`))
      }
      dispatch(clearDateBlocks())
      return editedDateBlock
    }
  }

  const [dateValidationStatus, setDateValidationStatus] = useState({
    available: true,
    status: '',
    message: ''
  })

  const validateDate = async (selectedPropertyId: string, checkIn: Date, checkOut: Date) => {
    setDateValidationStatus({
      available: false,
      status: 'warning',
      message: 'Checking date range for property'
    })
    const propertyId = [selectedPropertyId]
    // if (myProperties[selectedPropertyId]) {
    //   const {
    //     multiUnit: {
    //       isMultiUnit,
    //       linkedUnits,
    //     }
    //   } = myProperties[selectedPropertyId]
    //   if (isMultiUnit) {
    //     linkedUnits.forEach((unit: PropertyLinkedPropertyType) => {
    //       propertyId.push(unit.propertyId)
    //     })
    //   }
    // }
    const blockData = await dispatch(checkBlockDates({
      propertyId,
      checkIn,
      checkOut
    })).unwrap()
    if (blockData && blockData.docs && blockData.docs.length > 0) {
      const filteredBlocks = blockData.docs.filter((b: any) => {
        if (b._id === _id) {
          return false
        }
        return true
      })
      if(filteredBlocks.length > 0) {
        setDateValidationStatus({
          available: false,
          status: 'danger',
          message: 'WARNING: selected date range is unavailable for this property'
        })
        return
      }
    }
    setDateValidationStatus({
      available: true,
      status: 'success',
      message: 'This property is available for  the date range'
    })
    return
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit DateBlock</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          {dateBlockId && dateBlockId === _id && dateBlockData && (
            <DateBlockForm
              disabled={false}
              initialValues={dateBlockData}
              onSubmit={handleSubmit}
              propertyOptionList={propertyOptions}
              propertyData={myProperties}
              dateValidationFn={validateDate}
              dateValidationStatus={dateValidationStatus}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
