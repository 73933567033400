import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardCrudBuilder, generateStandardNetworkState, StandardNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface PurchaseState extends StandardNetworkState {
  _id: string | null
  purchaseData: any
}

const generateEmptyPurchaseState = (): PurchaseState => {
  return {
    _id: '',
    purchaseData: {},
    ...generateStandardNetworkState()
  }
}

const initialState = generateEmptyPurchaseState()

export const getPurchaseInfo = createAsyncThunk(
  'purchase/getPurchaseInfoCall',
  async (purchaseId: string, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().purchase
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const purchaseData = await thunkApi.extra.networkRequest.getPurchaseById(purchaseId)
    if (purchaseData.error) {
      return rejectWithValue({
        ...purchaseData,
        _id: purchaseId
      })
    }
    return {
      _id: purchaseId,
      purchaseData
    }
  }
)

export const addNewPurchase = createAsyncThunk(
  'purchase/addNewPurchaseCall',
  async (purchasePayload: { purchaseInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().purchase
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { purchaseInfo } = purchasePayload
    const purchaseData = await thunkApi.extra.networkRequest.addNewPurchase(purchaseInfo)
    if (purchaseData.error) {
      return rejectWithValue({
        ...purchaseData,
        _id: 999
      })
    }
    return {
      _id: purchaseData._id,
      purchaseData: {
        ...purchaseData
      }
    }
  }
)

export const editPurchase = createAsyncThunk(
  'purchase/editPurchaseCall',
  async (purchasePayload: { _id: string, purchaseInfo: any }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().purchase
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id, purchaseInfo } = purchasePayload
    const purchaseData = await thunkApi.extra.networkRequest.editPurchase(_id, purchaseInfo)
    if (purchaseData.error) {
      return rejectWithValue({
        ...purchaseData,
        _id: 999
      })
    }
    return {
      _id: purchaseData._id,
      purchaseData: {
        ...purchaseData
      }
    }
  }
)

export const deletePurchase = createAsyncThunk(
  'purchase/deletePurchaseCall',
  async (purchasePayload: { _id: string }, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().purchase
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const { _id } = purchasePayload
    const purchaseData = await thunkApi.extra.networkRequest.deletePurchase(_id)
    if (!purchaseData) {
      return rejectWithValue({
        error: 'Deletion failed',
        _id: 999
      })
    }
    if (purchaseData.error) {
      return rejectWithValue({
        ...purchaseData,
        _id: 999
      })
    }
    return {
      _id: purchaseData._id,
      purchaseData: {
        title: 'Purchase Deleted'
      }
    }
  }
)

export const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    clearPurchase: (state, action: PayloadAction) => {
      return {
        ...generateEmptyPurchaseState()
      }
    }
  },
  extraReducers: (builder) => {
    ([
      [getPurchaseInfo, 'getPurchaseInfo'],
      [addNewPurchase, 'addNewPurchase'],
      [editPurchase, 'editPurchase'],
      [deletePurchase, 'deletePurchase']
    ]).forEach(([action, actionName]: any) => {
      generalStandardCrudBuilder(builder, action, actionName)
    })
  }
})

export const { clearPurchase } = purchaseSlice.actions

export const purchaseSelector = createSelector((state: RootState) => state, (state) => state.purchase)

export default purchaseSlice.reducer
