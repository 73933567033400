import { Button, Card, Stack } from "react-bootstrap";
import { BookingGuestEntryType } from "../../../Types/bookingGuestTypes";
import { PropertyType } from "../../../Types/propertyTypes";
import { PropertyBookingEntry } from "../../../Types/bookingTypes";
import timeService from "../../../services/time/timeService";
import AccessLinkButton from "../../Buttons/AccessLinkButton";

const defaultOptions = {
    disableButtons: {
        all: false,
    }
}

export default function BookingGuestEntry(props: {
    bookingGuestInfo: BookingGuestEntryType,
    propertyData?: PropertyType,
    bookingData?: PropertyBookingEntry,
    extraButtons?: any[],
    options?: {
        disableButtons?: {
            [k: string]: boolean
        }
    }
}) {
    const {
        bookingGuestInfo,
        propertyData,
        bookingData,
        extraButtons,
        options,
    } = props

    const {
        _id,
        created,
        bookingId,
        propertyId,
        main,
        guests,
        arrivalTime,
        status,
        mailSent,
        documents
    } = bookingGuestInfo

    const {
        disableButtons
    } = {
        ...defaultOptions,
        ...options
    }

    return (
        <Card className="-guest-list-item mb-3">
            {/* {header && (
                <Card.Header>
                    {header}
                </Card.Header>
            )} */}
            <Card.Body>
                <Card.Title>
                    {main && main.firstName} {main && main.lastName}
                </Card.Title>
                <Card.Subtitle className="mb-2">Arrival: {timeService.getFormattedTime(arrivalTime)}</Card.Subtitle>
                <Card.Text className="small">
                    <b>Status: </b> {status} <br />
                    <b>Created: </b> {timeService.getFormattedTime(created)} <br />

                    {mailSent && (
                        <>
                            <b>Mail Sent: </b> {timeService.getFormattedTime(mailSent)} <br />
                        </>
                    )}

                    {bookingData ? (
                        <>
                            <b>Check In: </b> {timeService.getFormattedTime(bookingData.checkIn)} <br />
                            <b>Check Out: </b> {timeService.getFormattedTime(bookingData.checkOut)} <br />
                        </>
                    ) : (
                        <>
                            <b>Booking ID: </b> {bookingId} <br />
                        </>
                    )}

                    {propertyData ? (
                        <>
                            <b>Property: </b> {propertyData.title} <br />
                        </>
                    ) : (
                        <>
                            <b>Property ID: </b> {propertyId} <br />
                        </>
                    )}
                    <b>Email: </b> {main && main.email} <br />
                    <b>Phone: </b> {main && main.phone} <br />
                </Card.Text>
                <a href={main.idImage} target="_blank" rel="noreferrer">
                    <img src={main.idImage} style={{ maxWidth: '100px', }} alt="main guest" /> <br />
                    View ID
                </a>
                {
                    guests && guests.length > 0 && (
                        <>
                            <hr />
                            <Card.Subtitle className="mb-2 text-muted">Guests</Card.Subtitle>
                            <div className="guest-grid" style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                                gap: '1em'
                            }}>
                                {guests.map((guest, index) => (
                                    <div key={index}>
                                        <Card.Text className="small">
                                            <b>Guest {index + 1}: </b> {guest.firstName} {guest.lastName} <br />
                                        </Card.Text>
                                        <a href={guest.idImage} target="_blank" rel="noreferrer">
                                            <img src={guest.idImage} style={{ maxWidth: '100px', }} alt="guest" /> <br />
                                            View ID
                                        </a>
                                    </div>
                                )
                                )}
                            </div>
                        </>
                    )
                }

                {documents && documents.length > 0 && (
                    <>
                        <hr />
                        <Card.Subtitle className="mb-2 text-muted">Documents</Card.Subtitle>
                        <div className="documents-list">
                            <ul>
                                {documents.map((document, index) => (
                                    <li key={`${index}-${document.name}`}>
                                        <a href={document.url} target="_blank" rel="noreferrer">{document.name}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </>
                )}


            </Card.Body >
            <Card.Footer>
                {disableButtons && !disableButtons.all && (
                    <>
                        <Stack direction={'horizontal'} gap={0} className="mt-2 mb-2">
                            <AccessLinkButton
                                action={'booking-guest'}
                                text={'View'}
                                size={'sm'}
                                to={`/booking-guest/${_id}`}
                            />
                            <AccessLinkButton
                                action={'edit-booking-guest'}
                                color={'secondary'}
                                text={'Edit'}
                                size={'sm'}
                                to={`/edit-booking-guest/${_id}`}
                            />
                            <AccessLinkButton
                                action={'delete-booking-guest'}
                                color={'danger'}
                                text={'Delete'}
                                size={'sm'}
                                to={`/delete-booking-guest/${_id}`}
                            />
                        </Stack>

                        {/* view booking link */}
                        {bookingId && (
                            <AccessLinkButton
                                action={'booking'}
                                text={'View Booking'}
                                color={'secondary'}
                                size={'sm'}
                                to={`/booking/${bookingId}`}
                            />
                        )}

                        {/* view property link */}
                        {propertyId && (
                            <AccessLinkButton
                                action={'property'}
                                text={'View Property'}
                                color={'secondary'}
                                size={'sm'}
                                to={`/property/${propertyId}`}
                            />
                        )}

                        {extraButtons && extraButtons.map((button, index) => (
                            <Button
                                key={index}
                                className="me-1 mb-1"
                                size={'sm'}
                                variant={button.variant ? button.variant : 'secondary'}
                                disabled={button.disabled}
                                onClick={() => button.onClick(bookingGuestInfo)}>
                                {button.label}
                            </Button>
                        ))}

                    </>
                )}
            </Card.Footer>
        </Card >
    )
}