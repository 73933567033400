import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import BukibleForm from "../../../Components/Forms/BukibleForm/BukibleForm";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";
import { addNewBukible, bukibleSelector } from "../../../redux/bukible/bukibleSlice";
import { clearBukibles } from "../../../redux/bukibles/bukiblesSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";

export default function AddBukiblePage() {

  const {
    uploadImageFn,
  } = useUploadImage()

  const {
    // loading,
    error,
    message,
  } = useSelector(bukibleSelector)
  const dispatch = useAppDispatch()
  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    const newBukible = await dispatch(addNewBukible({bukibleInfo: values})).unwrap()
    if (newBukible._id) {
      dispatch(navigateTo(`/bukible/${newBukible._id}`))
    }
    dispatch(clearBukibles())
    return newBukible
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add Bukible</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}

          <BukibleForm
            initialValues={{}}
            disabled={false}
            onSubmit={handleSubmit}
            uploadImageFn={uploadImageFn}
          />

        </Col>
      </Row>
    </Container>
  )
}
