import * as yup from 'yup'

export const editUserSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  role: yup.string().required(),
  profilePic: yup.string().nullable(),
  password: yup.string().nullable(),
})
