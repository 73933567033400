import { createAsyncThunk, createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit"
import { generalStandardPaginatedBuilder, generateStandardNetworkPaginatedState, StandardPaginatedNetworkState } from "../reduxHelperFunctions"
import { RootState } from "../store"

interface BookingMessagesState extends StandardPaginatedNetworkState {
  bookingMessagesList: any[] | null
}

const generateEmptyBookingMessagesState = (): BookingMessagesState => {
  return {
    ...generateStandardNetworkPaginatedState(),
    bookingMessagesList: null,
  }
}

export const getBookingMessagesAction = createAsyncThunk(
  'bookingMessages/getBookingMessagesActionCall',
  async (queryInfo: {query: any, page: number, limit: number}, thunkApi: any) => {
    const { loading, currentRequestId } = thunkApi.getState().bookingMessages
    const { requestId, rejectWithValue } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }
    const bookingMessagesList = await thunkApi.extra.networkRequest.getBookingMessages(queryInfo.query, {page: queryInfo.page, limit: queryInfo.limit})
    if (bookingMessagesList.error) {
      return rejectWithValue({
        ...bookingMessagesList,
        currentQuery: queryInfo
      })
    }
    const {docs, ...paginationInfo} = bookingMessagesList
    return {
      docs,
      paginationInfo,
      currentQuery: queryInfo
    }
  }
)

const initialState: BookingMessagesState = generateEmptyBookingMessagesState()

export const bookingMessagesSlice = createSlice({
  name: 'bookingMessages',
  initialState,
  reducers: {
    clearBookingMessages: (state, action: PayloadAction) => {
      return {
        ...generateEmptyBookingMessagesState()
      }
    }
  },
  extraReducers: (builder) => {
    generalStandardPaginatedBuilder(builder, getBookingMessagesAction, 'bookingMessagesList')
  }
})

export const { clearBookingMessages } = bookingMessagesSlice.actions

export const bookingMessagesSelector = createSelector((state: RootState) => state, (state) => state.bookingMessages)

export default bookingMessagesSlice.reducer
